<template>
  <b-modal
    id="tag-bib-modal"
    dialog-class="tag-bib-modal-dialog"
    hide-footer
    hide-header
  >
    <div class="text-right pull-up">
      <img
        @click="closeModal()"
        class="img-fluid close-btn"
        src="@/assets/images/single-image-view/close.png"
      />
    </div>
    <a
      class="d-block btn text-left btn-light mb-2 image-untag-not-me di py-2"
      v-if="image && image.bib && showNotMe"
      @click="notMe()"
      >{{ $t("singleImageView.not_me") }}</a
    >
    <a
      class="d-block btn text-left btn-light mb-2 image-untag-not-me di py-2"
      v-if="image && hasStickers"
      @click="share()"
      >{{ $t("share.add_sticker_label") }}</a
    >
    <a
      class="d-block btn text-left btn-light mb-2 image-untag-hide di py-2"
      v-if="image && image.bib && showNotMe"
      @click="removeBib()"
      >{{ $t("singleImageView.hide_me") }}</a
    >
    <div class="image-tag di pt-2">
      <div class="mb-1">{{ $t("singleImageView.tag_me") }}</div>
      <search-box
        v-if="getFetchedEvent"
        @selectRunner="selectRunner"
        name="tagBib"
        :raceId="getFetchedEvent.id"
        :tagBib="true"
      />
    </div>
  </b-modal>
</template>

<script>
import Eventbus from "../event-bus";
import is from "is-js";
import { tagBib, untagBib } from "../api/index";
import { mapGetters } from "vuex";
import SearchBox from "../components/SearchBox";

export default {
  name: "tag-bib-modal",
  components: {
    SearchBox,
  },
  computed: {
    ...mapGetters(["getFetchedEvent", "hasStickers"]),
  },
  data() {
    return {
      image: null,
      bib: "",
      error: false,
      showNotMe: false,
      hint: "",
    };
  },
  methods: {
    async submitTagBib() {
      this.hint = "";
      if (
        this.bib &&
        is.number(parseInt(this.bib)) &&
        this.bib != "" &&
        this.getFetchedEvent &&
        this.image
      ) {
        this.setLoading(true);
        const resp = await tagBib(
          this.getFetchedEvent.id,
          this.bib,
          this.image.image_key
        );
        if (resp.data && resp.data.status) {
          let status = resp.data.status.type;
          if (status == "success") {
            this.error = false;
            this.hint = this.$t("tagBib.success_hint");
            Eventbus.$emit("show-toast", this.hint);
            setTimeout(() => {
              this.$bvModal.hide("tag-bib-modal");
            }, 3000);
          } else {
            this.error = true;
            this.hint = this.$t("tagBib.fail_hint");
            Eventbus.$emit("show-toast", this.hint);
          }
          this.setLoading(false);
        }
      } else {
        this.error = true;
        this.hint = this.$t("tagBib.invalid_hint");
        Eventbus.$emit("show-toast", this.hint);
      }
    },
    async submitUntagBib() {
      this.hint = "";
      if (this.getFetchedEvent && this.image && this.image.bib) {
        this.setLoading(true);
        const resp = await untagBib(
          this.getFetchedEvent.id,
          this.image.bib,
          this.image.image_key
        );
        if (resp.data && resp.data.status) {
          let status = resp.data.status.type;
          if (status == "success") {
            this.error = false;
            this.hint = this.$t("untagBib.success_hint");
            Eventbus.$emit("show-toast", this.hint);
            this.$bvModal.hide("untag-bib-modal");
          } else {
            this.error = true;
            this.hint = this.$t("untagBib.fail_hint");
            Eventbus.$emit("show-toast", this.hint);
          }
        }
      } else {
        this.error = true;
        this.hint = this.$t("untagBib.invalid_hint");
        Eventbus.$emit("show-toast", this.hint);
      }
    },
    setLoading(loading) {
      if (this.$refs["bib-type-loader"]) {
        if (loading) {
          this.$refs["bib-type-loader"].setPosition(this.bib);
        } else {
          this.$refs["bib-type-loader"].close();
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("tag-bib-modal");
    },
    selectRunner(runner) {
      this.bib = runner.bib;
      this.submitTagBib();
    },
    notMe() {
      if (this.getFetchedEvent.results_searchable != "2" && !this.showNotMe) {
        Eventbus.$emit("init-untag-bib-modal", this.image);
      } else {
        this.submitUntagBib();
      }
      this.$store.dispatch("fireTracking", {
        event_type: "IMNM",
        ext_race_id: this.getFetchedEvent.id,
        image_id: this.image.image_id,
      });
    },
    removeBib() {
      if (this.getFetchedEvent.results_searchable != "2" && !this.showNotMe) {
        Eventbus.$emit("init-untag-bib-modal", this.image);
      } else {
        this.submitUntagBib();
      }
    },
    share() {
      Eventbus.$emit("init-share-modal", {
        image: this.image,
        downloadOnly: false,
      });
      this.closeModal();
    },
  },
  mounted() {
    Eventbus.$on("init-bib-modal", (image, showNotMe) => {
      this.image = image;
      this.bib = "";
      this.showNotMe = showNotMe;
      this.error = false;
      this.hint = "";
      this.$bvModal.show("tag-bib-modal");
    });

    Eventbus.$on("direct-tag-bib", (image, bib) => {
      this.image = image;
      this.bib = bib;
      this.submitTagBib();
    });
  },
  beforeDestroy() {
    Eventbus.$off("init-bib-modal");
    Eventbus.$off("direct-tag-bib");
  },
};
</script>

<style lang="scss" scoped>
#tag-bib-modal {
  .close-btn {
    width: 14px;
  }
  .pull-up {
    margin-top: -0.5rem;
  }
}
</style>

<style lang="scss">
.modal-dialog.tag-bib-modal-dialog {
  @media (min-width: 576px) {
    max-width: 400px;
  }
}
</style>
