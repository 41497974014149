<template>
  <div
    class="single-image-view-item py-3"
    :data-page="page"
    :data-id="image.image_id"
    :data-bib="image.bib ? image.bib : null"
  >
    <div class="container p-0">
      <div class="runner-bar" v-if="!desktop && image.nearRunnerInfo">
        <div v-if="image.nearRunnerInfo.name" class="label">
          {{ $t("runnerHome.nearby_runners") }}
        </div>
        <div v-if="image.nearRunnerInfo.name" class="runner-title">
          <span class="bib mr-2">
            <span
              v-if="
                image.nearRunnerInfo.bib && image.nearRunnerInfo.bib.length < 8
              "
            >
              {{ image.nearRunnerInfo.bib }}</span
            >
          </span>
          <span class="name">{{ image.nearRunnerInfo.name }}</span>
          <span class="detail mt-2">{{
            getRunnerDesc(image.nearRunnerInfo)
          }}</span>
        </div>
      </div>
      <div class="header" v-if="!changing && showDots">
        <a @click="showTagBibModal()">
          <i class="icon fas fa-ellipsis-h"></i>
        </a>
        <div class="float-right">
          <span
            v-if="!favorite"
            class="icon like-image"
            @click="savePhoto(true)"
          >
            <i class="far fa-heart"></i>
          </span>
          <span
            v-if="favorite"
            class="icon unlike-image"
            @click="savePhoto(false)"
          >
            <i class="fas fa-heart color-hotpink"></i>
          </span>
          <span class="icon ml-2 fb-share-image" @click="share()">
            <i class="fas fa-share-alt" style="margin-left: -3px"></i>
          </span>
          <span class="icon ml-2 download-image" @click="download()">
            <i class="fas fa-arrow-down"></i>
          </span>
        </div>
      </div>
      <div class="img position-relative">
        <img
          class="w-100 img-fluid img-max-height"
          crossorigin="anonymous"
          :src="image.previewPath"
          style="pointer-events: none"
        />
        <span
          class="face-confidence"
          v-if="image.face && !desktop"
          :id="`face-search-item-${image.image_id}`"
        >
          <i class="mx-1 fa fa-smile"></i>
          <span>{{ `${getScore(image.confidence)}%` }}</span>
          <div class="d-none d-md-block">
            {{ $t("runnerHome.what_is_this") }}
          </div>
        </span>
        <b-tooltip
          v-if="image.face"
          :target="`face-search-item-${image.image_id}`"
          customClass="face-search-item-tooltip"
          placement="top"
        >
          <i class="mb-2 fa fa-smile"></i>
          <div class="desc">
            {{
              $t("faceSearch.photo_tooltip", {
                percent: `${getScore(image.confidence)}%`,
              })
            }}
          </div>
        </b-tooltip>
      </div>
      <div class="footer" v-if="!changing">
        <div class="float-left">
          <span
            v-if="!favorite"
            class="icon like-image"
            @click="savePhoto(true)"
          >
            <i class="far fa-heart"></i>
          </span>
          <span
            v-if="favorite"
            class="icon unlike-image"
            @click="savePhoto(false)"
          >
            <i class="fas fa-heart color-hotpink"></i>
          </span>
        </div>
        <div class="float-right">
          <span class="icon ml-2 fb-share-image" @click="share()">
            <i class="fas fa-share-alt" style="margin-left: -3px"></i>
          </span>
          <span class="icon ml-2 download-image" @click="download()">
            <i class="fas fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Eventbus from "../event-bus";
import SearchBox from "../components/SearchBox";
import loadingGif from "@/assets/images/loading.gif";
import formatter from "../utils/formatter";
import imagesLoaded from "vue-images-loaded";

export default {
  name: "single-image-view-item",
  components: {
    SearchBox,
  },
  directives: {
    imagesLoaded,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    nearRunner: {
      type: Boolean,
      default: false,
    },
    desktop: {
      type: Boolean,
      default: false,
    },
    runnerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatter,
      favorite: false,
      changing: false,
      loadingGif,
    };
  },
  computed: {
    ...mapGetters([
      "getFetchedEvent",
      "getPhotosPurchased",
      "getSelectedRunner",
      "getSelectedRunnerOrTeam",
      "getWindowSize",
      "getOverlays",
    ]),
    getRunnerData() {
      return this.getSelectedRunnerOrTeam
        ? this.getSelectedRunnerOrTeam.data
        : null;
    },
    getDirection() {
      if (!this.image || !this.image.width) {
        return false;
      }
      if (!isNaN(this.image.width) && !isNaN(this.image.height)) {
        return parseInt(this.image.width) > parseInt(this.image.height);
      }
      return false;
    },
    getImgWidthStyle() {
      if (
        this.desktop &&
        this.getWindowSize.width >= 768 &&
        !isNaN(this.image.width) &&
        !isNaN(this.image.height)
      ) {
        let targetWidth = 0;
        if (this.getDirection) {
          //landscape
          targetWidth = Math.min(
            parseInt(((parseInt(this.getWindowSize.height) * 0.8) / 2) * 3),
            parseInt(parseInt(this.getWindowSize.width) * 0.8)
          );
        } else {
          targetWidth = Math.min(
            parseInt(((parseInt(this.getWindowSize.height) * 0.8) / 3) * 2),
            parseInt(parseInt(this.getWindowSize.width) * 0.8)
          );
        }
        return {
          "max-width": `${targetWidth}px`,
        };
      } else {
        return {};
      }
    },
    showDots() {
      if (this.getFetchedEvent) {
        if (this.getFetchedEvent.result_searchable) {
          return (
            this.getFetchedEvent.photos_available == "1" &&
            (this.getFetchedEvent.results_searchable == "1" ||
              this.getFetchedEvent.results_searchable == "2" ||
              this.getFetchedEvent.results_searchable == "3")
          );
        }
        return (
          this.getFetchedEvent.photos_available == "1" &&
          (this.getFetchedEvent.results_searchable == "1" ||
            this.getFetchedEvent.results_searchable == "2" ||
            this.getFetchedEvent.results_searchable == "3")
        );
      }
      return false;
    },
    IsStickerAvailable() {
      const stickerList = [];
      this.getOverlays.forEach((value) => {
        value.overlays.forEach((overlayItem) => {
          if (
            overlayItem.overlay_position === "sticker" &&
            stickerList.indexOf(overlayItem.overlay_logo) === -1
          ) {
            stickerList.push(overlayItem.overlay_logo);
          }
        });
      });

      return stickerList.length > 0;
    },
    IsMarkupAvailable() {
      const runnerData = this.getRunnerData;
      if (!runnerData) return false;
      if (!runnerData.firstname || !runnerData.lastname) return false;
      if (
        !runnerData.category ||
        !runnerData.category.completed ||
        !runnerData.category.completed.distance ||
        !runnerData.category.completed.distance.name
      )
        return false;
      if (
        !runnerData.result ||
        !runnerData.result.duration ||
        !runnerData.result.duration.chip
      )
        return false;

      return true;
    },
    IsPurchaseAvailable() {
      const fetchedEvent = this.getFetchedEvent;
      let hasUploadCheckPoint = false;
      this.getOverlays.forEach((value) => {
        if (
          value.checkpoint.checkpoint_id === this.image.check_point_id &&
          (value.checkpoint.checkpoint_label === "User Uploads" ||
            value.checkpoint.checkpoint_label === "Uploads")
        ) {
          hasUploadCheckPoint = true;
        }
      });

      return (
        fetchedEvent &&
        fetchedEvent.photos_purchase == "1" &&
        this.image.check_point_id !== 3 &&
        this.image.check_point_id !== "3" &&
        hasUploadCheckPoint == false
      );
    },
  },
  methods: {
    showTagBibModal() {
      Eventbus.$emit("init-bib-modal", this.image, !this.nearRunner);
    },
    tagBib() {
      this.$emit("tagBib", this.image);
    },
    notMe() {
      this.$emit("notMe", { image: this.image, bib: this.image.bib });
    },
    removeBib() {
      this.$emit("removeBib", { image: this.image, bib: this.image.bib });
    },
    share() {
      this.$emit("share", this.image);
    },
    download() {
      if (this.IsPurchaseAvailable) {
        //Eventbus.$emit("init-download-modal", this.image);
        this.$emit("download", this.image, this.nearRunner);
      } else if (this.IsStickerAvailable || this.IsMarkupAvailable) {
        // console.log('should be shared');
        this.share();
      } else {
        this.$emit("download", this.image, this.nearRunner);
      }
    },
    savePhoto(save) {
      if (save) {
        this.$store.dispatch("fireTracking", {
          event_type: "LIKE",
          ext_race_id: this.getFetchedEvent.id,
          image_id: this.image.image_id,
        });
        if (!localStorage.savedImages) {
          localStorage.savedImages = JSON.stringify([this.image.image_id]);
        } else {
          let json = JSON.parse(localStorage.savedImages);
          if (json.indexOf(this.image.image_id) < 0) {
            json.push(this.image.image_id);
            localStorage.savedImages = JSON.stringify(json);
          }
        }
        this.favorite = true;
      } else {
        if (!localStorage.savedImages) {
          localStorage.savedImages = JSON.stringify([]);
        } else {
          let json = JSON.parse(localStorage.savedImages);
          let index = json.indexOf(this.image.image_id);
          if (index >= 0) {
            json.splice(index, 1);
            localStorage.savedImages = JSON.stringify(json);
          }
        }
        this.favorite = false;
      }
    },
    getRunnerDesc(runner) {
      if (!runner) return "";
      let result = "";
      let arr = [];
      if (runner.race_name && runner.race_name != "") {
        arr.push(runner.race_name);
      }
      let col2 = "";
      if (runner.age && runner.age != "") {
        col2 = runner.age;
        if (runner.sex && runner.sex != "") {
          col2 += "/" + this.formatter.formatStr(runner.sex);
        }
      } else {
        if (runner.sex && runner.sex != "") {
          col2 = this.formatter.formatStr(runner.sex);
        }
      }
      if (col2 != "") {
        arr.push(col2);
      }
      if (runner.hometown && runner.hometown != "") {
        arr.push(runner.hometown);
      }
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        if (i != 0) {
          result += " | ";
        }
        result += element;
      }
      return result;
    },
    getScore(scoreStr) {
      let score = Math.ceil(parseFloat(scoreStr));
      if (score == 100) {
        score = 99;
      }
      return score;
    },
    checkFavorite() {
      if (localStorage.savedImages) {
        let json = JSON.parse(localStorage.savedImages);
        if (json.indexOf(this.image.image_id) >= 0) {
          this.favorite = true;
        } else {
          this.favorite = false;
        }
      } else {
        this.favorite = false;
      }
    },
  },
  watch: {
    image(val) {
      this.checkFavorite();
    },
  },
  mounted() {
    this.checkFavorite();
    Eventbus.$on("image-view-loading", (load) => {
      if (this.desktop) {
        if (!load) {
          setTimeout(() => {
            this.changing = load;
          }, 1);
        } else {
          this.changing = load;
        }
      }
    });
  },
  beforeDestroy() {
    Eventbus.$off("image-view-loading");
  },
};
</script>

<style lang="scss" scoped>
.img-max-height {
  @media (min-width: 768px) {
    height: calc(100vh - 120px);
    object-fit: contain;
  }
}

.single-image-view-item {
  border-bottom: 1px solid #d5d5d5;
  @media (min-width: 768px) {
    border-bottom: none;
  }

  & > .container {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .icon {
    cursor: pointer;
  }

  span.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6f7372;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    i {
      color: #fff;
      font-size: 24px;

      &.fas.fa-heart {
        color: red;
      }
    }
  }

  .footer {
    padding: 1rem 5px;
    min-height: 60px;
  }

  .header {
    padding: 1rem 0;
  }
  .fas {
    color: #6f7372;
    font-size: 28px;
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px !important;
      height: auto;
    }
  }
}
</style>

<style lang="scss">
.single-image-view-item {
  .dropdown-menu.show {
    min-width: 300px;
  }
  .dropdown-item,
  .image-tag {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }
}

.color-hotpink {
  color: hotpink !important;
}
</style>
