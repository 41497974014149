import _ from "lodash";
// import moment from "moment";
import moment from "moment-timezone";

export default {
  formatTime(val) {
    if (!val || val.trim() == "") {
      return "-";
    }
    let complete = false;
    let result = val;
    while (!complete) {
      let firstChar = result[0];
      let secondCahr = result[1];
      if (firstChar != "0" && firstChar != ":" || (firstChar == "0" && secondCahr == ":" && result.length == 4)) {
        complete = true;
      } else {
        result = result.slice(1);
      }
      if (result.length == 0) {
        complete = true;
      }
    }
    return this.formatStr(result);
  },
  formatPace(val) {
    if (!val || val.trim() == '' || val == '00:00:00' || val == '00:00') {
      return '-'
    }
    let complete = false;
    let result = val;
    while (!complete) {
      let firstChar = result[0];
      let secondCahr = result[1];
      if (firstChar != '0' && firstChar != ':' || (firstChar == "0" && secondCahr == ":" && result.length == 4)) {
        complete = true;
      } else {
        result = result.slice(1);
      }
      if (result.length == 0) {
        complete = true;
      }
    }
    return this.formatStr(result)
  },
  formatPaceUnit(val) {
    if (!val || val == "none") {
      return ""
    } else {
      return val
    }
  },
  normalisePayload(payload) {
    for (let key in payload) {
      // console.log(key)
      if (_.isObject(payload[key])) {
        this.normalisePayload(payload[key]);
      } else if (_.isArray(payload[key])) {
        payload[key] = _.map(o => {
          return this.normalise(o);
        });
      } else {
        // console.log(`${key}: ${payload[key]}`)
        payload[key] = this.normalise(payload[key]);
      }
    }
    return this.normalise(payload);
  },
  normalise(val) {
    if (_.isString(val)) {
      return val;
    } else if (_.isNumber(val) || _.isInteger(val)) {
      return val.toString();
    } else {
      return val;
    }
  },
  formatStr(val) {
    if (!val || val.trim() == "") {
      return "-";
    }
    if (val.trim().toLowerCase() == "male") {
      return "M";
    }
    if (val.trim().toLowerCase() == "female") {
      return "F";
    }
    var parser = new DOMParser();
    var dom = parser.parseFromString(val, "text/html");
    var decodedString = dom.body.textContent;
    return decodedString;
  },
  addPreviewImagePath(arr) {
    if (!Array.isArray(arr)) {
      return [];
    }
    const result = arr;
    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        result[key].previewPath = this.previewImgPath() + result[key].image_key;
        result[key].thumbnail_Path =
          this.thumbnailImgPath() + result[key].image_key;
      }
    }
    return result;
  },
  previewImgPath() {
    let ran = Math.floor(Math.random() * 7);
    return `https://img${ran}.enmotive.grepcv.com/images/preview/`;
  },
  thumbnailImgPath() {
    let ran = Math.floor(Math.random() * 7);
    return `https://img${ran}.enmotive.grepcv.com/images/thumbnail_300/`;
  },
  downloadImgPath() {
    return `https://dl.enmotive.grepcv.com/images/download/`;
  },
  getObjArrElementById(arr, id, keyName) {
    for (const key in arr) {
      if (arr.hasOwnProperty(key)) {
        const element = arr[key];
        if ("" + element[keyName] == "" + id) {
          return {
            key: key,
            element: element
          };
        }
      }
    }
    return null;
  },
  formatNearRunnerUrl(currentRunner, nearRunner, link) {
    let url = link;
    if (link.indexOf(currentRunner.registration_id) >= 0) {
      url = link.replace(
        currentRunner.registration_id,
        nearRunner.registration_id
      );
    } else {
      url = link.replace(currentRunner.bib, nearRunner.bib);
    }
    let split = url.split("?");
    if (split.length > 0) {
      url = split[0];
      if (split.length > 1) {
        let querySplit = split[1].split("&");
        for (const key in querySplit) {
          if (querySplit.hasOwnProperty(key)) {
            const element = querySplit[key];
            if (element.indexOf("image_id=") >= 0) {
              url = `${url}?${element}`;
            }
          }
        }
      }
    }
    return url;
  },
  checkRouteSame(query, current) {
    if (Object.keys(query).length !== Object.keys(current).length) return false;
    let sameCount = 0;
    for (const key in current) {
      if (current.hasOwnProperty(key)) {
        const currentQuery = current[key];
        for (const subkey in query) {
          if (query.hasOwnProperty(subkey)) {
            const newQuery = query[subkey];
            if ("" + key == "" + subkey && "" + newQuery == "" + currentQuery) {
              sameCount++;
              break;
            }
          }
        }
      }
    }
    if (sameCount != Object.keys(query).length) {
      return false;
    }
    return true;
  },
  lastUpdateFormatter(timeString) {
    try {
      const time = moment.utc(timeString);
      const localtz = moment.tz.guess();
      const date = time.clone().tz(localtz);
      return moment(date).format("[Last Update:] MMM, D YYYY hh:mm a z");
    } catch (e) {
      console.log(`lastUpdateFormatter - ${e}`);
      return null;
    }
  }
};
