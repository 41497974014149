<template>
  <div class="event-title">
    <div class="d-flex align-items-center justify-content-center gap-3">
      <div><img v-if="icon" :src="icon" class="rounded-circle" alt="Event Logo"></div>
      <div>
        <h1>{{ formatter.formatStr(name) }}</h1>
        <h2>
          {{ formatter.formatStr(location) }} | 
          <span>{{ getFommatedTimeForStart }}</span>
          <span v-if="getFommatedTimeForEnd"> - {{ getFommatedTimeForEnd }}</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import formatter from "../utils/formatter";

export default {
  name: "event-title",
  components: {},
  props: {
    name: {
      type: String,
      default: ""
    },
    location: {
      type: String,
      default: ""
    },
    dateStart: {
      type: String,
      default: ""
    },
    dateEnd: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formatter,
      icon: ""
    };
  },
  computed: {
    ...mapGetters(["getWindowSize", "getFetchedEvent"]),
    getFommatedTimeForStart() {
      return moment(this.dateStart).format("MMM DD, YYYY");
    },
    getFommatedTimeForEnd() {
      if (!this.dateEnd) return null
      if (this.dateStart === this.dateEnd) return null
      return moment(this.dateEnd).format("MMM DD, YYYY");
    }
  },
  methods: {
    getIcon() {
      let logo = this.getFetchedEvent
        ? this.getFetchedEvent.images.logo
          ? this.getFetchedEvent.images.logo
          : ""
        : "";
      if (
        logo.indexOf(".png") >= 0 ||
        logo.indexOf(".jpg") >= 0 ||
        logo.indexOf(".jpeg") >= 0 ||
        logo.indexOf(".gif") >= 0
      ) {
        this.icon = logo;
      } else {
        this.icon = "";
      }
    }
  },
  watch: {
    getFetchedEvent(val) {
      if (val) {
        this.getIcon();
      }
    }
  },
  mounted() {
    this.getIcon();
  }
};
</script>

<style lang="scss" scoped>
.event-title {
  position: relative;
  padding: 0 1rem;
  
  img {
    width: min(12vw, 120px);
    background-color: #ffffff;
  }

  h1 {
    font-size: min(4vw, 2rem);
    margin-bottom: 0.25rem;
  }
  h2 {
    font-size: min(3vw, 1.5rem);
    margin-bottom: 0;
  }
}
</style>