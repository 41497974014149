<template>
  <div
    id="single-image-view"
    class="pt-5 pt-md-0"
    :class="{'landscape': getDirection, 'lock': lock}"
    :style="{'color': '#fff'}"
    v-if="show && getFetchedEvent"
  >
    <div class="backdrop" @click="clickBackground()" @wheel="handleWheel"></div>
    <div class="main-image-wrapper">
      <single-image-view-item
        v-if="allImages && allImages.length > 0 && currentIndex < allImages.length && currentImage"
        :image="currentImage"
        :nearRunner="nearRunnerImages.length > 0 && (imageList.length == 0 || currentIndex + 1 > imageList.length)"
        :desktop="true"
        @tagBib="tagBib"
        @removeBib="removeBib"
        @notMe="notMe"
        @share="share"
        @download="download"
      />
    </div>
    <img
      class="close-btn"
      @click="close()"
      src="@/assets/images/single-image-view/close.png"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Eventbus from "../event-bus";
import SingleImageViewItem from "../components/SingleImageViewItem";
import { getImage, getRunnerImage, getRunner, tagBib } from "../api/index";
import formatter from "../utils/formatter";

export default {
  name: "single-image-view",
  components: {
    SingleImageViewItem
  },
  data() {
    return {
      type: "race",
      show: false,
      pageInfo: null,
      virtualPage: 0,
      selectedImage: null,
      checkpoint: null,
      imageList: [],
      nearRunner: null,
      nearRunnerObj: {},
      nearRunnerImages: [],
      appending: false,
      faceImages: [],
      lock: false,
      formatter,
      currentIndex: 0,
      allImages: [],
      currentImage: null,
      settingPrevImage: false,
      holdResult: false
    };
  },
  computed: {
    ...mapGetters([
      "getWindowSize",
      "getFetchedEvent",
      "getSelectedImage",
      "getSelectedRunner",
      "getFaceSearchResult",
      "getCurrentNearRunner"
    ]),
    getDirection() {
      if (!this.getSelectedImage || !this.getSelectedImage.width) {
        return false;
      }
      return this.getSelectedImage.width > this.getSelectedImage.height;
    }
  },
  methods: {
    deleteFaceSearchImg(images) {
      let arr = [];
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        if (!element.faceSearch) {
          arr.push(element);
        }
      }
      return arr;
    },
    close(scrollTo = true) {
      if (this.show) {
        if (scrollTo) {
          Eventbus.$emit(
            "change-gallery-scroll-position",
            this.$route.query.image_id
          );
        }
        this.changeRouterQuery(null);
        this.$store.dispatch("setSelectedImage", null);
        this.$store.dispatch("setCurrentNearRunner", null);
        this.show = false;
        Eventbus.$emit("lock-screen", false);
        this.$bvModal.hide("download-modal");
        this.$bvModal.hide("email-photo-modal");
        this.$bvModal.hide("untag-bib-modal");
      }
    },
    setupImageView(payload) {
      this.setLoading(true);
      Eventbus.$emit("image-view-loading", false);
      this.type = payload.type;
      this.pageInfo = payload.pageInfo;
      this.virtualPage = payload.pageInfo.page;
      this.selectedImage = payload.selectedImage;
      this.currentImage = payload.selectedImage;
      this.currentIndex = 0;
      this.allImages = [];

      if (this.type == "race" || this.type == "face") {
        this.checkpoint = payload.checkpoint;
        this.imageList = payload.imageList;
        if (this.type == "face") {
          this.faceImages = this.getFaceSearchResult
            ? this.getFaceSearchResult.images.slice(payload.index)
            : [];
          this.allImages = [...this.faceImages, ...this.imageList];
        } else {
          this.allImages = [...this.imageList];
        }
      } else if (this.type == "runner") {
        this.nearRunner = payload.nearRunner;
        for (const key in this.nearRunner) {
          this.nearRunnerObj[this.nearRunner[key].bib] = this.nearRunner[key];
        }
        this.nearRunnerImages = payload.nearRunnerImages;
        this.imageList = this.deleteFaceSearchImg(payload.imageList);
        if (this.imageList.length == 0) {
          let bib =
            this.nearRunnerImages.length > 0
              ? this.nearRunnerImages[0].bib
              : null;
          this.$store.dispatch(
            "setCurrentNearRunner",
            this.nearRunnerObj[parseInt(bib)]
          );
        } else {
          this.$store.dispatch("setCurrentNearRunner", this.getSelectedRunner);
        }
        this.allImages = [...this.imageList];
        this.nearRunnerImages.forEach(element => {
          this.allImages.push(...element.images);
        });
      }
      let result = this.formatter.getObjArrElementById(
        this.allImages,
        this.selectedImage.image_id,
        "image_id"
      );
      if (result) this.currentIndex = parseInt(result.key);
      this.changeRouterQuery(this.selectedImage.image_id);

      this.show = true;
      Eventbus.$emit("lock-screen", true);
      this.setLoading(false);
    },
    setLoading(load) {
      this.lock = false;
      Eventbus.$emit("change-loader-status", false);
    },
    tagBib(image, bib) {
      if (bib) {
        Eventbus.$emit("direct-tag-bib", image, bib);
      }
    },
    notMe(image) {
      Eventbus.$emit("init-untag-bib-modal", image);
      this.$store.dispatch("fireTracking", {
        event_type: "IMNM",
        ext_race_id: this.getFetchedEvent.id,
        image_id: image.image_id
      });
    },
    removeBib(image) {
      Eventbus.$emit("init-untag-bib-modal", image);
    },
    share(image) {
      Eventbus.$emit("init-share-modal", { image });
    },
    download(image, nearRunner = false) {
      if (this.getFetchedEvent) {
        image.nearRunner = nearRunner;
        if (this.getFetchedEvent.photos_purchase == "1") {
          Eventbus.$emit("init-download-modal", image);
        } else {
          Eventbus.$emit("direct-download", image);
        }
      }
    },
    handleWheel() {
      if (this.getWindowSize.width >= 768) {
        this.close();
      }
    },
    callFireTracking(image = null) {
      let payload = {
        event_type: "IMV",
        ext_race_id: this.getFetchedEvent.id
      };
      if (image) {
        payload.image_id = image;
      }
      this.$store.dispatch("fireTracking", payload);
    },
    changeRouterQuery(imageId, page = null) {
      //for pagination
      if (page) {
        if (this.virtualPage != page) {
          const pInfo = Object.assign({}, this.pageInfo);
          pInfo.page = page;
          Eventbus.$emit("change-gallery-page", pInfo);
          this.virtualPage = page;
        }
      }
      //
      let query = {};
      if (imageId != null) {
        query = { view: 1 };
      }
      if (this.pageInfo) {
        query.page = page ? page : this.pageInfo.page;
      }
      if (imageId) {
        query.image_id = imageId;
        this.callFireTracking(imageId);
      } else {
        this.callFireTracking();
      }
      if (this.checkpoint && this.checkpoint.checkpoint_id != -1) {
        query.checkpoint_id = this.checkpoint.checkpoint_id;
      }
      if (!this.formatter.checkRouteSame(query, this.$route.query)) {
        this.$router.push({ query: query });
      }
    },
    clickBackground() {
      this.close();
    },
    leftClicked() {
      if (this.currentIndex > 0) {
        Eventbus.$emit("image-view-loading", true);
        if (
          this.$route.name == "RaceHome" &&
          this.currentIndex % process.env.RACE_IMAGE_PER_PAGE === 0
        ) {
          this.holdResult = true;
          Eventbus.$emit("append-prev-race-gallery");
        }
        this.currentIndex--;
        let image = this.allImages[this.currentIndex];
        this.currentImage = image;
        Eventbus.$emit("image-view-loading", false);
        let page = image.page ? image.page : 1;
        this.changeRouterQuery(image.image_id, page);
      } else {
        this.close();
      }
    },
    rightClicked() {
      if (this.currentIndex < this.allImages.length) {
        Eventbus.$emit("image-view-loading", true);
        if (this.currentIndex != this.allImages.length - 1) {
          if (
            this.$route.name == "RaceHome" &&
            (this.currentIndex + 1) % process.env.RACE_IMAGE_PER_PAGE === 0
          ) {
            this.holdResult = true;
            Eventbus.$emit("append-next-race-gallery");
          }
          this.currentIndex++;
          let image = this.allImages[this.currentIndex];
          this.currentImage = image;
          Eventbus.$emit("image-view-loading", false);
          let page = image.page ? image.page : 1;
          this.changeRouterQuery(image.image_id, page);
        } else {
          if (!this.pageInfo.isLastPage) {
            this.appending = true;
            if (this.type == "race" || this.type == "face") {
              Eventbus.$emit("append-next-race-gallery");
            } else if (this.type == "runner") {
              Eventbus.$emit("append-next-near-runners");
            }
          } else {
            this.close();
          }
        }
      }
    }
  },
  mounted() {
    $("body").on(
      "click",
      "#single-image-view .mega-dropdown .dropdown-toggle",
      function(e) {
        if (
          !$(this)
            .parent()
            .hasClass("open")
        ) {
          $(
            "#single-image-view .dropdown.mega-dropdown.open .dropdown-menu"
          ).removeClass("show");
          $("#single-image-view .dropdown.mega-dropdown.open").removeClass(
            "open"
          );
        }
        $(this)
          .parent()
          .toggleClass("open");
        $(this)
          .parent()
          .find(".dropdown-menu")
          .toggleClass("show");
        e.stopPropagation();
      }
    );
    $("body").on(
      "click",
      "#single-image-view .single-image-view-item",
      function(e) {
        $(
          "#single-image-view .dropdown.mega-dropdown.open .dropdown-menu"
        ).removeClass("show");
        $("#single-image-view .dropdown.mega-dropdown.open").removeClass(
          "open"
        );
      }
    );

    Eventbus.$on("show-single-image-view", payload => {
      this.setupImageView(payload);
    });

    Eventbus.$on("close-single-view-image", (scrollTo = true) => {
      this.close(scrollTo);
    });

    Eventbus.$on("left-image", () => {
      if (this.getWindowSize.width >= 768) this.leftClicked();
    });

    Eventbus.$on("right-image", () => {
      if (this.getWindowSize.width >= 768) this.rightClicked();
    });

    Eventbus.$on("push-append-race-gallery", ({ pageInfo, newImages }) => {
      this.pageInfo = pageInfo;
      if (this.settingPrevImage) {
        this.settingPrevImage = false;
        let img = this.allImages;
        this.imageList = [...newImages];
        if (!this.holdResult) {
          this.allImages = [...newImages, ...img];
          this.currentIndex = this.currentIndex + newImages.length;
          this.currentIndex--;
          this.currentImage = this.allImages[this.currentIndex];
          Eventbus.$emit("image-view-loading", false);
          let page = this.currentImage.page ? this.currentImage.page : 1;
          this.changeRouterQuery(this.currentImage.image_id, page);
          this.appending = false;
        } else {
          this.holdResult = false;
        }
      } else {
        if (!this.holdResult) {
          this.allImages.push(...newImages);
          this.currentIndex++;
          this.currentImage = this.allImages[this.currentIndex];
          Eventbus.$emit("image-view-loading", false);
          let page = this.currentImage.page ? this.currentImage.page : 1;
          this.changeRouterQuery(this.currentImage.image_id, page);
          this.appending = false;
        } else {
          this.holdResult = false;
        }
      }
    });
    Eventbus.$on("push-near-runners", ({ pageInfo, newNearRunner }) => {
      this.pageInfo = pageInfo;
      this.nearRunnerImages.push(...newNearRunner);
      newNearRunner.forEach(runner => {
        this.allImages.push(...runner.images);
      });
      this.currentIndex++;
      this.currentImage = this.allImages[this.currentIndex];
      Eventbus.$emit("image-view-loading", false);
      this.appending = false;
    });

    Eventbus.$on("single-view-share-facebook", () => {
      if (this.currentImage) {
        Eventbus.$emit("share-facebook", {
          type: "gallery",
          image: this.currentImage
        });
      }
    });
  },
  beforeDestroy() {
    Eventbus.$off("show-single-image-view");
    Eventbus.$off("close-single-view-image");
    Eventbus.$off("left-image");
    Eventbus.$off("right-image");
    Eventbus.$off("push-append-race-gallery");
    Eventbus.$off("push-near-runners");
    Eventbus.$off("single-view-share-facebook");
  }
};
</script>

<style lang="scss" scoped>
#single-image-view {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    background-color: #ffffff;
    display: block;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }

  &.lock {
    overflow: hidden;
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: auto;
  }

  .back-btn {
    cursor: pointer;
    position: fixed;
    top: 4rem;
    right: 0.5rem;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 15px;
      height: auto;
    }
  }
  .mobile-image-wrapper.pdt {
    padding-top: 50px;
  }
  #single-image-view-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    .type-loader {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;

      .typing_loader {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        -webkit-animation: typing 1s linear infinite alternate;
        -moz-animation: Typing 1s linear infinite alternate;
        animation: typing 1s linear infinite alternate;
        position: relative;
        left: -6px;
        padding: 0 !important;
      }
    }
  }
  .runner-bar {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    .label {
      color: #6f7372;
      max-width: 75px;
      font-size: 14px;
      padding-right: 0.5rem;
      margin-right: 1rem;
      border-right: 1px solid #6f7372;
      @media (min-width: 768px) {
        max-width: 150px;
        font-size: 24px;
        padding-right: 1.5rem;
        margin-right: 2rem;
        border-width: 2px;
      }
    }
    .runner-title {
      position: relative;
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      .name {
        display: inline-block;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #2b2b2b;
        @media (min-width: 768px) {
          margin-right: 2rem;
          font-size: 46px;
          line-height: 56px;
        }
      }
      .bib {
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;

        @media (min-width: 768px) {
          margin-right: 2rem;
          font-size: 22px;
          line-height: 27px;
        }

        span {
          display: inline-block;
          background-color: var(--primary-color);
          border-radius: 4px;
          padding: 0.1rem 0.3rem;
          @media (min-width: 768px) {
            padding: 0.25rem 0.5rem;
          }
        }
      }
      .detail {
        display: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 14px;
        color: #6f7372;
        padding-right: 30px;
        @media (min-width: 768px) {
          display: inline-block;
        }
      }
      .share-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        img {
          cursor: pointer;
          height: auto;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#single-image-view {
  // .main-image-wrapper {
  //   @media (min-width: 768px) {
  //     max-width: 350px;
  //   }
  // }

  // &.landscape {
  //   .main-image-wrapper {
  //     @media (min-width: 768px) {
  //       max-width: 700px;
  //     }
  //   }
  // }

  .dropdown {
    & > button {
      padding: 0;
      background-color: transparent;
      border: none;
      &::after {
        display: none;
      }
    }
  }
  // .near-runners-title {
  //   font-weight: 600;
  //   font-size: 18px;
  //   margin: 1rem .5rem .5rem;
  //   color: var(--primary-color);
  // }
}
</style>
