<template>
  <div v-if="getFetchedEvent">
    <b-modal id="download-modal" modal-class="modal-fullscreen-md" hide-footer hide-header>
      <b-container fluid class="p-0">
        <b-row no-gutters>
          <b-col cols="12" class="text-center mrg-b">
            <div class="title">
              <span class="icon mr-2">
                <i class="fas fa-arrow-down"></i>
              </span>
              {{ isCert ? $t("download.cert") : $t("download.download") }}
              <img
                @click="closeModal()"
                class="img-fluid close-btn"
                src="@/assets/images/single-image-view/close.png"
              />
            </div>
          </b-col>
          <b-col
            v-if="image && !isCert"
            cols="12"
            class="text-center mrg-b pd-1"
          >
            <img class="img-fluid" :src="image.previewPath" />
            <div v-if="imageInfo" class="text-center hint mt-2">
              {{
                `${imageInfo.width} x ${imageInfo.height} PX ${$t(
                  "download.high_def"
                )} | ${moment(image.timing_date, ['YYYY-MM-DD hh:mm:ss']).format("MMM DD YYYY hh:mm:ss")}`
              }}
            </div>
          </b-col>
          <b-col v-if="isCert" cols="12" class="text-center mrg-b pd-1">
            <img class="w-100 img-fluid" :src="certImage" />
          </b-col>
          <b-col v-if="isBadge" cols="12" class="text-center mrg-b pd-1">
            <img class="w-100 img-fluid" :src="certImage" />
          </b-col>
          <b-col v-if="image && !isCert" cols="12" class="text-center pd-1">
            <button
              v-if="getFetchedEvent && getFetchedEvent.photos_purchase == '1'"
              class="submit-btn mb-2"
              @click="highDef()"
            >
              {{ $t("download.high_def") }}
            </button>
            <button
              v-if="
                getFetchedEvent &&
                getFetchedEvent.photos_purchase == '1' &&
                enableMultiDownload
              "
              class="submit-btn dark mb-2"
              @click="initMultiDownload()"
            >
              {{
                $t("download.get_all_count", {
                  count: getDownloadImages.length,
                })
              }}
            </button>
            <button class="submit-btn alt mb-2" @click="downloadWithStickers()">
              {{ $t("download.low_def") }}
            </button>
            <!-- <button v-if="getSelectedRunner" class="submit-btn alt1 mb-2" @click="imageResult()">{{$t('download.image_with_result')}}</button> -->
          </b-col>
          <b-col v-if="isCert" cols="12" class="text-center pd-2">
            <button class="submit-btn mb-2" @click="downloadCert('pdf')">
              {{ $t("download.download_cert") }}
            </button>
          </b-col>
          <b-col v-if="isBadge" cols="12" class="text-center pd-2">
            <button class="submit-btn mb-2" @click="downloadBadge()">
              {{ $t("download.download_badge") }}
            </button>
          </b-col>
          <b-col v-if="isCert" cols="12" class="text-center pd-2">
            <button class="submit-btn alt mb-2" @click="facebookShare()">
              <i class="icon fab fa-facebook-square mr-2"></i>
              {{ $t("download.share_facebook") }}
            </button>
          </b-col>
          <b-col v-if="isBadge" cols="12" class="text-center pd-2">
            <button class="submit-btn alt mb-2" @click="facebookShare('badge')">
              <i class="icon fab fa-facebook-square mr-2"></i>
              {{ $t("download.share_facebook") }}
            </button>
          </b-col>
          <b-col v-if="isCert" cols="12" class="text-center pd-2">
            <button class="submit-btn alt mb-2" @click="navigatorShare(certImage)">
              <i class="icon fas fa-share-alt mr-2"></i>
              Share
            </button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="download-multi-modal"
      modal-class="modal-fullscreen-md"
      hide-footer
      hide-header
      dialog-class="download-multi-modal-dialog"
    >
      <b-container fluid class="p-0">
        <b-row no-gutters>
          <b-col cols="12" class="text-center mrg-b">
            <div class="title">
              <span class="icon mr-2">
                <i class="fas fa-arrow-down"></i>
              </span>
              {{ $t("download.hi_res_title") }}
              <img
                @click="closeHiModal()"
                class="img-fluid close-btn"
                src="@/assets/images/single-image-view/close.png"
              />
            </div>
          </b-col>
          <b-col cols="12" class="text-center mrg-b card-cols">
            <b-card-group columns>
              <b-card
                v-for="(item, index) in getDownloadImages"
                :key="index"
                class="rcn"
                :img-src="item.previewPath"
                no-body
              ></b-card>
            </b-card-group>
          </b-col>
          <b-col cols="12" class="text-center mrg-b">
            <div class="text-center hint mt-2" v-if="getRunnerImages">
              {{
                `${getDownloadImages.length} ${$t(
                  "download.high_def_image"
                )} | ${getFetchedEvent.name}`
              }}
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters align-h="between">
          <b-col cols="12" md="6" class="order-md-12 text-right">
            <button class="submit-btn md-fix mb-2" @click="highDefMulti()">
              {{ $t("download.get_all") }}
            </button>
          </b-col>
          <b-col cols="12" md="6" class="order-md-1">
            <button class="submit-btn alt md-fix mb-2" @click="back()">
              {{ $t("core.back") }}
            </button>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col cols="12" class="text-left">
            <div class="hint-sm">
              {{
                $t("download.batch_hint", {
                  date: moment.utc(),
                })
              }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="purchase-success-modal"
      :title="$t('download.purchase_success')"
    >
      <div class="text-left" v-html="purchaseText"></div>
      <div class="text-left mt-3" v-show="!purchasedWithEmail">
        <span class="text-left">{{
          $t("download.generating_download_link_message")
        }}</span>
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <template #modal-footer>
        <b-button
          v-show="!purchasedWithEmail"
          class="submit-btn"
          @click="closePurchaseSuccessModal()"
        >
          OK
        </b-button>
        <b-button
          v-show="purchasedWithEmail"
          class="submit-btn alt"
          @click="closePurchaseSuccessModal()"
        >
          CLOSE
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          v-show="purchasedWithEmail"
          class="submit-btn"
          @click="downloadLink()"
        >
          Download
        </b-button>
      </template>
    </b-modal>
    <b-modal id="purchase-duplicate-modal" hide-footer hide-header>
      <div class="text-center my-2">{{ duplicateHint }}</div>
      <div class="text-center mt-4">
        <button class="submit-btn md-fix mb-2" @click="buyAgain()">
          {{ $t("core.yes") }}
        </button>
        <button class="submit-btn alt md-fix mb-2" @click="cancelPurchase()">
          {{ $t("core.cancel") }}
        </button>
      </div>
    </b-modal>
    <a
      ref="download-btn"
      class="d-none"
      :href="link"
      download
    ></a>
  </div>
</template>

<script>
import Eventbus from "../event-bus";
import is from "is-js";
import {
  imageDetail,
  // imageWithResult
} from "../api/index";
import { mapGetters } from "vuex";
import moment from "moment";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";

import formatter from "../utils/formatter";
import SearchBox from "../components/SearchBox";

export default {
  name: "download-modal",
  components: {
    SearchBox,
  },
  computed: {
    ...mapGetters([
      "getFetchedEvent",
      "getSurvey",
      "getSelectedRunner",
      "getRunnerImages",
      "getWindowSize",
      "getHideFacematches",
    ]),
    getDownloadImages() {
      if (!this.getHideFacematches) {
        return this.getRunnerImages;
      }
      let arr = [];
      if (this.getRunnerImages) {
        this.getRunnerImages.forEach((element, index) => {
          if (element.face == false) {
            arr.push(element);
          }
        });
      }
      return arr;
    },
    enableMultiDownload() {
      return (
        this.getDownloadImages &&
        this.getDownloadImages.length >= process.env.BATCH_MIN_COUNT &&
        this.getDownloadImages.length <= process.env.BATCH_MAX_COUNT &&
        !this.image.nearRunner
      );
    },
  },
  data() {
    return {
      image: null,
      imageInfo: null,
      isCert: false,
      isBadge: false,
      blank: "_blank",
      certImage: null,
      link: "",
      purchaseText: "",
      moment,
      duplicateHint: "",
      duplicateMulti: null,
      duplicatePayload: null,
      purchasedWithEmail: "",
    };
  },
  methods: {
    async callImageDetail(imageKey) {
      const resp = await imageDetail(imageKey);
      if (resp.data.detail) {
        this.imageInfo = resp.data.detail[0];
      }
    },
    // async callImageWithResult() {
    //   if (this.getSelectedRunner && this.image) {
    //     const resp = await imageWithResult({
    //       ext_race_id: this.getSelectedRunner.id,
    //       bib_number: this.getSelectedRunner.bib,
    //       image_key: this.image.image_key,
    //       first_name: this.getSelectedRunner.firstname,
    //       last_name: this.getSelectedRunner.lastname,
    //       finisher_data: {
    //         race_series: this.getSelectedRunner.result.race_name,
    //         finish_time: this.getSelectedRunner.result.chip
    //       }
    //     });
    //     if (resp.data && resp.data.result && resp.data.result["image url"]) {
    //       this.link = this.image.previewPath;
    //       setTimeout(() => {
    //         this.$refs["download-btn"].click();
    //         this.closeModal();
    //         this.showSurvey();
    //       }, 500);
    //     }
    //   }
    // },
    callFireTracking(type = "IMD", image = true) {
      let payload = {
        event_type: type,
        ext_race_id: this.getFetchedEvent.id,
      };
      if (image) {
        payload.image_id = this.image.image_id;
      }
      this.$store.dispatch("fireTracking", payload);
    },
    closeModal() {
      this.$bvModal.hide("download-modal");
    },
    closeHiModal() {
      this.$bvModal.hide("download-multi-modal");
    },
    closePurchaseSuccessModal() {
      this.$bvModal.hide("purchase-success-modal");
    },
    back() {
      this.$bvModal.hide("download-multi-modal");
      if (this.image) this.$bvModal.show("download-modal");
    },
    initMultiDownload() {
      this.callFireTracking("HRB", false);
      this.$bvModal.hide("download-modal");
      this.$bvModal.show("download-multi-modal");
    },
    startPurchase(payload, single = true) {
      let _this = this;
      if (single) {
        this.callFireTracking("HRI");
        var productId = process.env.PADDLE_SINGLE_DOWNLOAD_PRODUCT_ID;
        Paddle.Checkout.open({
          product: productId,
          passthrough: JSON.stringify(payload),
          allowQuantity: false,
          successCallback: function (order) {
            if (order.checkout.completed) {
              if (localStorage["single-image-purchase"]) {
                let stored = JSON.parse(localStorage["single-image-purchase"]);
                let index = stored.findIndex((item) => {
                  if (
                    item.ext_race_id === stored.ext_race_id &&
                    item.images === stored.images
                  ) {
                    return true;
                  }
                  return false;
                });
                if (index < 0) {
                  stored.push(payload);
                  localStorage["single-image-purchase"] =
                    JSON.stringify(stored);
                }
              } else {
                localStorage["single-image-purchase"] = JSON.stringify([
                  payload,
                ]);
              }
              _this.$bvModal.show("purchase-success-modal");
              _this.purchaseText = _this.$t("download.purchase_message", {
                email: order.user.email,
              });
              _this.getDownloadImages;
              $("html").removeClass(function (index, className) {
                return (className.match(/(^|\s)mvc__\S+/g) || []).join(" ");
              });
              _this.closeModal();
              _this.showSurvey();
              setTimeout((o) => {
                _this.showDownloadLink(order);
              }, 10000);
            }
          },
        });
      } else {
        this.callFireTracking("HRB2", false);
        var productId = process.env.PADDLE_BATCH_DOWNLOAD_PRODUCT_ID;

        Paddle.Checkout.open({
          product: productId,
          passthrough: JSON.stringify(payload),
          allowQuantity: false,
          successCallback: function (order) {
            if (order.checkout.completed) {
              let toStore = {
                ext_race_id: payload.ext_race_id,
                bib_number: payload.bib_number,
              };
              if (localStorage["batch-image-purchase"]) {
                let stored = JSON.parse(localStorage["batch-image-purchase"]);
                let index = stored.findIndex((item) => {
                  if (
                    item.ext_race_id === payload.ext_race_id &&
                    item.bib_number === payload.bib_number
                  ) {
                    return true;
                  }
                  return false;
                });
                if (index < 0) {
                  stored.push(toStore);
                  localStorage["batch-image-purchase"] = JSON.stringify(stored);
                }
              } else {
                localStorage["batch-image-purchase"] = JSON.stringify([
                  toStore,
                ]);
              }
              _this.$bvModal.show("purchase-success-modal");
              _this.purchaseText = _this.$t("download.purchase_message_s", {
                email: order.user.email,
              });
              $("html").removeClass(function (index, className) {
                return (className.match(/(^|\s)mvc__\S+/g) || []).join(" ");
              });
              _this.closeHiModal();
              _this.showSurvey();

              setTimeout((o) => {
                _this.showDownloadLink(order);
              }, 10000);
            }
          },
        });
      }
    },
    buyAgain() {
      if (this.duplicateMulti !== null)
        this.startPurchase(this.duplicatePayload, !this.duplicateMulti);
      this.$bvModal.hide("purchase-duplicate-modal");
    },
    cancelPurchase() {
      this.$bvModal.hide("purchase-duplicate-modal");
    },
    highDefPayload() {
      let payload = {
        images: this.image.image_id,
        ext_race_id: this.getFetchedEvent.id,
      };
      if (this.image.bib) {
        payload.bib_number = this.image.bib;
      }
      payload.uuid = uuidv4();
      return payload;
    },
    highDef() {
      this.duplicatePayload = null;
      this.duplicateMulti = null;
      let payload = {
        images: this.image.image_id,
        ext_race_id: this.getFetchedEvent.id,
      };
      if (this.image.bib) {
        payload.bib_number = this.image.bib;
      }
      if (localStorage["single-image-purchase"]) {
        let stored = JSON.parse(localStorage["single-image-purchase"]);
        let index = stored.findIndex((item) => {
          if (
            item.ext_race_id === payload.ext_race_id &&
            item.images === payload.images
          ) {
            return true;
          }
          return false;
        });
        if (index < 0) {
          this.startPurchase(payload);
        } else {
          this.duplicatePayload = payload;
          this.duplicateMulti = false;
          this.duplicateHint = this.$t("download.duplicate_single_purchase");
          this.$bvModal.show("purchase-duplicate-modal");
        }
      } else {
        this.startPurchase(payload);
      }
    },
    highDefMulti() {
      this.duplicatePayload = null;
      this.duplicateMulti = null;
      let ids = [];
      let bib = "";
      if (this.getDownloadImages.length > 0) {
        if (this.getDownloadImages[0].bib) {
          bib = this.getDownloadImages[0].bib;
        }
      }
      for (const key in this.getDownloadImages) {
        if (this.getDownloadImages.hasOwnProperty(key)) {
          const image = this.getDownloadImages[key];
          ids.push(image.image_id);
        }
      }
      let idStr = ids.join();
      let payload = {
        images: idStr,
        ext_race_id: this.getFetchedEvent.id,
      };
      if (bib !== "") {
        payload.bib_number = bib;
      }
      if (localStorage["batch-image-purchase"]) {
        let stored = JSON.parse(localStorage["batch-image-purchase"]);
        let index = stored.findIndex((item) => {
          if (
            item.ext_race_id === payload.ext_race_id &&
            item.bib_number === payload.bib_number
          ) {
            return true;
          }
          return false;
        });
        if (index < 0) {
          this.startPurchase(payload, false);
        } else {
          this.duplicatePayload = payload;
          this.duplicateMulti = true;
          this.duplicateHint = this.$t("download.duplicate_batch_purchase", {
            bib: bib ? bib : "",
          });
          this.$bvModal.show("purchase-duplicate-modal");
        }
      } else {
        this.startPurchase(payload, false);
      }
    },
    downloadWithStickers() {
      Eventbus.$emit("init-share-modal", {
        image: this.image,
        downloadOnly: true,
      });
    },
    lowDef() {
      this.callFireTracking('IMD', true)
      this.link = formatter.downloadImgPath() + this.image.image_key;
      console.log(this.link)
      if (
        this.getFetchedEvent.photos_purchase !== "1" &&
        this.getFetchedEvent.photos_purchase !== 1 &&
        this.link.indexOf("/preview/") >= 0
      ) {
        this.link = this.image.previewPath.replace("/preview/", "/download/");
      }
      this.blank = "_blank";
      setTimeout(() => {
        this.$refs["download-btn"].click();
        this.closeModal();
        this.showSurvey();
      }, 1500);
    },
    // imageResult() {
    //   this.callFireTracking("IMDPP");
    //   this.callImageWithResult();
    // },
    downloadCert(type) {
      this.callFireTracking("IMD", false);
      this.link = this.certImage;
      if (type == "pdf") {
        this.link = this.certImage.replace(".png", ".pdf");
      }
      this.blank = "_blank";
      setTimeout(() => {
        this.$refs["download-btn"].click();
        this.closeModal();
        this.showSurvey();
      }, 500);
    },
    downloadBadge() {
      this.callFireTracking("BMD", false);
      this.link = this.certImage;
      this.blank = "_blank";
      setTimeout(() => {
        this.$refs["download-btn"].click();
        this.closeModal();
        this.showSurvey();
      }, 500);
    },
    showSurvey() {
      if (!this.isCert) {
        if (this.image && this.image.previewPath) {
          Eventbus.$emit("show-survey", this.image.previewPath);
        }
      } else {
        Eventbus.$emit("show-survey", this.certImage);
      }
    },
    facebookShare(type) {
      if (!type) {
        Eventbus.$emit("share-facebook", {
          type: "cert",
          image: this.certImage,
        });
      } else {
        Eventbus.$emit("share-facebook", { type: type, image: this.certImage });
      }
    },
    showDownloadLink(order) {
      console.log(order);
      this.purchasedWithEmail = order.user.email;
      console.log(this.purchasedWithEmail);
    },
    downloadLink() {
      Eventbus.$emit("close-single-view-image", false);
      this.$bvModal.hide("purchase-success-modal");
      this.closeModal();
      this.closeHiModal();
      this.$router.push({
        name: "PurchasedPhotosWithEmail",
        params: {
          email: this.purchasedWithEmail,
        },
      });
    },
    navigatorShare(opt) {
      Eventbus.$emit("navigator-share", opt);
    }
  },
  mounted() {
    Eventbus.$on("init-download-modal", (image) => {
      if (image.type) {
        switch (image.type) {
          case "badge":
            this.isBadge = true;
            this.isCert = false;
            this.certImage = image.image;
            break;
          default:
            break;
        }
      } else if (!image.image_id) {
        this.isCert = true;
        this.blank = "_blank";
        this.certImage = image;
      } else {
        this.isCert = false;
        this.image = image;
        this.callImageDetail(image.image_id);
      }
      this.$bvModal.show("download-modal");
    });
    Eventbus.$on("direct-download", (image) => {
      if (image.image_id) {
        this.image = image;
        this.lowDef()
      }
    });
    Eventbus.$on("download-cert", (image) => {
      this.callFireTracking("IMD", false);
      this.link = image;
      this.blank = "_blank";
      setTimeout(() => {
        this.$refs["download-btn"].click();
      }, 1500);
    });
    Eventbus.$on("direct-batch-download", () => {
      this.initMultiDownload();
    });
  },
  beforeDestroy() {
    Eventbus.$off("init-download-modal");
    Eventbus.$off("direct-download");
    Eventbus.$off("download-cert");
    Eventbus.$off("direct-batch-download");
  },
};
</script>

<style lang="scss" scoped>
#download-modal,
#download-multi-modal,
#purchase-duplicate-modal {
  .img-fluid {
    max-height: 60vh;
  }
  .card-cols {
    max-height: 300px;
    overflow-y: auto;
    @media (min-width: 768px) {
      max-height: 600px;
    }
  }
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
    column-gap: 0.25rem;
    @media (min-width: 576px) {
      -webkit-column-count: 6;
      -moz-column-count: 6;
      column-count: 6;
      -webkit-column-gap: 0.25rem;
      -moz-column-gap: 0.25rem;
      column-gap: 0.25rem;
    }
    @media (min-width: 768px) {
      -webkit-column-count: 8;
      -moz-column-count: 8;
      column-count: 8;
    }

    .card {
      display: inline-block;
      width: 100%;
      margin-bottom: 0.25rem;
    }
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    color: #6f7372;
    padding-left: 10px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .mrg-b {
    margin-bottom: 20px;
  }
  .pd-1 {
    padding: 0 10px;
  }
  .pd-2 {
    padding: 0 30px;
  }
  .hint {
    font-size: 12px;
    color: #6f7372;
  }
  .hint-sm {
    font-size: 11px;
    color: #6f7372;
  }
  .card {
    pointer-events: none;
  }
  .submit-btn {
    display: block;
    cursor: pointer;
    width: 100%;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;
    border: 1px solid var(--primary-color);
    font-size: calc(min(18px, 4vw));
    text-transform: uppercase;
    font-weight: 900;

    &.alt,
    &.alt1 {
      background-color: #fff;
      border: 1px solid #6f7372;
      color: #6f7372 !important;
      &:hover {
        background-color: lightgray;
      }
    }
    &.dark {
      background-color: var(--dark-color);
    }
    &.md-fix {
      @media (min-width: 768px) {
        display: inline-block;
        max-width: 300px;
      }
    }
  }
  .close-btn {
    cursor: pointer;
  }
  span.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6f7372;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    i {
      color: #fff;
      font-size: 16px;
    }
  }
  .loading {
    width: 50px;
    height: auto;
  }
}

#purchase-success-modal {
  .submit-btn {
    display: block;
    cursor: pointer;
    width: auto;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;
    border: 1px solid var(--primary-color);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;

    &.alt,
    &.alt1 {
      background-color: #fff;
      border: 1px solid #6f7372;
      color: #6f7372 !important;
      &:hover {
        background-color: lightgray;
      }
    }
    &.dark {
      background-color: var(--dark-color);
    }
    &.md-fix {
      @media (min-width: 768px) {
        display: inline-block;
        max-width: 300px;
      }
    }
  }
}
</style>
<style lang="scss">
.download-multi-modal-dialog {
  @media (min-width: 576px) {
    max-width: 80%;
    margin: 1.75rem auto;
  }
}
</style>
