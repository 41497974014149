<template>
  <div
    class="search-box"
    :class="{ [`${customClass}`]: true }"
    :id="`search-box-${id}`"
  >
    <div class="input-search-wrapper">
      <b-form-input
        ref="normal-search-input"
        class="search-input"
        size="sm"
        v-model="navSearch"
        @keyup="refreshSearchBuffer"
        @keyup.delete="hideSearchResult()"
        trim
        :placeholder="$t(getPlaceholder())"
      ></b-form-input>
      <div>
        <i
          v-if="this.navSearch == ''"
          class="fa fa-search"
        ></i>
        <i
          v-else
          @click="hideSearchBar()"
          class="fa fa-times"
        ></i>
      </div>
      <type-loader
        ref="type-loader"
        :holdPadding="35"
        :name="`${name}-type-loader`"
      />
      <div v-if="showSearchResult" class="search-auto-complete">
        <div
          class="search-auto-complete-wrapper"
          v-if="navSearchResult.length > 0"
        >
          <div
            class="item"
            :class="element.type"
            v-for="(element, index) in navSearchResult"
            :key="`nav-search-${index}`"
            @click="selectRunner(element)"
          >
            <div v-if="element.type == 'team' && normalSearch">
              <i class="fas fa-users"></i>
              <span class="team" v-html="element.team_name"> </span>
            </div>
            <div v-if="element.type == 'team' && normalSearch">
              <i class="fas fa-running"></i>
              <span class="runners">
                {{ element.member_count }}
              </span>
              {{ $t("searchbox.runners") }}
            </div>

            <div
              v-if="element.type == 'runner' && normalSearch"
              v-html="getSearchResultText(element, !normalSearch)"
            ></div>
            <div v-if="element.type == 'runner' && normalSearch">
              <i class="fas fa-flag-checkered"></i>
              {{ element.reg_race_name }}
              <span
                class="bib"
                v-if="
                  getFetchedEvent.results_searchable != '2' &&
                    (element.bib_number || element.team_bib_number) &&
                    (element.bib_number || element.team_bib_number).length < 8
                "
                >{{ element.bib_number || element.team_bib_number }}</span
              >
            </div>
            <div
              v-if="element.type == 'runner' && !normalSearch"
              v-html="getSearchResultText(element, !normalSearch)"
            ></div>
            <div v-if="element.type == 'runner' && !normalSearch">
              <i class="fas fa-flag-checkered"></i>
              <a
                v-if="element.category && element.category.name"
                class="ml-0 mr-2"
                >{{ element.category.name }}</a
              >
              <span
                v-if="getFetchedEvent.results_searchable != '2' && element.bib"
                class="ml-0 bib"
                >{{ element.bib }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="search-auto-complete-wrapper"
          v-if="navSearchResult.length == 0"
        >
          <div class="item">{{ $t("core.no_result") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Eventbus from "../event-bus";
import formatter from "../utils/formatter";

import { searchRunner, legacySearchRunner } from "../api/index";

import TypeLoader from "./TypeLoader";

export default {
  name: "search-box",
  components: {
    TypeLoader
  },
  props: {
    customClass: {
      type: String,
      default: ""
    },
    raceId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    syncInput: {
      type: Boolean,
      default: false
    },
    tagBib: {
      type: Boolean,
      default: false
    },
    htmlFunction: {
      type: Boolean,
      default: false
    },
    searchForTeam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatter,
      navSearch: "",
      navSearchResult: [],
      navSearching: false,
      searchBuffer: 0,
      showSearchResult: false,
      id: Math.random()
        .toString(36)
        .substr(2, 10),
      refreshTimeoutSpeed: 300
    };
  },
  computed: {
    ...mapGetters(["getFetchedEvent", "getLegacy", "getHeaderHeight"]),
    normalSearch() {
      let check = false;
      if (
        !this.getLegacy &&
        this.getFetchedEvent
        // this.getFetchedEvent.photos_available == "1"
      ) {
        check = true;
      }
      return check;
    }
  },
  methods: {
    async callSearchRunner(keywords) {
      const resp = await searchRunner(this.getFetchedEvent.id, keywords);
      // this.navSearchResult = this.filterNullBib(resp.data, false);
      this.navSearchResult = this.searchForTeam
        ? resp.data
        : resp.data.filter(item => item.type == "runner" || item.type == null);

      this.navSearchResult = this.navSearchResult.map(item => {
        return { ...item, type: item.type == "team" ? "team" : "runner" };
      });
      this.showSearchResult = true;
      if (this.htmlFunction) this.$emit("searchResult", this.navSearchResult);
      this.setLoading(false);
    },
    async callLegacySearchRunner(keywords, isBib) {
      const resp = await legacySearchRunner(
        this.getFetchedEvent.id,
        keywords,
        isBib
      );
      // this.navSearchResult = this.filterNullBib(resp.data.body, true);
      this.navSearchResult = this.searchForTeam
        ? resp.data.body
        : resp.data.body.filter(
            item => item.type == "runner" || item.type == null
          );
      this.navSearchResult = this.navSearchResult.map(item => {
        return { ...item, type: item.type == "team" ? "team" : "runner" };
      });

      this.showSearchResult = true;
      if (this.htmlFunction) this.$emit("searchResult", this.navSearchResult);
      this.setLoading(false);
    },
    filterNullBib(arr, legacy) {
      let result = [];
      arr.forEach(item => {
        if (legacy) {
          if (item.bib != null) {
            result.push(item);
          }
        } else {
          if (item.bib_number != null || item.team_bib_number != null) {
            result.push(item);
          }
        }
      });
      return result;
    },
    isBib(value) {
      var patt = /^[a-zA-z]{0,4}[0-9]{1,5}$/g;
      return patt.test(value);
    },
    isWord(value) {
      var patt = /^[a-zA-Z\s]{3,}$/g;
      return patt.test(value);
    },
    searchRule(value) {
      let allow = $.isNumeric(value) && Math.floor(value) == value;
      if (!allow) {
        if (value.length > 2) {
          allow = true;
        }
      }
      return allow;
    },
    hideSearchResult() {
      this.showSearchResult = false;
    },
    refreshSearchBuffer(e) {
      if (this.syncInput) {
        this.$emit("syncInput", this.navSearch);
      }
      if (e.key != "Escape") {
        if (this.navSearch.trim() == "" || !this.searchRule(this.navSearch)) {
          this.searchBuffer = 0;
          this.showSearchResult = false;
          this.setLoading(false);
          this.navSearching = false;
        } else {
          this.searchBuffer = 301;
          this.setLoading(true);
          if (!this.navSearching) {
            this.navSearching = true;
            this.refreshTimeout();
          }
        }
      }
    },
    refreshTimeout: function() {
      setTimeout(() => {
        if (this.searchBuffer < this.refreshTimeoutSpeed) {
          if (this.searchRule(this.navSearch)) {
            let word = this.handleSearchWord();

            if (this.normalSearch) {
              this.callSearchRunner(word);
            } else {
              this.callLegacySearchRunner(word, this.isBib(this.navSearch));
            }
            this.navSearching = false;
          }
        } else {
          // this.navSearching = true;
          this.searchBuffer = this.searchBuffer - this.refreshTimeoutSpeed;
          this.refreshTimeout();
        }
      }, this.refreshTimeoutSpeed);
    },
    handleSearchWord() {
      let searchWord = this.navSearch;
      if (
        searchWord != "" &&
        this.isBib(searchWord) &&
        !this.isWord(searchWord)
      ) {
        var init_alpha_bib = /^[a-zA-Z]+[0-9]{1,2}$/g;
        var init_alpha = /^[a-zA-z]+/g;
        var number_part = /[0-9]+$/g;
        var leading_zero = /^[0]{1}[0-9]{1}/g;
        if (init_alpha_bib.test(searchWord)) {
          //auto append 0
          var word_first = searchWord.match(init_alpha);
          var word_padding = "0";
          var word_last = searchWord.match(number_part);
          if (leading_zero.test(word_last[0])) {
          } else if (word_last.length > 0 && word_last[0].length == 1) {
            word_padding = "00";
          }
          searchWord = word_first + word_padding + word_last;
        }
      }
      return searchWord;
    },
    selectRunner(runner) {
      this.$store.dispatch("fireTracking", {
        event_type: "SBR",
        ext_race_id: this.getFetchedEvent.id,
        keyword: this.navSearch
      });
      let tmp = this.convertRunnerData(runner);
      this.showSearchResult = false;
      this.navSearch = "";
      this.$emit("selectRunner", tmp);
    },
    convertRunnerData(runner) {
      if (runner.type == "team") {
        return {
          ...runner,
          bib: runner.team_bib_number,
          registrant_id: runner.team_registration_id
        };
      }
      var tmp = runner;
      if (this.normalSearch) {
        tmp = {
          isTeam: false,
          type: runner.type,
          age: runner.age,
          bib: runner.bib_number,
          firstname: runner.first_name,
          gender: runner.gender,
          lastname: runner.last_name,
          registrant_id: runner.registration_id,
          race_name: runner.reg_race_name
        };
        if (runner.team_name) {
          tmp.team_name = runner.team_name;
        }
      } else {
        tmp.registrant_id = runner.registration_id
          ? runner.registration_id
          : runner.registrant_id;
        tmp.isTeam = false;
        tmp.race_name = runner.category
          ? runner.category.name
            ? runner.reg_race_name
            : null
          : null;
      }
      return tmp;
    },
    setInput(val) {
      this.navSearch = val;
    },
    setLoading(loading) {
      if (this.$refs["type-loader"]) {
        if (loading) {
          this.$refs["type-loader"].setPosition(this.navSearch);
        } else {
          this.$refs["type-loader"].close();
        }
      }
      if (this.htmlFunction) {
        this.$emit("setLoading", loading);
      }
    },
    getSearchResultText(item, legacy) {
      let text = "";
      let result = "";
      if (legacy) {
        text = `${item.firstname} ${item.lastname} - `;
      } else {
        text = `${item.first_name} ${item.last_name} - `;
      }
      if (item.age) {
        text += `${item.age}`;
        if (item.gender) {
          text += `/${this.formatter.formatStr(item.gender)}`;
        }
      } else {
        if (item.gender) {
          text += `${this.formatter.formatStr(item.gender)}`;
        }
      }
      if (item.team_name) {
        text += ` - ${item.team_name}`;
      }
      let index = text.toLowerCase().indexOf(this.navSearch.toLowerCase());
      if (index >= 0) {
        let end = index + this.navSearch.length;
        for (let i = 0; i < text.length; i++) {
          const element = text[i];
          if (i == index) {
            result += "<strong>";
          } else if (i == end) {
            result += "</strong>";
          }
          result += element;
        }
      }
      return result == "" ? text : result;
    },
    hideSearchBar() {
      this.navSearch = "";
      this.navSearchResult = [];
      this.navSearching = false;
      this.searchBuffer = 0;
      this.showSearchResult = false;
    },
    focusInput() {
      if (this.$refs["normal-search-input"]) {
        this.$nextTick(() => this.$refs["normal-search-input"].focus());
      }
    },
    searchByOutside(e, keyword) {
      this.navSearch = keyword;
      this.refreshSearchBuffer(e);
    },
    getPlaceholder() {
      if (this.getFetchedEvent) {
        switch (this.getFetchedEvent.results_searchable) {
          case "2":
            return this.searchForTeam
              ? "faceSearch.placeholder_5"
              : "faceSearch.placeholder_2";
          case "3":
            return this.searchForTeam
              ? "faceSearch.placeholder_6"
              : "faceSearch.placeholder_3";
          default:
            return this.searchForTeam
              ? "faceSearch.placeholder_4"
              : "faceSearch.placeholder_1";
        }
      }
    }
  },
  mounted() {
    Eventbus.$on("close-search-box-result", () => {
      this.showSearchResult = false;
    });

    let _this = this;

    if (this.tagBib) {
      $("body").on("click", `#search-box-${this.id} input`, function(e) {
        e.stopPropagation();
      });
    }

    $("#app").on("click", function(e) {
      if (
        $(".search-auto-complete").length > 0 &&
        !$(".search-auto-complete").is(e.target) &&
        $(".search-auto-complete").has(e.target).length === 0 &&
        _this.showSearchResult &&
        $(".input-search-wrapper").length > 0 &&
        !$(".input-search-wrapper").is(e.target) &&
        $(".input-search-wrapper").has(e.target).length === 0
      ) {
        _this.showSearchResult = false;
      }
    });
  },
  beforeDestroy() {
    Eventbus.$off("close-search-box-result");
  }
};
</script>

<style lang="scss" scoped>
.search-box {
  position: relative;
  width: 100%;
  .input-search-wrapper {
    display: flex;
    align-items: center;
    background: #ffffff;
    padding: 0 0.75rem 0 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid var(--light-gray);

    .search-input {
      border: none;
    }

    .fa, .fas {
      color: var(--primary-color)
    }

    .search-auto-complete {
      position: absolute;
      width: 100%;
      top: 0100%;
      left: 0;
      background: #ffffff;
      border: 1px solid var(--primary-color);
      font-size: 0.9rem;
      color: var(--gray);
      max-height: 300px;
      overflow-y: auto;

      .search-auto-complete-wrapper {
        .item {
          cursor: pointer;
          padding: 0.25rem 0.75rem;
          border-bottom: 1px solid var(--primary-color);

          &:hover {
            background: var(--alpha-color);
          }

          & :nth-child(2) {
            font-size: 0.8rem;

            span {
              border: 1px solid var(--gray);
              padding: 0 0.25rem;
              border-radius: 0.25rem;
              margin-left: 1rem;
              font-size: smaller;
            }
          }
        }
      }
    }
  }
}
</style>