<template>
  <div id="app">
    <app-header />
    <error-overlay />
    <router-view />
    <app-footer />
    <single-image-view />
    <tag-bib-modal />
    <untag-bib-modal />
    <email-photo-modal />
    <download-modal />
    <share-modal />
    <survey-modal />
    <b-toast
      id="general-toast"
      :title="toastMsg"
      :auto-hide-delay="toastDelay"
      solid
    ></b-toast>
    <b-alert 
      id="general-alert" 
      :show="showAlert"
      variant="light" 
      dismissible 
      @dismissed="showAlert=false"
      fade
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000;"
    >
      <div class="d-flex flex-row align-items-center" style="gap:1em">
        <div>
          {{alertMsg}}
        </div>
        <div>
          <b-spinner v-show="showAlertSpinner" small variant="secondary" label="Loading..."></b-spinner>
        </div>
        <div>
          <b-button v-show="alertAction" small variant="secondary" @click="clickAlertAction">{{alertAction}}</b-button>
        </div>
      </div>
    </b-alert>
    <loader />
    <api-error />
  </div>
</template>

<script>
import Eventbus from "./event-bus";
import { mapGetters } from "vuex";
import { getEnmotiveUserId, getVideoStatus } from "./api/index";
import formatter from "./utils/formatter";
import $ from "jquery";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import debounce from "debounce";

import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import SingleImageView from "./components/SingleImageView";
import Loader from "./components/Loader";
import TagBibModal from "./components/TagBibModal";
import UntagBibModal from "./components/UntagBibModal";
import EmailPhotoModal from "./components/EmailPhotoModal";
import DownloadModal from "./components/DownloadModal";
import ShareModal from "./components/ShareModal";
import ApiError from "./components/ApiError";
import SurveyModal from /* webpackPrefetch: true */  "./components/SurveyModal";
import ErrorOverlay from "./components/ErrorOverlay";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    SingleImageView,
    Loader,
    TagBibModal,
    UntagBibModal,
    EmailPhotoModal,
    DownloadModal,
    ShareModal,
    ApiError,
    SurveyModal,
    ErrorOverlay,
  },
  data() {
    return {
      formatter,
      toastMsg: "",
      toastDelay: 2000,
      alertMsg: "",
      showAlert: false,
      showAlertSpinner: true,
      alertAction: false,
      alertRoute: false,
      lockScreen: false,
      hardLock: false,
      scrollLock: false,
      persistentToast: false,
      locale: 'en'
    };
  },
  computed: {
    ...mapGetters([
      "getFetchedEvent",
      "getCurrentNearRunner",
      "getSelectedRunner",
      "getWindowSize",
      "getColor",
      "getCreateVideoRequestId",
    ]),
  },
  methods: {
    async loadEnmotiveUserId() {
      const resp = await getEnmotiveUserId();
      this.$store.dispatch("setEnmotiveUserId", resp.data);
    },
    handleResize() {
      this.$store.dispatch("setWindowSize", {
        width: $(window).width(),
        height: window.innerHeight,
      });
    },
    handleKeyUp(e) {
      if (e.key == "Escape") {
        Eventbus.$emit("close-single-view-image");
        Eventbus.$emit("close-search-box-result");
      } else if (e.keyCode == 37) {
        Eventbus.$emit("left-image");
      } else if (e.keyCode == 39) {
        Eventbus.$emit("right-image");
      }
    },
    handleScroll(e) {
      if (this.$route.name === "RaceHome") {
        Eventbus.$emit("race-gallery-scroll");
        Eventbus.$emit("race-gallery-top-scroll");
      }
      if (
        this.$route.name === "RunnerHome" ||
        this.$route.name === "TeamHome"
      ) {
        Eventbus.$emit("runner-home-scroll");
        Eventbus.$emit("runner-gallery-scroll");
        Eventbus.$emit("runner-sticky-scroll");
      }
      if (this.$route.name === "ScrollView") {
        Eventbus.$emit("scrollview-scroll");
      }

      Eventbus.$emit("header-scroll");
      Eventbus.$emit("close-search-box-result");
      this.dismissTooltip();

      $(".dropdown.show .dropdown-menu.show").removeClass("show");
      $(".dropdown.show").removeClass("show");
    },
    dismissTooltip() {
      this.$root.$emit("bv::hide::tooltip");
    },
    changeLanguage(lang) {
      if (lang == "en" || lang == "es") {
        //TODO: Change laguage here
        this.locale = lang
        this.$i18n.locale = lang
      }
    },
    popup(shareLink, title) {
      if (!window.focus) return;
      var href;
      if (typeof shareLink == "string") {
        href = shareLink;
      } else {
        href = shareLink.href;
      }
      var windowname = title;
      var width = 600;
      var height = 350;
      window.open(
        href,
        windowname,
        `resizable=yes,width=${width},height=${height},scrollbars=yes`
      );
    },
    domStrDecode(str) {
      var parser = new DOMParser();
      var dom = parser.parseFromString(
        "<!doctype html><body>" + str,
        "text/html"
      );
      return dom.body.textContent;
    },
    getIcon() {
      let logo = this.getFetchedEvent
        ? this.getFetchedEvent.images.logo
          ? this.getFetchedEvent.images.logo
          : ""
        : "";
      if (
        logo.indexOf(".png") >= 0 ||
        logo.indexOf(".jpg") >= 0 ||
        logo.indexOf(".jpeg") >= 0 ||
        logo.indexOf(".gif") >= 0
      ) {
        return logo;
      }
      return null;
    },
    shareWithFacebook(payload) {
      let image = null;
      if (
        payload.type == "gallery" ||
        payload.type == "cert" ||
        payload.type == "badge" ||
        payload.type == "updated"
      ) {
        image = payload.image;
      }
      if (this.getFetchedEvent) {
        var fb_share_title = this.domStrDecode(this.getFetchedEvent.name);
        var fb_share_desc = this.$t("facebook.desc", { name: fb_share_title });
        var shareLink = window.location.href;
        if (payload.type == "updated") {
          const split = shareLink.split("?");
          if (split.length > 0) {
            shareLink = split[0];
          }
        }
        // var shareLink = "https://raceday-dev.enmotive.com/";
        if (this.$route.name === "ScrollView") {
          shareLink = shareLink.replace("/scroll/", "/events/");
        }
        if (this.getSelectedRunner && this.getCurrentNearRunner) {
          shareLink = this.formatter.formatNearRunnerUrl(
            this.getSelectedRunner,
            this.getCurrentNearRunner,
            shareLink
          );
        }
        if (payload.type == "cert") {
          if (shareLink.indexOf("?") >= 0) {
            shareLink += "&cert=true";
          } else {
            shareLink += "?cert=true";
          }
        }
        if (payload.type == "badge") {
          if (shareLink.indexOf("?") >= 0) {
            shareLink += "&badge=true";
          } else {
            shareLink += "?badge=true";
          }
        }
        if (this.getFetchedEvent.sharing.facebook.default_text != "") {
          fb_share_desc = this.domStrDecode(
            this.getFetchedEvent.sharing.facebook.default_text
          ).replace("{{page_link}}", shareLink);
        } else {
          fb_share_desc += shareLink;
        }

        var shareobject = {
          "og:url": shareLink,
          "og:title": fb_share_title,
          "og:description": fb_share_desc,
        };

        if (payload.type == "gallery" || payload.type == "updated") {
          shareobject["og:image"] = image.previewPath;
          shareobject["og:image:width"] = image.width;
          shareobject["og:image:height"] = image.height;
          this.$store.dispatch("fireTracking", {
            event_type: "IMS",
            ext_race_id: this.getFetchedEvent.id,
            image_id: image.image_id,
          });
        } else if (payload.type == "cert") {
          shareobject["og:image"] = image;
          this.$store.dispatch("fireTracking", {
            event_type: "IMS",
            ext_race_id: this.getFetchedEvent.id,
          });
        } else if (payload.type == "badge") {
          shareobject["og:image"] = image;
          this.$store.dispatch("fireTracking", {
            event_type: "BMS",
            ext_race_id: this.getFetchedEvent.id,
          });
        } else {
          let icon = this.getIcon();
          if (icon) {
            shareobject["og:image"] = icon;
            this.$store.dispatch("fireTracking", {
              event_type: "IMS",
              ext_race_id: this.getFetchedEvent.id,
            });
          }
        }

        var shareEnabledUrl = `${window.location.protocol}//${
          window.location.hostname
        }${
          window.location.port ? ":" + window.location.port : ""
        }/share?p=${btoa(
          JSON.stringify(shareobject).replace(/[^\x00-\x7F]/g, "")
        )}`;

        //var shareEnabledUrl = `http://raceday-dev.enmotive.com/share?p=${btoa(JSON.stringify(shareobject).replace(/[^\x00-\x7F]/g,""))}`;

        var facebookUrl = `https://www.facebook.com/dialog/share?app_id=${
          process.env.FACEBOOK_APP_ID
        }&display=popup&href=${encodeURIComponent(shareEnabledUrl)}`;
        this.popup(facebookUrl, this.$t("facebook.share_text"));

        Eventbus.$emit("close-share-modal");
        if (image) {
          Eventbus.$emit("show-survey", image.previewPath);
        } else {
          Eventbus.$emit("show-survey", null);
        }
      }
    },
    navigatorShare(payload) {
      let share = {
        title: this.getFetchedEvent.name,
        text: payload,
        url: window.location.href
      };
      navigator.share(share);
    },
    onBackKeyDown() {
      if (
        (this.$route.query && this.$route.query.view == 1) ||
        this.$route.name == "ScrollView"
      ) {
        setTimeout(() => {
          Eventbus.$emit("close-single-view-image");
          Eventbus.$emit("close-image-sroll-view");
        }, 200);
      }
      // if (
      //   this.$route.name === "RunnerHome" &&
      //   this.$route.query.view !== 1 &&
      //   this.getWindowSize.width < 768
      // ) {
      //   setTimeout(() => {
      //     Eventbus.$emit("runner-back-race-home");
      //   }, 200);
      // }
    },
    clickAlertAction() {
      this.alertAction=false
      this.showAlert=false;
      this.$router.push({ path: this.alertRoute, force: true})
      Eventbus.$emit("reload-runner-page", 1)
      return true
    },
    async checkVideoStatus() {
      if (this.getCreateVideoRequestId) {
        const resp = await getVideoStatus(this.getCreateVideoRequestId);
        if (
          resp.status == 200 &&
          resp.data &&
          resp.data.statusCode == 200 &&
          resp.data.data &&
          resp.data.data.success == true &&
          resp.data.data.resp
        ) {
          const respArray = resp.data.data.resp;
          const desktopItem = respArray.find((item) => item.mode === "desktop");
          if (
            desktopItem &&
            desktopItem.url != null &&
            desktopItem.url != "processing"
          ) {
            // popup notification
            this.alertMsg = this.$t("createVideo.message_success_created_video")
            this.alertAction = this.$t("createVideo.view_video_cta")
            this.showAlertSpinner = false
            this.showAlert = true
            // reload runner page
            this.$store.dispatch("setCreateVideoRequestId", null);
            return;
          }
        }

        setTimeout(this.checkVideoStatus, 30 * 1000);
      }
    },
  },
  mounted() {
    this.handleResize();
    this.loadEnmotiveUserId();

    Eventbus.$on("lock-screen", (lock) => {
      this.lockScreen = lock;
      if (lock) {
        if (this.getWindowSize && this.getWindowSize.width < 768) {
          $("html, body").scrollTop(0);
        }
        $("html, body").addClass("lock");
      } else {
        $("html, body").removeClass("lock");
      }
    });

    Eventbus.$on("hard-lock", (lock) => {
      this.hardLock = lock;
      if (lock) {
        $("html, body").addClass("hard-lock");
      } else {
        $("html, body").removeClass("hard-lock");
      }
    });

    Eventbus.$on("lock-scroll", (scrollLock) => {
      this.scrollLock = scrollLock;
      let targetElement = document.body;
      if (scrollLock) {
        disableBodyScroll(targetElement);
      } else {
        enableBodyScroll(targetElement);
      }
    });

    Eventbus.$on("share-facebook", (payload) => {
      this.shareWithFacebook(payload);
    });

    Eventbus.$on("navigator-share", (payload) => {
      this.navigatorShare(payload);
    });

    Eventbus.$on("show-toast", (msg) => {
      this.toastMsg = msg;
      this.toastDelay = 5000;
      this.$bvToast.show("general-toast");
    });

    Eventbus.$on("show-spinner-alert", (msg, route) => {
      this.alertMsg=msg
      this.alertRoute=route
      this.showAlert=true
      this.showAlertSpinner=true
    });
    Eventbus.$on("test-spinner-alert", () => {
      this.alertMsg = this.$t("createVideo.message_success_created_video")
      this.alertAction = this.$t("createVideo.view_video_cta")
      this.showAlertSpinner = false
      this.showAlert = 15
    });

    Eventbus.$on("change-local", (locale) => {
      this.changeLanguage(locale);
    });

    Eventbus.$on("sent-request-create-video", () => {
      setTimeout(this.checkVideoStatus, 30 * 1000);
    });
  },
  watch: {
    //color theme
    getColor(val, old) {
      let primary = `#f99f27`;
      let dark = `#f36e22`;
      let light = `#fad961`;
      let alpha = `#f99f2730`;
      let disabled = `#c6c8c8`;
      if (val) {
        primary = this.getColor.primary;
        alpha = `${this.getColor.primary}30`;
        dark = this.getColor.dark;
        light = this.getColor.light;
        disabled = this.getColor.disabled;
        if (!disabled) {
          disabled = this.getColor.grey;
        }
      }
      let bodyStyles = document.body.style;
      bodyStyles.setProperty("--primary-color", primary);
      bodyStyles.setProperty("--dark-color", dark);
      bodyStyles.setProperty("--light-color", light);
      bodyStyles.setProperty("--alpha-color", alpha);
      bodyStyles.setProperty("--disabled-color", disabled);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("scroll", debounce(this.handleScroll, 200, false));
    window.onpopstate = this.onBackKeyDown;
  },
  beforeDestroy() {
    Eventbus.$off("lock-screen");
    Eventbus.$off("lock-scroll");
    Eventbus.$off("hard-lock");
    Eventbus.$off("share-facebook");
    Eventbus.$off("navigator-share");
    Eventbus.$off("show-toast");
    Eventbus.$off("change-local");
    Eventbus.$off("sent-request-create-video");
    Eventbus.$off("show-spinner-alert");
    clearAllBodyScrollLocks();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
@import "./assets/fonts/font.css";
@import "./plugins/fontawesome/css/all.min.css";

:root {
  --primary-color: #f99f27;
  --dark-color: #f36e22;
  --light-color: #fad961;
  --disabled-color: #c6c8c8;
  --alpha-color: #f99f2730;
}

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index.scss";

@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
      img.img-fluid {
        max-height: 50vh;
      }
    }
  }
}

/* BEGIN Doka Style Overrides */

.doka--root {
  --doka-effect--invert: 100%;
  --doka-color--alpha: var(--primary-color);
  --doka-color--alpha-dim: var(--light-color);
  @include media-breakpoint-up(sm) {
    --doka-button-main--radius: 0;
  }
}

/* END Doka Style Overrides */

.lock,
.hard-lock,
.lock-scroll {
  overflow: hidden;
}

.hard-lock {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

[sp-widget-holder="trust-badge"] {
  margin: 0 auto !important;
}

.paddle-frame {
  min-height: 100vh;
  position: fixed !important;
}

body {
  position: relative;
  font-family: Montserrat !important;
  font-style: normal;

  button,
  [type="button"],
  input {
    &:focus,
    &.form-control:focus {
      outline: none;
      box-shadow: none;
    }
  }

  #email-photo-modal,
  #download-modal {
    .modal-body {
      padding: 10px !important;
    }
  }

  .v-none {
    visibility: hidden;
  }

  .px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  #general-toast {
    .toast-header {
      button {
        margin-top: auto !important;
        margin-bottom: auto !important;
      }
    }
    .toast-body {
      display: none;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    .pagination-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        cursor: pointer;
      }
      span {
        padding: 0 0.5rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        &.current {
          color: var(--primary-color);
        }
      }
    }
  }

  @-webkit-keyframes typing {
    0% {
      background-color: rgba(1, 1, 1, 1);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 0.2),
        24px 0px 0px 0px rgba(1, 1, 1, 0.2);
    }
    25% {
      background-color: rgba(1, 1, 1, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 2),
        24px 0px 0px 0px rgba(1, 1, 1, 0.2);
    }
    75% {
      background-color: rgba(1, 1, 1, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 0.2),
        24px 0px 0px 0px rgba(1, 1, 1, 1);
    }
  }

  @-moz-keyframes typing {
    0% {
      background-color: rgba(1, 1, 1, 1);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 0.2),
        24px 0px 0px 0px rgba(1, 1, 1, 0.2);
    }
    25% {
      background-color: rgba(1, 1, 1, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 2),
        24px 0px 0px 0px rgba(1, 1, 1, 0.2);
    }
    75% {
      background-color: rgba(1, 1, 1, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 0.2),
        24px 0px 0px 0px rgba(1, 1, 1, 1);
    }
  }

  @keyframes typing {
    0% {
      background-color: rgba(1, 1, 1, 1);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 0.2),
        24px 0px 0px 0px rgba(1, 1, 1, 0.2);
    }
    25% {
      background-color: rgba(1, 1, 1, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 2),
        24px 0px 0px 0px rgba(1, 1, 1, 0.2);
    }
    75% {
      background-color: rgba(1, 1, 1, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(1, 1, 1, 0.2),
        24px 0px 0px 0px rgba(1, 1, 1, 1);
    }
  }
}

.race-masonry {
  padding: 0.5rem 0 0;
  .masonry-item {
    display: inline-block;
    padding: 0 0.25rem 0.5rem;
    width: 50%;
    @media (min-width: 768px) {
      width: 33.3333%;
    }
    @media (min-width: 992px) {
      width: 20%;
    }
    @media (min-width: 1200px) {
      width: 16.6667%;
    }

    &.x1 {
      width: 50%;
      @media (min-width: 768px) {
        width: 33.3333%;
      }
      @media (min-width: 992px) {
        width: 20%;
      }
      @media (min-width: 1200px) {
        width: 16.6667%;
      }
    }

    &.x2 {
      width: 100%;
      @media (min-width: 768px) {
        width: 66.6667%;
      }
      @media (min-width: 992px) {
        width: 40%;
      }
      @media (min-width: 1200px) {
        width: 33.3334%;
      }
    }

    &.x3 {
      width: 100%;
      @media (min-width: 768px) {
        width: 100%;
      }
      @media (min-width: 992px) {
        width: 60%;
      }
      @media (min-width: 1200px) {
        width: 50%;
      }
    }

    &.full {
      width: 100%;
      @media (min-width: 768px) {
        width: 100%;
      }
      @media (min-width: 992px) {
        width: 100%;
      }
      @media (min-width: 1200px) {
        width: 100%;
      }
    }
  }
}
#app {
  &.lock-screen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .text-primary {
    color: var(--primary-color);
  }
  .text-accent {
    color: var(--dark-color);
  }
  .text-normal {
    color: #2b2b2b;
  }
  .text-normal {
    color: #6f7372;
  }
  .text-white {
    color: #fff;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
    column-gap: 0.25rem;

    @media (min-width: 768px) {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    @media (min-width: 992px) {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
    @media (min-width: 1200px) {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
    }
    .card {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
