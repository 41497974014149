<template>
  <b-modal
    id="survey-modal"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    dialog-class="survey-modal-dialog"
  >
    <b-container fluid v-if="getSurvey && currentSurvey">
      <b-row class="mb-1">
        <b-col cols="10" class="d-flex align-items-center">
          <img class="w-100 img-fluid logo mr-2 mr-md-3" :src="getLogoPath" />
          <div class="title" v-html="getSurvey.survey_title"></div>
        </b-col>
        <b-col cols="2" class="text-right">
          <img
            @click="closeModal()"
            class="img-fluid close-btn"
            src="@/assets/images/single-image-view/close.png"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col
          cols="12"
          md="6"
          v-if="image"
          class="d-flex justify-content-center align-items-center"
        >
          <img class="w-100 img-fluid photo" :src="image" />
        </b-col>
        <div class="col-12 text-left" :class="{'col-md-6': image }">
          <div
            v-for="(question, index) in getSurvey.questions"
            :key="index"
            :class="{'d-none': currentSurvey.survey_question_id !== question.survey_question_id}"
          >
            <div class="my-2 question-title">{{question.question_title}}</div>
            <div class="my-2 question-content">{{question.question_content}}</div>
            <div class="q1" v-if="question.question_type === 1">
              <b-form-input
                v-model="form[question.survey_question_id]"
                type="text"
                :name="`${question.survey_question_id}`"
              ></b-form-input>
            </div>
            <div class="q2" v-if="question.question_type === 2">
              <b-form-group>
                <b-form-radio
                  v-model="form[question.survey_question_id]"
                  :name="`${question.survey_question_id}`"
                  value="1"
                >{{$t('survey.yes')}}</b-form-radio>
                <b-form-radio
                  v-model="form[question.survey_question_id]"
                  :name="`${question.survey_question_id}`"
                  value="0"
                >{{$t('survey.no')}}</b-form-radio>
              </b-form-group>
            </div>
            <div class="q4" v-if="question.question_type === 4">
              <b-form-group>
                <b-form-radio-group
                  v-model="form[question.survey_question_id]"
                  :options="getOption(question)"
                  :name="`${question.survey_question_id}`"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <div class="q5" v-if="question.question_type === 5">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="form[question.survey_question_id]"
                  :options="getOption(question)"
                  :name="`${question.survey_question_id}`"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
            <div class="q7" v-if="question.question_type === 7">
              <div class="form-group">
                <b-form-input
                  id="input-live"
                  v-model="userEmail"
                  :state="emailState"
                  type="email"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Enter your email"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{$t('survey.email_error')}}</b-form-invalid-feedback>
              </div>
              <p class="help-block">
                {{$t('survey.survey_hint')}}
                <a v-b-modal.tncModal class="btn btn-link px-0 py-0">{{$t('footer.terms')}}.</a>
              </p>
              <button
                @click="submit()"
                class="next-btn"
              >{{question.button_label == '' ? $t('core.submit') : question.button_label}}</button>
            </div>
            <div class="next-btn-wrapper" v-if="question.question_type < 6">
              <button
                @click="next(question)"
                class="next-btn"
              >{{question.button_label == '' ? $t('core.next') : question.button_label}}</button>
            </div>
          </div>
        </div>
        <b-col>
          <div class="error mt-2" v-if="showError">{{$t('survey.error')}}</div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Eventbus from "../event-bus";
import formatter from "../utils/formatter";
import is from "is-js";
import { submitSurvey } from "../api/index";
import { mapGetters } from "vuex";

export default {
  name: "survey-modal",
  components: {},
  computed: {
    ...mapGetters(["getFetchedEvent", "getSurvey", "getSelectedRunnerOrTeam"]),
    emailState() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.userEmail).toLowerCase());
    },
    getLogoPath() {
      return this.getSurvey.survey_logo
        ? `https://img1.enmotive.grepcv.com/survey_logo/${this.getSurvey.survey_logo}`
        : null;
    },
    shouldShowSurvey() {
      return (
        this.getFetchedEvent &&
        this.getSurvey &&
        this.getSurvey.survey_id != -1 &&
        this.getSurvey.questions &&
        this.getSurvey.questions.length > 0 &&
        !localStorage.surveyDone
      );
    }
  },
  data() {
    return {
      formatter,
      currentSurvey: null,
      step: 1,
      image: null,
      form: {},
      userEmail: "",
      forceNotShow: false,
      showError: false
    };
  },
  methods: {
    initSurvey() {
      this.currentSurvey = this.getSurvey.questions[0];
      this.form = {};
      this.$bvModal.show("survey-modal");
    },
    getOption(question) {
      return JSON.parse(question.options.replace(/\'/g, '"'));
    },
    closeModal() {
      this.data = {};
      this.image = null;
      this.$bvModal.hide("survey-modal");
    },
    checkAnswer(question) {
      if (this.form[question.survey_question_id]) {
        this.showError = false;
        return true;
      } else {
        this.showError = true;
        return false;
      }
    },
    next(question) {
      let passed = this.checkAnswer(question);
      if (question.question_type < 6 && passed) {
        let next = null;
        switch (question.question_type) {
          case 2:
            if (this.form[question.survey_question_id] == 1) {
              next = question.next_yes;
            } else {
              next = question.next_no;
            }
            break;
          case 6:
          case 7:
            break;
          default:
            next = question.next_default;
            break;
        }
        if (next) {
          let nextQ = this.formatter.getObjArrElementById(
            this.getSurvey.questions,
            next,
            "survey_question_id"
          );
          if (nextQ) {
            this.currentSurvey = nextQ.element;
            if (nextQ.element.question_type === 6) {
              this.submit(3000);
            }
          }
        }
      }
    },
    async submit(delay = 1) {
      let payload = {
        queryStringParameters: {
          race_id: this.getFetchedEvent.id,
          answers_json: this.form,
          survey_id: this.getSurvey.survey_id,
          bib: this.getSelectedRunnerOrTeam
            ? this.getSelectedRunnerOrTeam.data.bib
            : "-1",
          registration_id: this.getSelectedRunnerOrTeam
            ? this.getSelectedRunnerOrTeam.isTeam
              ? this.getSelectedRunnerOrTeam.data.id
              : this.getSelectedRunnerOrTeam.data.registrant_id
            : "-1",
          email: this.userEmail,
          partial: 0
        }
      };
      let resp = await submitSurvey(payload);
      localStorage.surveyDone = 1;
      this.forceNotShow = true;
      setTimeout(() => {
        this.closeModal();
        Eventbus.$emit("show-toast", this.$t("survey.success_hint"));
      }, delay);
    }
  },
  mounted() {
    Eventbus.$on("show-survey", image => {
      if (this.shouldShowSurvey && !this.forceNotShow) {
        if (image) {
          this.image = image;
        }
        this.currentSurvey = null;
        this.initSurvey();
      }
    });
  },
  beforeDestroy() {
    Eventbus.$off("show-survey");
  }
};
</script>

<style lang="scss" scoped>
#survey-modal {
  .logo {
    max-width: 30px;
  }
  .title {
    font-size: 18px;
  }
  .next-btn {
    display: block;
    cursor: pointer;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;
    border: none;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
  }
  .close-btn {
    cursor: pointer;
  }
  a {
    color: var(--primary-color);
  }
  .photo {
    @media (min-width: 768px) {
      max-width: 450px;
    }
  }
  .error {
    color: var(--primary-color);
  }
}
</style>
<style lang="scss">
.survey-modal-dialog {
  @media (min-width: 576px) {
    max-width: 900px;
    margin: 1.75rem auto;
  }
  .form-group {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
    }
  }
}
</style>