import Vue from 'vue';
import Router from 'vue-router';
const RaceHome = () => import(
  /* webpackChunkName: "home" */ 
  /* webpackPrefetch: true */
  '@/pages/RaceHome');
const RunnerHome = () => import(
  /* webpackChunkName: "home" */ 
  /* webpackPrefetch: true */
  '@/pages/RunnerHome');
const TeamHome = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "home" */ 
  '@/pages/TeamHome');
const PurchasedPhotos = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "purchasedPhotos" */ 
  '@/pages/PurchasedPhotos');
import ErrorPage from '@/pages/Error';
import ScrollView from '@/pages/ScrollView';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: `/events/${process.env.DEFAULT_EVENT}/results`
    },
    {
      path: '/purchased-photos',
      name: 'PurchasedPhotos',
      component: PurchasedPhotos,
      props: true,
    },
    {
      path: '/purchased-photos/email/:email',
      name: 'PurchasedPhotosWithEmail',
      component: PurchasedPhotos,
      props: true,
    },
    {
      path: '/purchased-photos/order/:order',
      name: 'PurchasedPhotosWithOrder',
      component: PurchasedPhotos,
      props: true,
    },
    {
      path: '/events',
      redirect: `/events/${process.env.DEFAULT_EVENT}/results`
    },
    {
      path: '/events/:raceId/registrants/:bib',
      name: 'RunnerHome',
      component: RunnerHome,
      props: true,
    },
    {
      path: '/events/:raceId/:bib/tab/:tab?',
      beforeEnter: (to, from, next) => {
        next({
          path: `/events/${to.params.raceId}/registrants/${to.params.bib}`,
          query: to.query
        });
      }
    },
    {
      path: '/events/:raceId',
      beforeEnter: (to, from, next) => {
        next({
          path: `/events/${to.params.raceId}/results`,
          query: to.query
        });
      }
    },
    {
      path: '/scroll/:raceId/gallery',
      name: 'ScrollView',
      component: ScrollView,
      props: true,
    },
    {
      path: '/events/:raceId/:tab',
      name: 'RaceHome',
      component: RaceHome,
      props: true,
      beforeEnter: (to, from, next) => {
        if(to.params.tab == 'gallery' || to.params.tab == 'results' || to.params.tab == 'info' || to.params.tab == undefined) {
          next();
        } else if(to.params.tab == 'search') {
          next({
            path: `/events/${to.params.raceId}/results`,
            query: to.query
          });
        } else {
          next({
            path: `/events/${to.params.raceId}/registrants/${to.params.tab}`,
            query: to.query
          });
        }
      }
    },
    {
      path: '/events/:raceId/teams/:teamId',
      name: 'TeamHome',
      component: TeamHome,
      props: true,
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorPage
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  // TODO: horrible hack to support redirecting a race for Kyle @ Enmotive
  if (to.params && to.params.raceId) {
    switch (to.params.raceId) {
      case '2024-rock-cf' : {
        next('events/2024-rock-cf-rivers-half-marathon-and-5k')
        break
      }
    }
  }
  if (!to.matched.length) {
    next('/error');
  } else {
    next();
  }
});

export default router;
