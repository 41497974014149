// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import { i18n } from "./plugins/i18n";
import { VueMasonryPlugin } from "vue-masonry";
import VueClipboard from "vue-clipboard2";
import VueScrollTo from "vue-scrollto";
import VueCookies from "vue-cookies";
import VueMobileDetection from "vue-mobile-detection";

Vue.use(BootstrapVue)
Vue.use(VueMasonryPlugin)
Vue.use(VueClipboard)
Vue.use(VueScrollTo)
Vue.use(VueCookies)
Vue.use(VueMobileDetection)

VueCookies.config("1y");

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "./assets/styles/custom.scss";

Vue.config.productionTip = false;

import GoogleAds from "./components/GoogleAds";
Vue.component("google-ads", GoogleAds);

// require svg icons
import Icon from "./components/SVGIcon.vue";
Vue.component("icon", Icon);

const request = require.context(
  "./assets/images/runner-home/icon",
  false,
  /\.svg$/
);
request.keys().map(request);
// console.log("request", request);
// console.log("request.keys", request.keys());
// console.log("request.id", request.id);
// console.log("request.resolve()", request.resolve("./cycling.svg"));
// console.log("request.resolve", request.resolve);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  i18n,
  components: { App },
  template: "<App/>"
});
