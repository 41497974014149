<template>
  <div v-if="showAdsense && show" :class="`google-ads ${customClass}`" :data-id="id">
    <vue-ins
      v-if="currentAds"
      :adsObj="currentAds"
    />
  </div>
</template>

<script>
import Eventbus from "../event-bus";
import { mapGetters } from "vuex";
import $ from 'jquery';
import VueIns from './VueIns';
import googleAdsData from '../data/google_ads.json';

export default {
	name: "google-ads",
	props: {
    name: {
			type: String,
			required: true
    },
    customClass: {
      type: String,
			default: ''
    },
    limitAds: {
      type: Boolean,
			default: false
    },
    maxInnerAds: {
      type: Number
    },
    hidden: {
      type: Boolean,
			default: false
    }
	},
  components: {
    VueIns
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 10),
      show: false,
      adsCount: 0,
      currentAds: null,
      templates: googleAdsData,
    };
  },
  computed: {
    ...mapGetters(["getFetchedEvent", "getWindowSize", "getNoAdTenantIdArray", "getShowAdsense", "getAdsCount", "getSingleImageAdsCount"]),
    showAdsense() {
      return this.getShowAdsense;
    },
    getIsMobile() {
      return this.getWindowSize.width < 768
    },  
  },
  methods: {
    getAdsObj() {
      switch (this.name) {
        case 'singleImageViewInner':
          if(this.$route.name == 'RaceHome' || this.$route.name == 'ScrollView') {
            return this.templates[this.name].race[Math.floor(Math.random() * this.templates[this.name].race.length)]
          } else if(this.$route.name == 'RunnerHome') {
            return this.templates[this.name].runner[Math.floor(Math.random() * this.templates[this.name].runner.length)]
          }
          break;
        case 'runnerHomeInner':
        case 'raceHomeGalleryInner':
          if (this.getIsMobile) {
            return this.templates[this.name].m[Math.floor(Math.random() * this.templates[this.name].m.length)]
          } else {
            return this.templates[this.name].d[Math.floor(Math.random() * this.templates[this.name].d.length)]
          }
          break;
        default:
          if(this.templates[this.name]) {
            if (this.getIsMobile) {
              return this.templates[this.name].m
            } else {
              return this.templates[this.name].d
            }
          }
          break;
      }
    }
  },
  watch: {
    getWindowSize(val, old) {
      this.currentAds = null;
      this.$nextTick(()=> this.currentAds = this.getAdsObj())
    }
  },
  mounted() {
    if(this.limitAds) {
      this.adsCount = parseInt(`${this.getSingleImageAdsCount}`) + 1;
      this.$store.dispatch('setSingleImageAdsCount', this.adsCount)
      if(this.adsCount <= this.maxInnerAds) {
        this.currentAds = this.getAdsObj();
        this.show = true;
      }
    } else {
      this.currentAds = this.getAdsObj();
      this.show = true;
    }
    if(!this.showAdsense) {
      this.$store.dispatch('fireTracking', {
        event_type: 'IMPG',
        ext_race_id: this.getFetchedEvent.id,
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.google-ads {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>