<template>
  <b-modal id="email-photo-modal" hide-footer hide-header @hide="onHidden">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="12" class="text-center mrg-b">
          <div class="title">
            <span class="icon mr-2">
              <i class="far fa-envelope"></i>
            </span>
            {{$t('core.email')}}
            <img
              @click="closeModal()"
              class="close-btn img-fluid"
              src="@/assets/images/single-image-view/close.png"
            />
          </div>
        </b-col>
        <b-col cols="12" class="text-center mrg-b pd-1" v-if="image">
          <img class="w-100 img-fluid" :src="attachment ? attachment.content : image.previewPath" />
        </b-col>
        <b-col cols="12" class="text-left mrg-b pd-1">
          <b-form-input
            class="custom-input mb-2"
            v-model="email"
            type="text"
            trim
            :placeholder="$t('core.email')"
          ></b-form-input>
          <b-form-input
            class="custom-input mb-2"
            v-model="subject"
            type="text"
            trim
            :placeholder="$t('core.subject')"
          ></b-form-input>
          <b-form-textarea class="custom-input mb-2" v-model="message" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col cols="12" class="text-center mrg-b pd-2">
          <button class="submit-btn w-100" @click="submitEmail()">{{$t('core.send')}}</button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Eventbus from "../event-bus";
import is from "is-js";
import { sendImageEmail, sendEmail } from "../api/index";
import formatter from "../utils/formatter";
import { mapGetters } from "vuex";

export default {
  name: "email-photo-modal",
  components: {},
  computed: {
    ...mapGetters([
      "getFetchedEvent",
      "getCurrentNearRunner",
      "getSelectedRunner"
    ])
  },
  data() {
    return {
      formatter,
      image: null,
      email: "",
      subject: "",
      message: "",
      hint: "",
      error: true,
      attachment: null
    };
  },
  methods: {
    async submitEmail() {
      if (this.checkInput()) {
        Eventbus.$emit("change-loader-status", true);
        this.hint = "";
        let resp = null;
        let link = this.getLink();
        if (this.image) {
          resp = await sendImageEmail(
            this.getFetchedEvent.id,
            this.image,
            this.email,
            this.subject,
            this.message,
            link,
            this.getFetchedEvent.name,
            this.attachment ? 
            {
              content: this.attachment.content.split(',')[1],
              filename: this.attachment.filename,
              type: this.attachment.type
            } : this.attachment
          );
          this.$store.dispatch("fireTracking", {
            event_type: "EML",
            ext_race_id: this.getFetchedEvent.id,
            image_id: this.image.image_id
          });
        } else {
          let type = "race";
          if (this.$route.name == "RunnerHome") {
            type = "runner";
          }
          resp = await sendEmail(
            type,
            this.getFetchedEvent.id,
            this.email,
            this.subject,
            this.message,
            link,
            this.getFetchedEvent.name
          );
          this.$store.dispatch("fireTracking", {
            event_type: "EML",
            ext_race_id: this.getFetchedEvent.id
          });
        }
        if (resp && resp.data && resp.data.message == "Email Sent") {
          this.error = false;
          this.hint = this.$t("photoEmail.success_hint");
          Eventbus.$emit("show-toast", this.hint);
          setTimeout(() => {
            this.$bvModal.hide("email-photo-modal");
          }, 3000);
        } else {
          this.error = true;
          this.hint = this.$t("photoEmail.fail_hint");
          Eventbus.$emit("show-toast", this.hint);
        }
        Eventbus.$emit("change-loader-status", false);
      }
    },
    closeModal() {
      this.$bvModal.hide("email-photo-modal");
    },
    onHidden(e) {
      Eventbus.$emit("close-share-modal");
    },
    getLink() {
      let link = `${window.location.href}`;
      if (this.$route.name === "ScrollView") {
        link = link.replace("/scroll/", "/events/");
      }
      if (this.getCurrentNearRunner && this.getSelectedRunner) {
        link = this.formatter.formatNearRunnerUrl(
          this.getSelectedRunner,
          this.getCurrentNearRunner,
          link
        );
      }
      return link;
    },
    checkInput() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email.trim() == "" || this.subject.trim() == "") {
        Eventbus.$emit("show-toast", this.$t("photoEmail.check_all_input"));
        return false;
      }
      if (!re.test(String(this.email.trim()).toLowerCase())) {
        Eventbus.$emit("show-toast", this.$t("photoEmail.check_email"));
        return false;
      }
      return true;
    }
  },
  mounted() {
    Eventbus.$on("init-email-photo-modal", image => {
      this.image = image;
      this.attachment = null;
      let link = this.getLink();
      if (this.getFetchedEvent) {
        this.message = this.$t("photoEmail.message", {
          name: this.getFetchedEvent.name,
          link: link
        });
      }

      this.$bvModal.show("email-photo-modal");
    });

    Eventbus.$on("init-email-photo-modal-base64", (image, attachment) => {
      this.attachment = attachment;
      this.image = image;
      let link = this.getLink();
      if (this.getFetchedEvent) {
        this.message = this.$t("photoEmail.message", {
          name: this.getFetchedEvent.name,
          link: link
        });
      }

      this.$bvModal.show("email-photo-modal");
    });
  },
  beforeDestroy() {
    Eventbus.$off("init-email-photo-modal");
    Eventbus.$off("init-email-photo-modal-base64");
  }
};
</script>

<style lang="scss" scoped>
#email-photo-modal {
  .title {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    color: #6f7372;
    padding-left: 10px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .mrg-b {
    margin-bottom: 20px;
  }
  .pd-1 {
    padding: 0 10px;
  }
  .pd-2 {
    padding: 0 30px;
  }
  .hint {
    font-size: 14px;
    &.error {
      color: red;
    }
  }
  input,
  textarea {
    border-radius: 0;
  }
  .submit-btn {
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;
    border: none;
    font-size: 18px;
  }

  span.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6f7372;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    i {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>