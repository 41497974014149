<template>
  <div class="infinite-grid-wrapper">
    <div class="infinite-grid" :class="customCssClass" :id="name"></div>
    <google-ads
      ref="google-ads"
      customClass="d-none"
      :name="adsType"
      :hidden="true"
    />
    <div v-if="appending" class="text-center loading my-3">
      <span>
        <img src="@/assets/images/loading.gif" />
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Eventbus from "../event-bus";
import $ from "jquery";
import InfiniteGrid, { JustifiedLayout, GridLayout } from "@egjs/infinitegrid";
import SearchBox from "../components/SearchBox";
import SearchIcon from "@/assets/images/header/SearchLabel.png";

export default {
  name: "infinite-grid-scroll-view",
  props: {
    customCssClass: {
      type: [String, Array]
    },
    itemSelector: {
      type: String
    },
    name: {
      type: String
    },
    pageInfo: {
      type: Object
    },
    adsType: {
      type: String,
      default: "singleImageViewInner"
    }
  },
  components: {
    SearchBox
  },
  data() {
    return {
      ig: null,
      appending: true,
      scrollingUp: false,
      lastPos: 0,
      adsPerImage: 8,
      images: {},
      appendPage: 1,
      SearchIcon,
      searchText: "",
      currentId: "",
      runnerResult: [],
      initialRun: false
    };
  },
  computed: {
    ...mapGetters([
      "getWindowSize",
      "getFetchedEvent",
      "getLegacy",
      "getShowAdsense",
      "getPhotosSocialSharing"
    ]),
    normalSearch() {
      let check = false;
      if (
        !this.getLegacy &&
        this.getFetchedEvent &&
        this.getFetchedEvent.photos_available == "1"
      ) {
        check = true;
      }
      return check;
    },
    IsPurchaseAvailable() {
      const fetchedEvent = this.getFetchedEvent;
      let hasUploadCheckPoint = false;
      this.getOverlays.forEach((value) => {
        if (
          value.checkpoint.checkpoint_id === this.image.check_point_id &&
          (value.checkpoint.checkpoint_label === "User Uploads" ||
            value.checkpoint.checkpoint_label === "Uploads")
        ) {
          hasUploadCheckPoint = true;
        }
      });

      return (
        fetchedEvent &&
        fetchedEvent.photos_purchase == "1" &&
        this.image.check_point_id !== 3 &&
        this.image.check_point_id !== "3" &&
        hasUploadCheckPoint == false
      )
    },
  },
  methods: {
    appendItem(itemArr, init = false) {
      if (this.ig) {
        if (init) {
          this.initialRun = init;
        }
        if (this.appendPage <= 5 && this.getShowAdsense) {
          this.ig.append(this.appendAdsHtml(this.generateImageHtml(itemArr)));
          this.appendPage++;
        } else {
          this.ig.append(this.generateImageHtml(itemArr));
        }
      }
    },
    appendAdsHtml(htmlArr) {
      let html = [];
      if (!this.$refs["google-ads"]) return html;
      htmlArr.forEach((item, index) => {
        html.push(item);
        if (index % this.adsPerImage == this.adsPerImage - 1) {
          let ads = this.$refs["google-ads"].getAdsObj();
          html.push(this.generateAdsHtml(ads));
        }
      });
      this.activateAds();
      return html;
    },
    generateImageHtml(itemArr) {
      let results = [];
      itemArr.forEach(image => {
        if (image.image_id) {
          this.images[image.image_id] = image;
          if (image.previewPath) {
            results.push(this.generateItemHtml(image));
          }
        }
      });
      return results;
    },
    generateItemHtml(image) {
      let html = `<div class="single-image-view-item inf-grid-item py-3 ${
        image.width > image.height ? "landscape" : "portrait"
      }" data-page="${image.page}" data-id="${image.image_id}" data-bib="${
        image.bib ? image.bib : null
      }">
            <div class="container p-0">
              <div class="header" v-if="!changing">
                <div class="mega-dropdown">
                  <a
                    class="dropdown-toggle"
                    id="image-dropdown-${image.image_id}"
                    data-id="${image.image_id}"
                  >
                    <i class="icon fas fa-ellipsis-h"></i>
                  </a>
                </div>
              </div>
              <div class="img">
                <img
                  class="w-100 img-fluid"
                  src="${image.previewPath}"
                  style="pointer-events:none;"
                  style="${this.getImgWidthStyle(image)}"
                />
              </div>
              <div class="footer">
                <div class="row no-gutters">
                  <div class="col-3 text-left">
                    [favorite]
                  </div>
                  <div class="col-9 text-right">
                    `;
        html += `<span class="icon ml-2 email-share-image" data-id="${image.image_id}">
                      <i class="fas fa-share-alt"></i>
                    </span>
                    <span class="icon ml-2 download-image" data-id="${image.image_id}">
                      <i class="fas fa-arrow-down"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
      html = html.replace("[favorite]", this.generateFavoriteHtml(image));
      return html;
    },
    generateFavoriteHtml(image) {
      let fa = this.checkFavorite(image);
      if (fa) {
        return `<span class="icon unlike-image" data-id="${image.image_id}">
              <i class="fas fa-heart"></i>
            </span>
            <span class="icon like-image d-none" data-id="${image.image_id}">
              <i class="far fa-heart"></i>
            </span>`;
      } else {
        return `<span class="icon unlike-image d-none" data-id="${image.image_id}">
              <i class="fas fa-heart"></i>
            </span>
            <span class="icon like-image" data-id="${image.image_id}">
              <i class="far fa-heart"></i>
            </span>`;
      }
    },
    generateAdsHtml(ads) {
      return `<div class="inf-grid-item ads w-100 text-center"><ins class="adsbygoogle ${ads.class}" data-ad-client="${ads.client}" data-ad-slot="${ads.slot}" style="${ads.style}"></ins></div>`;
    },
    activateAds() {
      setTimeout(() => {
        let loop =
          $("ins.adsbygoogle").length -
          $('ins.adsbygoogle[data-adsbygoogle-status="done"]').length;
        if (loop > 0) {
          this.$nextTick(() => {
            setTimeout(() => {
              for (let index = 0; index < loop; index++) {
                (adsbygoogle = window.adsbygoogle || []).push({});
              }
            }, 500);
          });
        }
      }, 1500);
    },
    clear() {
      this.images = {};
      this.appendPage = 1;
      if (this.ig) this.ig.clear();
    },
    destroy() {
      if (this.ig) this.ig.destroy();
    },
    checkFavorite(image) {
      if (!localStorage.savedImages) {
        return false;
      } else {
        let json = JSON.parse(localStorage.savedImages);
        if (json.indexOf(image.image_id) >= 0) {
          return true;
        }
        return false;
      }
    },
    tagBib(id) {
      let image = this.images[id];
      if (image) this.$emit("tagBib", image);
    },
    savePhoto(image, save) {
      if (save) {
        this.$store.dispatch("fireTracking", {
          event_type: "LIKE",
          ext_race_id: this.getFetchedEvent.id,
          image_id: image.image_id
        });
        if (!localStorage.savedImages) {
          localStorage.savedImages = JSON.stringify([image.image_id]);
        } else {
          let json = JSON.parse(localStorage.savedImages);
          if (json.indexOf(image.image_id) < 0) {
            json.push(image.image_id);
            localStorage.savedImages = JSON.stringify(json);
          }
        }
        $(`.like-image[data-id="${image.image_id}"]`).addClass("d-none");
        $(`.unlike-image[data-id="${image.image_id}"]`).removeClass("d-none");
      } else {
        if (!localStorage.savedImages) {
          localStorage.savedImages = JSON.stringify([]);
        } else {
          let json = JSON.parse(localStorage.savedImages);
          let index = json.indexOf(image.image_id);
          if (index >= 0) {
            json.splice(index, 1);
            localStorage.savedImages = JSON.stringify(json);
          }
        }
        $(`.unlike-image[data-id="${image.image_id}"]`).addClass("d-none");
        $(`.like-image[data-id="${image.image_id}"]`).removeClass("d-none");
      }
    },
    getImgWidthStyle(image) {
      if (!isNaN(image.width) && !isNaN(image.height)) {
        let targetWidth = 0;
        if (this.getDirection) {
          //landscape
          targetWidth = Math.min(
            parseInt(((parseInt(this.getWindowSize.height) * 0.8) / 2) * 3),
            parseInt(parseInt(this.getWindowSize.width) * 0.8)
          );
        } else {
          targetWidth = Math.min(
            parseInt(((parseInt(this.getWindowSize.height) * 0.8) / 3) * 2),
            parseInt(parseInt(this.getWindowSize.width) * 0.8)
          );
        }
        return `"max-width": ${targetWidth}px`;
      } else {
        return "";
      }
    },
    setLoading(load) {
      if (load) {
        this.setPosition(this.currentId);
        setTimeout(() => {
          $(`#search-box-${this.currentId} .type-loader`).removeClass("v-none");
        }, 500);
      } else {
        $(`#search-box-${this.currentId} .type-loader`).addClass("v-none");
        $(`#search-box-${this.currentId} .type-loader`).css("left", `75px`);
      }
    },
    setPosition(id) {
      setTimeout(() => {
        let left = Math.min(250, $(`#type-loader-text-width`).width() + 75);
        $(`#search-box-${id} .type-loader`).css("left", `${left}px`);
      }, 100);
    },
    handleScroll() {
      if (this.currentId != "") {
        this.searchText = "";
        this.currentId == "";
      }
    }
  },
  mounted() {
    var vm = this;
    this.ig = new InfiniteGrid(`#${this.name}`);
    var layout = new GridLayout({
      margin: 10,
      align: "center"
    });
    this.ig.setLayout(layout);
    this.ig.on({
      append: function(e) {
        if (
          !vm.scrollingUp &&
          !vm.appending &&
          vm.pageInfo &&
          !vm.pageInfo.isLastPage
        ) {
          vm.appending = true;
          vm.$emit("onAppendItem");
        }
      },
      layoutComplete: function(e) {
        vm.appending = false;
        if (vm.appendPage < 5) vm.activateAds();
        if (vm.initialRun) {
          vm.$emit("scrollToTop");
          $("html, body").scrollTop(0);
          Eventbus.$emit("change-loader-status", false);
          setTimeout(() => {
            $("html, body").animate({ scrollTop: 0 }, 500);
          }, 500);
          vm.initialRun = false;
        }
      }
    });

    // Start dropdown trigger
    $(`#${this.name}`).on("click", ".mega-dropdown .dropdown-toggle", function(
      e
    ) {
      let id = $(this).data("id");
      let image = vm.images[id];
      if (image) Eventbus.$emit("init-bib-modal", image, false);
    });
    // End dropdown button trigger

    // Start share button trigger
    $(`#${this.name}`).on("click", ".fb-share-image", function() {
      let id = $(this).data("id");
      let image = vm.images[id];
      if (image) vm.$emit("facebookShare", image);
    });
    $(`#${this.name}`).on("click", ".email-share-image", function(e) {
      console.log(e)
      let id = $(this).data("id");
      let image = vm.images[id];
      if (image) vm.$emit("emailShare", image);
    });
    $(`#${this.name}`).on("click", ".download-image", function() {
      let id = $(this).data("id");
      let image = vm.images[id];
      if (image)  {
        if (vm.getFetchedEvent.photos_purchase == "1") {
          vm.$emit("download", image, false);
        } else {
          Eventbus.$emit("direct-download", image, false);
        }
      } // vm.$emit("download", image, false);
    });
    // End share button trigger

    // Start favorite button trigger
    $(`#${this.name}`).on("click", ".unlike-image", function() {
      let id = $(this).data("id");
      let image = vm.images[id];
      if (image) vm.savePhoto(image, false);
    });
    $(`#${this.name}`).on("click", ".like-image", function() {
      let id = $(this).data("id");
      let image = vm.images[id];
      if (image) vm.savePhoto(image, true);
    });
    // End favorite button trigger
  }
};
</script>

<style lang="scss">
.single-image-view-item.inf-grid-item {
  width: 100%;
  border-bottom: 1px solid #d5d5d5;

  & > .container {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .icon {
    cursor: pointer;
  }

  span.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6f7372;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    i {
      color: #fff;
      font-size: 24px;

      &.fas.fa-heart {
        color: red;
      }
    }
  }

  .footer {
    padding: 1rem 0;
    height: 60px;
    // margin-bottom: 80px;
  }
  .fas {
    color: #6f7372;
    font-size: 28px;
  }

  &.landscape {
    .footer {
      // margin-bottom: 200px;
    }
  }

  .dropdown-menu.show {
    min-width: 300px;
  }
  .dropdown-item,
  .image-tag {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }
}

.infinite-grid-wrapper {
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px !important;
      height: auto;
    }
  }
  .dropdown {
    & > button {
      padding: 0;
      background-color: transparent;
      border: none;
      &::after {
        display: none;
      }
    }
  }
}

.mega-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.type-loader-text {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
}
</style>
