<template>
  <b-modal id="untag-bib-modal" hide-footer hide-header>
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="12" class="text-right mb-3">
          <img
            @click="closeModal()"
            class="img-fluid close-btn"
            src="@/assets/images/single-image-view/close.png"
          />
        </b-col>
        <b-col cols="12" class="text-center mb-3 mb-md-5">
          <div class="title">{{$t('untagBib.title')}}</div>
        </b-col>
        <b-col cols="12" class="position-relative text-left mb-3 mb-md-5 px-2 px-md-4">
          <b-form-input
            ref="untag-bib-input"
            class="search-input pr-5"
            v-model="bib"
            trim
            placeholder="Enter Bib"
          ></b-form-input>
          <div v-if="disabledSubmit" class="load text-center mt-2">
            <img src="@/assets/images/loading.gif" />
          </div>
        </b-col>
        <b-col cols="12" class="text-center mb-2">
          <button
            class="submit-btn"
            :disabled="disabledSubmit"
            @click="submitUntagBib()"
          >{{$t('core.submit')}}</button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Eventbus from "../event-bus";
import is from "is-js";
import { untagBib } from "../api/index";
import { mapGetters } from "vuex";

export default {
  name: "untag-bib-modal",
  components: {},
  computed: {
    ...mapGetters(["getFetchedEvent"])
  },
  data() {
    return {
      image: null,
      bib: "",
      error: false,
      hint: "",
      disabledSubmit: false
    };
  },
  methods: {
    async submitUntagBib() {
      if (!this.disabledSubmit) {
        this.disabledSubmit = true;
        this.hint = "";
        if (
          is.number(parseInt(this.bib)) &&
          this.bib != "" &&
          this.getFetchedEvent &&
          this.image
        ) {
          const resp = await untagBib(
            this.getFetchedEvent.id,
            this.bib,
            this.image.image_key
          );
          if (resp.data && resp.data.status) {
            let status = resp.data.status.type;
            if (status == "success") {
              this.error = false;
              this.hint = this.$t("untagBib.success_hint");
              Eventbus.$emit("show-toast", this.hint);
              this.$bvModal.hide("untag-bib-modal");
            } else {
              this.error = true;
              this.hint = this.$t("untagBib.fail_hint");
              Eventbus.$emit("show-toast", this.hint);
            }
          }
        } else {
          this.error = true;
          this.hint = this.$t("untagBib.invalid_hint");
          Eventbus.$emit("show-toast", this.hint);
        }
        this.disabledSubmit = false;
      }
    },
    closeModal() {
      this.disabledSubmit = false;
      this.image = null;
      this.bib = "";
      this.error = false;
      this.hint = "";
      this.$bvModal.hide("untag-bib-modal");
    }
  },
  mounted() {
    Eventbus.$on("init-untag-bib-modal", image => {
      this.disabledSubmit = false;
      this.image = image;
      this.bib = image.bib ? image.bib : "";
      this.error = false;
      this.hint = "";
      this.$bvModal.show("untag-bib-modal");
    });
  },
  beforeDestroy() {
    Eventbus.$off("init-untag-bib-modal");
  }
};
</script>

<style lang="scss" scoped>
#untag-bib-modal {
  .title {
    font-weight: 900;
    font-size: 20px;
    color: var(--primary-color);
    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
  .hint {
    font-size: 14px;
    &.error {
      color: red;
    }
  }
  .load {
    position: absolute;
    bottom: 0.5rem;
    right: 2rem;
    img {
      width: 20px;
      height: auto;
    }
  }
  .search-input {
    border: 1px solid var(--primary-color);
  }
  .submit-btn {
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;
    border: none;
    font-size: 18px;

    &:disabled {
      cursor: not-allowed;
      background-color: var(--disabled-color);
    }
  }
  .close-btn {
    cursor: pointer;
  }
}
</style>
