export const BASE_API_URL = 'https://q5nfj5ju35.execute-api.us-west-2.amazonaws.com'
export const STATS_API_URL = 'https://stats.grepcv.com'
export const GET_EVENT = '/rpe/bibs/get-event'
// export const SEARCH_RUNNER = '/rpe/runner/autocomplete';
export const SEARCH_RUNNER = '/rpe/runner/autocomplete'
export const SEARCH_FACE = '/rpe/bibs/search-mirofaces'
export const GET_IMAGE = '/rpe/bibs/search-multi'
export const GET_LF_IMAGE = '/rpe/bibs/searchlf'
export const GET_RUNNER_IMAGE = '/rpe/bibs/search-multi-faces'
export const NEAR_RUNNER = '/rpe/bibs/near-runner'
export const GET_CHECKPOINT = '/rpe/bibs/get-checkpoints'
export const TAG_BIB = '/rpe/bibs/tag-bib'
export const UNTAG_BIB = '/rpe/bibs/untag-bib'
export const IMAGE_EMAIL = '/rpe/sendmail/image'
export const SEND_EMAIL = '/rpe/sendmail/race'
export const SEND_RUNNER_EMAIL = '/rpe/sendmail/runner'
export const IMAGE_WITH_RESULT = '/rpe/bibs/get-finisher-image'
export const FIRE_TRACKING = '/rpe/bibs/tracking'
export const IMAGE_DETAIL = '/rpe/bibs/image-detail'
export const SUBMIT_SURVEY = '/rpe/bibs/post-survey-answers'
export const GET_ORDERS = '/rpe/orders'
export const DOWNLOAD_PHOTO = '/rpe/orders/download'
export const TAG_MULTI_IMAGES = '/rpe/bibs/tag-multi-images'
export const UPDATE_FACEREK_KEY = '/rpe/bibs/update-facerek-key'

// different endpoint
export const UPLOAD_PHOTO = '/prod/upload'
export const UPLOAD_PHOTO_FOR_SHARE = '/prod/share'

//From Enmotive.com
export const ENMOTIVE_URL = 'https://enmotive.com'
export const GET_USER_ID = '/users/show_id'

//From Enmotive
export const ENMOTIVE_BASE_API_URL = 'https://api.enmotive.grepcv.com'
export const LEGACY_SEARCH_RUNNER = (eventSlug) => {
  return `/prod/events/${eventSlug}/participants`
}

//From Enmotive api
export const ENMOTIVE_API_URL = ENMOTIVE_BASE_API_URL
export const FETCH_EVENT = '/prod/events'

export const ENMOTIVE_NEW_API_URL = 'https://api.enmotive.com'
export const GET_EVENT_CATEGORIES_NEW = eventSlug => `/events/${eventSlug}/categories`

// export const GET_EVENT_BRACKETS = (eventSlug) => {
//   return `/prod/events/${eventSlug}/brackets`;
// }
export const GET_EVENT_LEADERBOARD = (eventSlug) => {
  return `/prod/events/${eventSlug}/leaderboards`
}
export const GET_EVENT_CATEGORIES = (eventSlug) => {
  return `/dev/events/${eventSlug}/categories`
}
export const GET_EUNNER = (eventSlug, bib) => {
  return `/prod/events/${eventSlug}/${bib}`
}
export const UPLOAD_PHOTO_API_URL = ENMOTIVE_BASE_API_URL


//From video api
export const VIDEO_JSON = process.env.NODE_ENV == 'development' ? 'https://grepcv-dev-1.s3.amazonaws.com/json/video/video-dev-new.json' : 'https://grepcv-dev-1.s3.amazonaws.com/json/video/video-new.json'
export const CREATE_VIDEO_URL = process.env.NODE_ENV == 'development' ? 'https://raceday-video.enmotive.com' : 'https://raceday-video.enmotive.com'
export const FETCH_LEGACY_VIDEO_API_URL = process.env.NODE_ENV == 'development' ? 'https://ie5q7m30rk.execute-api.us-west-2.amazonaws.com' : 'https://ie5q7m30rk.execute-api.us-west-2.amazonaws.com'
export const FETCH_LEGACY_VIDEO_API_URL_V2 = 'https://g6an230fab.execute-api.us-east-1.amazonaws.com'
export const GET_VIDEO = process.env.NODE_ENV == 'development' ? '/sd-dev//video/link' : '/dev/video/link'
export const GET_VIDEO_V2 = '/prod/api/video/link'

//api key
export const grepcvAPIKey = 'xTWbpK86uR9kIZpc3v2Hu2K7Ez9f59iB9FK4KjkA'
export const videoAPIKey = process.env.NODE_ENV == 'development' ? 'aG1UDzWuT01K1ki3jXYLfa7c3G6KY1AQ6qyhoYGC' : 'xTWbpK86uR9kIZpc3v2Hu2K7Ez9f59iB9FK4KjkA'

// New Video API
export const NEW_VIDEO_API_BASE_URL = 'https://vppwh0rnnc.execute-api.us-west-2.amazonaws.com'
export const CREATE_VIDEO_API_URL = '/dev/video'
export const GET_VIDEO_API_URL = (race_id, bib) => {
  return `/dev/video/race/${race_id}/bib/${bib}`
}
export const GET_VIDEO_STATUS_API_URL = (request_id) => {
  return `/dev/video/request/${request_id}`
}

// Team API
export const TEAM_API_URL = ENMOTIVE_BASE_API_URL
// export const TEAM_API_URL = 'https://3wdwoj7t91.execute-api.us-west-2.amazonaws.com';
export const GET_TEAM_LIST = (eventSlug) => {
  return `/prod/events/${eventSlug}/teams`
}
export const GET_TEAM_DETAIL = (eventSlug, teamId, limit = 100, offset = 0, category_id = 0) => {
  return `/prod/events/${eventSlug}/teams/${teamId}?${category_id != 0 ? "category_id=" + category_id + "&" : ""}limit=${limit}&offset=${offset}`
}
export const CHECK_VIDEO = (eventSlug) => {
  return `/prod/events/${eventSlug}/video`
}

export const GET_TEAM_MEMBER_LIST = (eventSlug, teamId) => {
  return `/dev/events/${eventSlug}/teams/${teamId}/registrants`
}

export const GET_TEAM_VIDEOS = (eventSlug, teamId, offset = 0, limit = 10) => {
  return `/dev/teams/video?ext_race_id=${eventSlug}&team_registration_id=${teamId}&offset=${offset}&limit=${limit}`
}

export const TEAM_VIDEO_BASE_URL = "https://img.grepcv.com"
