<template>
  <div id="api-error" :class="{'d-none': !loading}">
    <div>
      <img class="img-fluid" src="@/assets/images/loading.gif" />
      <div class="my-3" v-html="$t('apiError.desc_1', {'link': getATag})"></div>
      <div>
        {{$t('apiError.desc_2')}}
        <a :href="getMailTo">{{email}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Eventbus from "../event-bus";
import { mapGetters } from "vuex";

export default {
  name: "api-error",
  components: {},
  data() {
    return {
      loading: false,
      email: process.env.ERROR_EMAIL
    };
  },
  computed: {
    ...mapGetters(["getFetchedEvent"]),
    getATag() {
      return `<a href="#" onclick="window.location.reload()">${this.$t("apiError.refresh")}</a>`;
    },
    getMailTo() {
      return this.getFetchedEvent
        ? `mailto:${this.email}?subject=${this.getFetchedEvent.name}: ${window.location.href}`
        : "";
    }
  },
  methods: {},
  mounted() {
    Eventbus.$on("show-api-error", () => {
      Eventbus.$emit("hard-lock", true);
      this.loading = true;
    });
  },
  beforeDestroy() {
    Eventbus.$off("show-api-error");
  }
};
</script>

<style lang="scss">
#api-error {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background-color: #ffffffee;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 5%;
  @media (min-width: 768px) {
    padding: 0 20%;
  }

  & > div {
    width: 100%;
  }

  img {
    height: auto;
  }

  a {
    cursor: pointer;
    color: var(--primary-color) !important;
  }
}
</style>
