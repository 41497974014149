<template>
  <div class="face-search">
    <Gallery style="height: 0px; visibility: hidden;" ref="fine-uploader" :uploader="uploader" />
    <button
      :id="parentId + '-face-search-btn'"
      class="face-search-wrapper"
      @click="faceSearch()"
      :disabled="getLegacy"
    >
      <i class="fas fa-camera mr-2"></i>
      <span>{{$t('faceSearch.btn_text')}}</span>
    </button>
    <b-tooltip
      :target="parentId + '-face-search-btn'"
      customClass="face-search-tooltip"
      placement="bottom"
    >
      <img src="@/assets/images/race-home/face-detection.png" />
      <div class="desc">{{$t('faceSearch.tooltip_text')}}</div>
    </b-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Eventbus from "../event-bus";
import formatter from "../utils/formatter";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "vue-fineuploader/gallery";
import Thumbnail from "vue-fineuploader/thumbnail";

import { BASE_API_URL, SEARCH_FACE } from "../api/urls";

export default {
  name: "face-search",
  components: {
    Gallery,
    Thumbnail
  },
  props: {
    raceId: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      required: true
    }
  },
  data() {
    const uploader = new FineUploaderTraditional({
      options: {
        request: {
          endpoint: BASE_API_URL + SEARCH_FACE,
          forceMultipart: false,
          params: {
            ext_race_id: this.raceId
          },
          paramsInBody: false
        },
        multiple: false,
        cors: {
          expected: true
        },
        validation: {
          acceptFiles: ".jpg, .jpeg, .png",
          allowedExtensions: ["jpg", "jpeg", "png"]
        },
        callbacks: {
          onSubmit: this.fineUploaderOnSubmit,
          onComplete: this.fineUploaderOnComplete,
          onError: this.fineUploaderOnError
        }
      }
    });
    return {
      formatter,
      uploader,
      fileId: 0,
      file: null
    };
  },
  computed: {
    ...mapGetters(["getFetchedEvent", "getLegacy"])
  },
  methods: {
    faceSearch() {
      $(
        "#" +
          this.parentId +
          ' .vue-fine-uploader-file-input input[type="file"]'
      ).val("");
      $(
        "#" + this.parentId + " .vue-fine-uploader-gallery-file-input-content"
      ).click();
    },
    fineUploaderOnSubmit(id, name) {
      this.fileId = id;
      Eventbus.$emit("change-loader-status", true);
    },
    fineUploaderOnComplete(id, name, obj, xhr) {
      this.toBase64(this.uploader.methods.getFile(this.fileId)).then(base64 => {
        this.file = base64;
        this.uploader.methods.deleteFile(this.fileId);
        let images = [];
        if (obj.total_images) {
          images = this.formatter.addPreviewImagePath(obj.mirofaces);
        }
        if (images.length > 100) {
          images = images.slice(0, 100);
        }
        this.$store.dispatch("setFaceSearchResult", {
          current: this.file,
          images: images
        });
        if (
          this.$route.name == "RaceHome" &&
          this.$route.params.tab != "gallery"
        ) {
          this.$router.push({
            path: `/events/${this.getFetchedEvent.id}/gallery`
          });
          $("html, body").animate({ scrollTop: 0 }, 500);
          this.$store.dispatch("fireTracking", {
            event_type: "SBS",
            ext_race_id: this.getFetchedEvent.id
          });
          setTimeout(() => {
            Eventbus.$emit("change-loader-status", false);
          }, 5000);
        } else if (this.$route.name == "RunnerHome") {
          Eventbus.$emit("change-loader-status", false);
          this.$emit("faceSearched", images);
        }
      });
    },
    fineUploaderOnError(id, name, errorReason, xhrOrXdr) {
      Eventbus.$emit("change-loader-status", false);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.face-search {
  .face-search-wrapper {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 0.25rem 1rem;
    border: none;
    border-radius: 0.25rem;
    transition: color 0.6s ease;

    &:hover,
    &:active {
      background-color: var(--dark-color);

      &:disabled {
        background-color: var(--disabled-color);
      }
    }
  }
}
</style>
