<template>
	<div id="app-header" class="sticky-top">
		<b-navbar toggleable="lg" type="dark">
			<b-navbar-brand @click="backHome()" class="mr-auto">
        <div v-if="!showNavEventTitle">
          <div class="enmotive-logo"></div>
        </div>
        <div v-else>
          <event-title
            v-if="getFetchedEvent"
            :name="getFetchedEvent.name"
            :location="getFetchedEvent.location.name"
            :dateStart="getFetchedEvent.date.start"
            :dateEnd="getFetchedEvent.date.end"
          />
        </div>
      </b-navbar-brand>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav v-if="this.$route.name != 'Share' && this.$route.name != 'Error'" class="d-flex flex-row" style="flex:1;">
					<search-box
						ref="header-search-box"
						v-if="getFetchedEvent && showSearchBox"
						@selectRunner="selectRunner"
						name="header"
						:raceId="getFetchedEvent.id"
						:searchForTeam="getEventTeamAvailable"
					/>
					<button
						v-if="
							getFetchedEvent &&
							getFetchedEvent.results_searchable == '0' &&
							getFetchedEvent.photos_faces == '1' &&
							getFetchedEvent.photos_available == '1'
						"
						class="btn btn-light btn-sm"
						style="color: var(--primary-color)"
						@click="faceSearch()"
					>
						<span id="app-header-face-search-btn">
							<i class="fas fa-camera"></i>
						</span>
						<face-search
							class="d-none"
							ref="app-header-face-search"
							:raceId="getFetchedEvent.id"
							:parentId="'app-header'"
						/>
					</button>
				</b-navbar-nav>

				<b-navbar-nav class="d-none d-lg-flex justify-content-end align-items-center" style="flex:2;">
					<b-nav-item-dropdown :text="$t('header.group.event')" right>
						<b-dropdown-item v-if="showResultPage" @click="redirectPage('results')">{{ $t('header.results') }}</b-dropdown-item>
						<b-dropdown-item v-if="!showResultPage" @click="redirectPage('info')">{{ $t('raceHome.info') }}</b-dropdown-item>
						<b-dropdown-item v-if="checkShowGallery" @click="redirectPage('gallery')">{{ $t('header.gallery') }}</b-dropdown-item>
						<b-dropdown-item v-if="getShowLostAndFound" @click="redirectPage('lost&found')">{{ $t('header.lost_n_found') }}</b-dropdown-item>
						<b-dropdown-item to="/purchased-photos">{{ $t('header.downloadcenter') }}</b-dropdown-item>
					</b-nav-item-dropdown>
					<b-nav-item href="https://hub.enmotive.com" target="_blank" class="font-weight-bold">{{ $t('header.home') }}</b-nav-item>
					<b-nav-item-dropdown html='<i class="fas fa-share-alt"></i>' right>
						<div class="px-4 font-weight-bold">{{ $t('sharepage.label') }}</div>
						<b-dropdown-item @click="shareOption('facebook')"><i class="fab fa-facebook-square mr-2 text-primary"></i> {{ $t('sharepage.facebook') }} </b-dropdown-item>
						<b-dropdown-item @click="shareOption('email')"><i class="fas fa-envelope mr-2 text-danger"></i> {{ $t('sharepage.email') }}</b-dropdown-item>
						<b-dropdown-item 
							v-clipboard:copy="getCopyMessage"
							v-clipboard:success="onCopy"
							v-clipboard:error="onCopyError">
							<i class="fas fa-link mr-2 text-info"></i> {{ $t('sharepage.link') }}
						</b-dropdown-item>
					</b-nav-item-dropdown>
					<b-nav-item v-if="!authenticated" @click="redirectPage('login')" link-classes="btn btn-light btn-sm text-muted"><i class="fa fa-sign-in-alt mr-2"></i> {{ $t('header.login') }}</b-nav-item>
					<template v-else>
						<b-nav-item href="https://enmotive.com/users/account" target="_blank">{{ $t('header.my-account') }}</b-nav-item>
						<b-nav-item @click="redirectPage('logout')" link-classes="btn btn-light btn-sm text-muted"><i class="fa fa-sign-in-alt mr-2"></i> {{ $t('header.logout') }}</b-nav-item>
					</template>
				</b-navbar-nav>

				<b-navbar-nav class="d-lg-none mt-3">
					<b-nav-item v-if="!authenticated" @click="redirectPage('login')" link-classes="btn btn-light btn-sm text-muted"><i class="fa fa-sign-in-alt mr-2"></i> {{ $t('header.login') }}</b-nav-item>
					<template v-else>
						<b-nav-item href="https://enmotive.com/users/account" target="_blank">{{ $t('header.my-account') }}</b-nav-item>
						<b-nav-item @click="redirectPage('logout')" link-classes="btn btn-light btn-sm text-muted"><i class="fa fa-sign-in-alt mr-2"></i> {{ $t('header.logout') }}</b-nav-item>
					</template>

					<b-nav-item v-if="showResultPage" @click="redirectPage('results')">{{ $t('header.results') }}</b-nav-item>
					<b-nav-item v-if="!showResultPage" @click="redirectPage('info')">{{ $t('raceHome.info') }}</b-nav-item>
					<b-nav-item v-if="checkShowGallery" @click="redirectPage('gallery')">{{ $t('header.gallery') }}</b-nav-item>
					<b-nav-item v-if="getShowLostAndFound" @click="redirectPage('lost&found')">{{ $t('header.lost_n_found') }}</b-nav-item>
					<b-nav-item to="/purchased-photos">{{ $t('header.downloadcenter') }}</b-nav-item>
					<div class="dropdown-divider"></div>

					<b-nav-item href="https://hub.enmotive.com" target="_blank" class="font-weight-bold">{{ $t('header.home') }}</b-nav-item>
					<div class="dropdown-divider"></div>

					<div class="font-weight-bold text-white">{{ $t('sharepage.label') }}</div>
					<b-nav-item @click="shareOption('facebook')"><i class="fab fa-facebook-square mr-2"></i> {{ $t('sharepage.facebook') }} </b-nav-item>
					<b-nav-item @click="shareOption('email')"><i class="fas fa-envelope mr-2"></i> {{ $t('sharepage.email') }}</b-nav-item>
					<b-nav-item 
						v-clipboard:copy="getCopyMessage"
						v-clipboard:success="onCopy"
						v-clipboard:error="onCopyError">
						<i class="fas fa-link mr-2"></i> {{ $t('sharepage.link') }}
					</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import EventTitle from "./EventTitle";
import Eventbus from "../event-bus";
import formatter from "../utils/formatter";
import SearchBox from "../components/SearchBox";
import FaceSearch from "../components/FaceSearch";
import { getTeamList } from "../api/index";

export default {
  name: "app-header",
  components: {
    EventTitle,
    SearchBox,
    FaceSearch,
  },
  data() {
    return {
      formatter,
      showingSearch: false,
      showNavEventTitle: false
    };
  },
  computed: {
    ...mapGetters([
      "getWindowSize",
      "getFetchedEvent",
      "getSelectedRunner",
      "getSelectedTeam",
      "getCurrentNearRunner",
      "getSelectedRunnerOrTeam",
      "getNoRaceImage",
      "getColor",
      "getShowLostAndFound",
      "getEnmotiveUserId",
      "getEventTeamAvailable",
    ]),
    authenticated() {
      return !!this.getEnmotiveUserId;
    },
    getCopyMessage() {
      let shareLink = window.location.href;
      if (this.getSelectedRunner && this.getCurrentNearRunner) {
        shareLink = this.formatter.formatNearRunnerUrl(
          this.getSelectedRunner,
          this.getCurrentNearRunner,
          shareLink
        );
      }
      if (this.$route.name === "ScrollView") {
        shareLink = shareLink.replace("/scroll/", "/events/");
      }
      return shareLink;
    },
    showResultPage() {
      if (this.getFetchedEvent) {
        if (
          this.getFetchedEvent.result_searchable &&
          this.getFetchedEvent.result_posted
        ) {
          return (
            this.getFetchedEvent &&
            this.getFetchedEvent.result_posted == "1" &&
            (this.getFetchedEvent.result_searchable == "1" ||
              this.getFetchedEvent.result_searchable == "2" ||
              this.getFetchedEvent.result_searchable == "3")
          );
        }
        return (
          this.getFetchedEvent &&
          this.getFetchedEvent.results_posted == "1" &&
          (this.getFetchedEvent.results_searchable == "1" ||
            this.getFetchedEvent.results_searchable == "2" ||
            this.getFetchedEvent.results_searchable == "3")
        );
      }
      return false;
    },
    showSearchBox() {
      if (
        this.$route.name === "PurchasedPhotos" ||
        this.$route.name === "PurchasedPhotosWithEmail" ||
        this.$route.name === "PurchasedPhotosWithOrder"
      ) {
        return false;
      }
      if (this.getFetchedEvent) {
        if (
          this.getFetchedEvent.result_searchable &&
          this.getFetchedEvent.result_posted
        ) {
          return (
            (this.getFetchedEvent.result_posted == "1" ||
              this.getFetchedEvent.photos_available == "1") &&
            (this.getFetchedEvent.result_searchable == "1" ||
              this.getFetchedEvent.result_searchable == "2" ||
              this.getFetchedEvent.result_searchable == "3")
          );
        }
        return (
          (this.getFetchedEvent.results_posted == "1" ||
            this.getFetchedEvent.photos_available == "1") &&
          (this.getFetchedEvent.results_searchable == "1" ||
            this.getFetchedEvent.results_searchable == "2" ||
            this.getFetchedEvent.results_searchable == "3")
        );
      }
      return false;
    },
    checkShowGallery() {
      return (
        !this.getNoRaceImage &&
        this.getFetchedEvent &&
        this.getFetchedEvent.photos_available == "1"
      );
    },
  },
  methods: {
    shareOption(opt) {
      switch (opt) {
        case "email":
          Eventbus.$emit("init-email-photo-modal", null);
          break;
        case "facebook":
          if (this.$route.query && this.$route.query.image_id) {
            if (this.$route.name === "RunnerHome") {
              if (this.$route.query.view === 1) {
                Eventbus.$emit(
                  "single-view-share-facebook",
                  this.$route.query.image_id
                );
              } else {
                Eventbus.$emit(
                  "runner-share-facebook",
                  this.$route.query.image_id
                );
              }
            } else if (this.$route.name === "ScrollView") {
              Eventbus.$emit(
                "scrollview-share-facebook",
                this.$route.query.image_id
              );
            } else {
              Eventbus.$emit("share-facebook", { type: "page" });
            }
          } else {
            Eventbus.$emit("share-facebook", { type: "page" });
          }
          break;
        default:
          break;
      }
    },
    redirectPage(opt) {
      const enmotive_url = 'https://enmotive.com/users/';
      let current_url = encodeURIComponent(window.location.href.split("?")[0]);
      switch (opt) {
        case "login":
          window.open(enmotive_url + "login_redirect?url=" + current_url, "_self");
          break;
        case "logout":
          window.open(enmotive_url + "logout_redirect?url=" + current_url, "_self");
          break;
        case "results":
          this.$router.push({path: `/events/${this.getFetchedEvent.slug}/results`, query: { content: 1 }});
          break;
        case "gallery":
          this.$router.push({path: `/events/${this.getFetchedEvent.slug}/gallery`, query: { content: 1 }});
          break;
        case "info":
          if (this.$route.params.tab !== "info") {
            this.$router.push({path: `/events/${this.getFetchedEvent.slug}/info`, query: { content: 1 }});
          }
          break;
        case "lost&found":
          let query = { page: 1, checkpoint_id: 0, content: 1 };
          if (!this.formatter.checkRouteSame(query, this.$route.query)) {
            this.$router.push({path: `/events/${this.getFetchedEvent.slug}/gallery`, query});
          }
          break;
        default:
          break;
      }
    },
    onCopy() {
      Eventbus.$emit("show-toast", this.$t("core.copy"));
      this.$store.dispatch("fireTracking", {
        event_type: "CLINK",
        ext_race_id: this.getFetchedEvent.id,
      });
    },
    onCopyError() {
      Eventbus.$emit("show-toast", this.$t("core.copy_fail"));
    },
    handleScroll() {
      if (
        $(".event-title.normal").length > 0 &&
        $(".event-title.normal").parent().length > 0
      ) {
        let current = $(document).scrollTop();
        let offset = $(".event-title.normal").parent().offset().top;
        if (current > offset) {
          if (!this.showEventTitle) {
            this.showEventTitle = true;
            Eventbus.$emit("change-show-top-btn", true);
            Eventbus.$emit("change-show-top-btn-runner", true);
          }
        } else {
          if (this.showEventTitle) {
            this.showEventTitle = false;
            Eventbus.$emit("change-show-top-btn", false);
            Eventbus.$emit("change-show-top-btn-runner", false);
          }
        }
      }

      this.showNavEventTitle = $(document).scrollTop() > 200;
    },
    selectRunner(runner) {
      const selectedRunnerOrTeam = this.getSelectedRunnerOrTeam;
      if (
        runner.type == "team" &&
        (!selectedRunnerOrTeam ||
          !selectedRunnerOrTeam.isTeam ||
          selectedRunnerOrTeam.data == null ||
          (selectedRunnerOrTeam.data &&
            selectedRunnerOrTeam.data.id != runner.registrant_id))
      ) {
        this.$store.dispatch("setSelectedTeam", runner);
        // this.closeImageView();
        if (runner.team_bib_number == null || runner.team_bib_number == 0) {
          this.$router.push({
            path: `/events/${this.getFetchedEvent.slug}/teams/${runner.registrant_id}`,
          });
        } else {
          this.$router.push({
            path: `/events/${this.getFetchedEvent.slug}/registrants/${runner.team_bib_number}`,
          });
        }

        $("html, body").animate({ scrollTop: 0 }, 500);
      } else if (
        runner.type == "runner" &&
        (!selectedRunnerOrTeam ||
          selectedRunnerOrTeam.isTeam ||
          selectedRunnerOrTeam.data == null ||
          (selectedRunnerOrTeam.data &&
            selectedRunnerOrTeam.data.id != runner.registrant_id))
      ) {
        this.$store.dispatch("setSelectedRunner", runner);
        // this.closeImageView();
        this.$router.push({
          path: `/events/${this.getFetchedEvent.slug}/registrants/${
            runner.bib || runner.registrant_id
          }`,
        });
        $("html, body").animate({ scrollTop: 0 }, 500);
      }
      this.showingSearch = false;
      this.showingShare = false;
    },
    backHome() {
      if (this.$route.name !== "PurchasedPhotos") {
        $("html, body").animate({ scrollTop: 0 }, 500);
        Eventbus.$emit("close-single-view-image");
        if (this.getFetchedEvent) {
          if (this.$route.params.tab !== "results")
            this.$router.push({
              path: `/events/${this.getFetchedEvent.slug}/results`,
            });
        } else {
          this.$router.push({ path: `/events` });
        }
      }
    },
    faceSearch() {
      this.$refs["app-header-face-search"].faceSearch();
    },
    async getEventTeamList(id) {
      console.log(`getEventTeamList - ${id}`);
      let resp = null;
      try {
        resp = await getTeamList(id);
      } catch (e) {
        return [];
      }
      let json = JSON.parse(resp.data.body);
      this.$store.dispatch("setEventTeamList", json);
    },
  },
  mounted() {
    Eventbus.$on("header-scroll", () => {
      this.handleScroll();
    });
  },
  beforeDestroy() {
    Eventbus.$off("header-scroll");
  },
  created() {
    this.$watch(
      () => this.getFetchedEvent,
      async () => {
        let resp = await getTeamList(this.getFetchedEvent.id);
        let json = JSON.parse(resp.data.body);
        this.$store.dispatch("setEventTeamList", json);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
#app-header {
  .navbar {
    background: linear-gradient(
      180deg,
      var(--dark-color) 0%,
      var(--light-color) 100%
    );

    .navbar-brand {
      cursor: pointer;

      .enmotive-logo {
        display: block;
        background-image: url("@/assets/images/header/EnmotiveLogo.png");
        background-position: center left;
        background-size: 80%;
        background-repeat: no-repeat;
        height: 48px;
        width: 240px;
      }

      :deep(.event-title) {
        img {
          height: 48px;
          width: auto;
        }
        h1 {
          font-size: min(4vw, 1.5rem);
        }
        h2 {
          font-size: min(3vw, 1rem);
        }
        h1, h2 {
          width: calc(100vw - 185px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (min-width: 992px) {
            max-width: 30vw;
          }
        }
      }
    }

    :deep(.navbar-nav) {
      column-gap: 1.25rem;
      .nav-link {
        color: #ffffff;
      }
      .dropdown-menu {
        font-size: 0.9rem;
      }
    }
  }
}
</style>