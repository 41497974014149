import axios from 'axios';
import * as urls from './urls';

//axiosClient
const axiosClient = axios.create({
  baseURL: urls.BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': urls.grepcvAPIKey
  }
});

export const getEvent = (raceSlug) =>
  axiosClient.get(urls.GET_EVENT, {
    params: {
      ext_race_id: raceSlug
    }
  });

export const searchRunner = (raceSlug, term, limit = 15) =>
axiosClient.get(urls.SEARCH_RUNNER, {
    params: {
      ext_race_id: raceSlug,
      term: term,
      limit: limit
    }
  });

export const getImage = (raceSlug, from = 0, limit = 48, checkpoint = -1, teamId = null, startDate = null, endDate = null) => {
  let url = urls.GET_LF_IMAGE;
  let param = {
    ext_race_id: raceSlug,
    team_id: teamId,
    from: from,
    limit: limit,
  };
  if (checkpoint != 0) {
    url = urls.GET_IMAGE;
    param.checkpoint_id = checkpoint;
    if (startDate && endDate) {
      param.start_time = startDate;
      param.end_time = endDate;
    }
  }
  return axiosClient.get(url, {
    params: param
  });
}

export const getRunnerImage = (raceSlug, bib_number, faceKey = null, mirofaces = 1, update = false, from = 0, limit = 120) => {
  let param = {
    ext_race_id: raceSlug,
    bib_number: bib_number,
    bucket: 'NORM',
    from: from,
    limit: limit,
    mirofaces: mirofaces,
  };

  if (mirofaces === 1 && update) {
    param.update = Date.now();
  }

  if (faceKey) {
    param.face_key = faceKey;
  }
  
  return axiosClient.get(urls.GET_RUNNER_IMAGE, {
    params: param
  });
}

export const nearRunner = (raceSlug, bib_number) =>
  axiosClient.get(urls.NEAR_RUNNER, {
    params: {
      ext_race_id: raceSlug,
      bib_number: bib_number
    }
  });

export const getCheckpoint = (raceSlug) =>
  axiosClient.get(urls.GET_CHECKPOINT, {
    params: {
      ext_race_id: raceSlug,
      include_lf: 1
    }
  });

export const tagBib = (raceSlug, bib, imageKey) =>
  axiosClient.post(urls.TAG_BIB, {
    ext_race_id: raceSlug,
    bib_number: bib,
    image_key: imageKey
  });

export const untagBib = (raceSlug, bib, imageKey) =>
  axiosClient.post(urls.UNTAG_BIB, {
    ext_race_id: raceSlug,
    bib_number: bib,
    image_key: imageKey
  });

export const sendEmail = (type = 'race', raceSlug, email, subject, message, link, race_label) =>
  axiosClient.post(type == 'race' ? urls.SEND_EMAIL : urls.SEND_RUNNER_EMAIL, {
    to: email,
    options: {
      link: link,
      message: message,
      race_label: race_label,
      subject, subject
    }
  }, {
    params: {
      ext_race_id: raceSlug,
    }
  });

export const sendImageEmail = (raceSlug, image, email, subject, message, link, race_label, attachment) =>
  axiosClient.post(urls.IMAGE_EMAIL, {
    images: attachment ? [] : [image.image_id],
    attachment,
    to: email,
    message,
    options: {
      img: attachment ? null : `https://img.enmotive.grepcv.com/images/preview/${image.image_key}`,
      link: link,
      race_label: race_label,
      subject, subject
    }
  }, {
    params: {
      ext_race_id: raceSlug,
    }
  });

export const imageDetail = (imageId) =>
  axiosClient.get(urls.IMAGE_DETAIL, {
    params: {
      img_id: imageId,
    }
  });

export const imageWithResult = (params) =>
  axiosClient.get(urls.IMAGE_WITH_RESULT, {
    params
  });

// Use a different API endpoint for tracking stats - to avoid throughput limits on the main endpoint
export const fireTracking = (params) =>
  axiosClient.get(urls.FIRE_TRACKING, {
    params: params,
    baseURL: urls.STATS_API_URL
  });

export const submitSurvey = (payload) =>
  axiosClient.post(urls.SUBMIT_SURVEY, payload);

export const getOrders = (payload) =>
  axiosClient.post(urls.GET_ORDERS, payload);

export const downloadPhotos = (payload) =>
  axiosClient.post(urls.DOWNLOAD_PHOTO, payload);

export const tagMultiImages = (raceSlug, bib, imageKeys) =>
  axiosClient.post(urls.TAG_MULTI_IMAGES, {
    ext_race_id: raceSlug,
    bib_number: bib,
    image_keys: imageKeys
  });

export const updateFacerekKey = (raceSlug, bib, facekey) => {
  let url = urls.UPDATE_FACEREK_KEY + '?ext_race_id=' + raceSlug + '&bib=' + bib + '&face_key=' + facekey;
  return axiosClient.post(url);
}

//axiosClientEnmotive
const axiosClientEnmotiveSite = axios.create({
  baseURL: urls.ENMOTIVE_URL,
  withCredentials: true
});

export const getEnmotiveUserId = () => axiosClientEnmotiveSite.get(urls.GET_USER_ID);
//export const getEnmotiveUserId = () => {return {data: "5ebb3238-9574-4923-bf38-7c61c0a80a41"}};

//axiosClientEnmotiveBase
const axiosClientEnmotiveBase = axios.create({
  baseURL: urls.ENMOTIVE_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const legacySearchRunner = (raceSlug, searchKey, isBib = false, limit = 10) => {
  let params = {
    type: 'participant',
    status: 'complete',
    offset: 0,
    limit: limit
  };
  if (isBib) {
    params.bib = searchKey;
  } else {
    params.searchKey = searchKey;
  }
  return axiosClientEnmotiveBase.get(urls.LEGACY_SEARCH_RUNNER(raceSlug), {
    params
  });
}


//axiosClientEnmotive
const axiosClientEnmotive = axios.create({
  baseURL: urls.ENMOTIVE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosClientEnmotiveNew = axios.create({
  baseURL: urls.ENMOTIVE_NEW_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const fetchEvent = (raceSlug) =>
  axiosClientEnmotive.get(urls.FETCH_EVENT + '/' + raceSlug);

export const getLeaderboards = (raceSlug) =>
  axiosClientEnmotive.get(urls.GET_EVENT_LEADERBOARD(raceSlug));

export const getEventBrackets = (raceSlug, bracket_id, page = 1) =>
  axiosClientEnmotive.get(urls.GET_EVENT_LEADERBOARD(raceSlug), {
    params: {
      bracket_id,
      page
    }
  });
export const getCategories = (raceSlug) =>
  axiosClientEnmotive.get(urls.GET_EVENT_CATEGORIES(raceSlug));

export const getCategoriesNew = raceSlug =>
  axiosClientEnmotiveNew.get(urls.GET_EVENT_CATEGORIES_NEW(raceSlug));

export const getRunner = (raceSlug, bib) =>
  axiosClientEnmotive.get(urls.GET_EUNNER(raceSlug, bib));

//legacyVideoAxiosClient
const legacyVideoAxiosClient = axios.create({
  baseURL: urls.FETCH_LEGACY_VIDEO_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': urls.videoAPIKey
  }
});

//legacyVideoV2AxiosClient
const legacyVideoV2AxiosClient = axios.create({
  baseURL: urls.FETCH_LEGACY_VIDEO_API_URL_V2,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer 1'
    // 'x-api-key': urls.videoAPIKey
  }
});

// export const getVideoJson = () =>
//   axios.get(urls.VIDEO_JSON);

export const getLegacyVideoV2 = (raceSlug, bib) =>
  legacyVideoV2AxiosClient.get(urls.GET_VIDEO_V2, {
    params: {
      ext_race_id: raceSlug,
      bib_no: bib
    }
  });

export const getLegacyVideo = (raceSlug, bib) =>
  legacyVideoAxiosClient.get(urls.GET_VIDEO, {
    params: {
      ext_race_id: raceSlug,
      bib_no: bib
    }
  });

//uploadPhotoAxiosClient
const uploadPhotoAxiosClient = axios.create({
  baseURL: urls.UPLOAD_PHOTO_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const getUploadPhotoInfo = ({
  raceId,
  checkPointId,
  originalName,
  uploaderUuid,
  fileContentLength,
  mediaType
}) => uploadPhotoAxiosClient.post(`${urls.UPLOAD_PHOTO}/${raceId}/${checkPointId}`, {
    original_name: originalName,
    uploader_uuid: uploaderUuid,
    file_content_length: fileContentLength,
    media_type: mediaType
  });

export const getUploadPhotoInfoForShare = ({
  raceId,
  checkPointId,
  originalName,
  uploaderUuid,
  fileContentLength,
  mediaType
}) => uploadPhotoAxiosClient.post(`${urls.UPLOAD_PHOTO_FOR_SHARE}/${raceId}/${checkPointId}`, {
    original_name: originalName,
    uploader_uuid: uploaderUuid,
    file_content_length: fileContentLength,
    media_type: mediaType
  });

//newVideoAxiosClient
const newVideoAxiosClient = axios.create({
  baseURL: urls.NEW_VIDEO_API_BASE_URL
});

export const createVideo = ({
  mode,
  fps,
  race_id,
  bib_number,
  category,
  runner_name,
  img_list,
  details,
  team_id,
  hometown,
  res_pace,
  res_dist,
  finish_time
}) => newVideoAxiosClient.post(urls.CREATE_VIDEO_API_URL, {
  mode,
  fps,
  race_id,
  bib_number,
  category,
  runner_name,
  img_list,
  details,
  team_id,
  hometown,
  res_pace,
  res_dist,
  finish_time
});

export const getNewVideo = (
  race_id,
  bib
) => newVideoAxiosClient.get(urls.GET_VIDEO_API_URL(race_id, bib));

export const getVideoStatus = (
  request_id
) => newVideoAxiosClient.get(urls.GET_VIDEO_STATUS_API_URL(request_id));

//teamAxiosClient
const teamAxiosClient = axios.create({
  baseURL: urls.TEAM_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

const teamVideosAxiosClient = axios.create({
  baseURL: urls.BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});


export const getTeamInfo = (
  eventSlug,
  team_id,
  limit,
  offset,
  category_id
) => teamAxiosClient.get(urls.GET_TEAM_DETAIL(eventSlug, team_id, limit, offset, category_id));

export const getTeamList = (
  eventSlug
) => teamAxiosClient.get(urls.GET_TEAM_LIST(eventSlug));

export const getTeamVideos = (
  eventSlug,
  team_id,
  offset = 0,
  limit = 10
) => teamVideosAxiosClient.get(urls.GET_TEAM_VIDEOS(eventSlug, team_id, offset, limit));

export const checkEventVideoType = (
  eventSlug
) => teamAxiosClient.get(urls.CHECK_VIDEO(eventSlug));