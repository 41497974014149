<template>
  <footer id="app-footer" class="shadow">
    <b-container>
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div>
          <a href="https://enmotive.com/privacy" target="view_window">{{ $t("footer.privacy") }}</a> | 
          <a @click="showTerms()">{{ $t("footer.terms") }}</a>
        </div>
        <div>
          {{ $t('footer.reserve') }}
        </div>
      </div>
    </b-container>
      <terms-modal ref="terms-modal-component" />
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import TermsModal from "./TermsModal";

export default {
  name: "app-footer",
  components: {
    TermsModal
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getColor"])
  },
  methods: {
    showTerms() {
      this.$refs["terms-modal-component"].toggleModal();
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
#app-footer {
  position: sticky;
  top: 100%;
  padding: 1rem 0;
  margin-top: 3rem;
  border-top: 1px solid var(--light-gray);
  background-color: #ffffff;
  color: var(--gray);
  font-size: 0.9rem;

  a {
    color: var(--gray);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>