<template>
  <svg viewBox="0 0 300 300">
    <use :xlink:href="`#${name}`"></use>
  </svg>
</template>

<script>
export default {
  name: "icon",
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>
