<template>
  <div id="scroll-view">
    <infinite-grid-scroll-view
      v-if="getRaceGalleryInfo"
      ref="scroll-view-inf-grid"
      name="scroll-view-inf-grid"
      customCssClass="scroll-view-inf-grid"
      :pageInfo="pageInfo"
      adsType="singleImageViewInner"
      @onAppendItem="onAppendItem"
      @tagBib="tagBib"
      @facebookShare="facebookShare"
      @emailShare="emailShare"
      @download="download"
      @closeScrollView="goBackGallery"
    ></infinite-grid-scroll-view>
  </div>
</template>

<script>
import Eventbus from "../event-bus";
import { mapGetters } from "vuex";
import $ from "jquery";
import formatter from "../utils/formatter";
import InfiniteGridScrollView from "../components/InfiniteGridScrollView";
import { getImage } from "../api/index";

export default {
  name: "scroll-view",
  components: {
    InfiniteGridScrollView
  },
  computed: {
    ...mapGetters([
      "getWindowSize",
      "getFetchedEvent",
      "getPhotosPurchased",
      "getSelectedRunner",
      "getSelectedRunnerOrTeam",
      "getRaceGalleryInfo",
      "getOverlays"]),
    pageInfo() {
      return this.getRaceGalleryInfo ? this.getRaceGalleryInfo.pageInfo : null;
    },
    checkpoint() {
      return this.getRaceGalleryInfo
        ? this.getRaceGalleryInfo.checkpoint
        : null;
    },
    time() {
      return this.getRaceGalleryInfo ? this.getRaceGalleryInfo.time : null;
    },
    images() {
      return this.getRaceGalleryInfo ? this.getRaceGalleryInfo.images : null;
    },
    getRunnerData() {
      return this.getSelectedRunnerOrTeam ? this.getSelectedRunnerOrTeam.data : null;
    },
    IsStickerAvailable() {
      const stickerList = [];
      this.getOverlays.forEach(value => {
        value.overlays.forEach(overlayItem => {
          if (overlayItem.overlay_position === "sticker"
            && stickerList.indexOf(overlayItem.overlay_logo) === -1) {
            stickerList.push(overlayItem.overlay_logo);
          }
        })
      })

      return stickerList.length > 0;
    },
    IsMarkupAvailable() {
      const runnerData = this.getRunnerData;
      if (!runnerData) return false;
      if (!runnerData.firstname || !runnerData.lastname) return false;
      if (!runnerData.category || !runnerData.category.completed
        || !runnerData.category.completed.distance
        || !runnerData.category.completed.distance.name) return false;
      if (!runnerData.result || !runnerData.result.duration
        || !runnerData.result.duration.chip) return false;

      return true;

    },

  },
  data() {
    return {
      formatter,
      appending: false,
      imvImages: []
    };
  },
  methods: {
    async callGetImage(
      checkpoint = -1,
      start_time = null,
      end_time = null,
      from = 0,
      limit = this.pageInfo.limit,
      append = false
    ) {
      this.appending = true;
      let resp = null;
      try {
        resp = await getImage(
          this.getFetchedEvent.id,
          from,
          limit,
          checkpoint,
          null,
          start_time,
          end_time
        );
        if (
          resp.data.errorType == "Error" ||
          resp.data.status.type == "error"
        ) {
          throw new Error("api error");
        }
      } catch (e) {
        Eventbus.$emit("show-api-error");
        return;
      }
      let _this = this;
      let newImages = this.formatter
        .addPreviewImagePath(resp.data.images)
        .map(item => {
          const el = item;
          el.page = _this.pageInfo.page;
          return el;
        });
      this.getRaceGalleryInfo.images.push(...newImages);
      this.getRaceGalleryInfo.allImages.push(...newImages);
      this.appendToInfGrid(newImages);
      if (
        resp.data.total_images <= this.pageInfo.limit ||
        this.pageInfo.total < this.pageInfo.limit ||
        resp.data.total_images <= this.pageInfo.page * this.pageInfo.limit
      ) {
        this.pageInfo.isLastPage = true;
      } else {
        this.pageInfo.isLastPage = false;
      }
    },
    goBackGallery() {
      if (this.$refs["scroll-view-inf-grid"]) {
        this.$refs["scroll-view-inf-grid"].clear();
        this.$refs["scroll-view-inf-grid"].destroy();
      }
      let query = this.$route.query;
      query.back = true;
      if (this.getFetchedEvent) {
        this.$router.push({
          path: `/events/${this.getFetchedEvent.slug}/gallery`,
          query: query
        });
      } else {
        let path = window.location.href;
        let pathSplit = path.split("/");
        let index = pathSplit.indexOf("scroll");
        if (index >= 0) {
          let slice = pathSplit.slice(index + 1);
          let join = slice.join("/");
          path = `/events/${join}`;
        }
        this.$router.push({
          path: path,
          query: query
        });
      }
    },
    tagBib(image, bib) {
      if (bib) {
        Eventbus.$emit("direct-tag-bib", image, bib);
      }
    },
    facebookShare(image) {
      this.changeRouterQuery(image.image_id);
      Eventbus.$emit("share-facebook", { type: "gallery", image });
    },
    emailShare(image) {
      this.changeRouterQuery(image.image_id);
      console.log(image)
      Eventbus.$emit("init-share-modal", { image, downloadOnly: false });
    },
    IsPurchaseAvailable(image) {
      const fetchedEvent = this.getFetchedEvent;
      let hasUploadCheckPoint = false;
      this.getOverlays.forEach(value => {
        if (
          value.checkpoint.checkpoint_id === image.check_point_id &&
          (value.checkpoint.checkpoint_label === "User Uploads"
        || value.checkpoint.checkpoint_label === "Uploads")) {
          hasUploadCheckPoint = true;
        }
      });

      return fetchedEvent && fetchedEvent.photos_purchase == "1" && image.check_point_id !== 3 && image.check_point_id !== "3"
       && hasUploadCheckPoint == false;
    },
    download(image, nearRunner = false) {
      // console.log('here');
      // if (this.getFetchedEvent) {
      //   image.nearRunner = nearRunner;
      //   if (this.getFetchedEvent.photos_purchase == "1") {
      //     Eventbus.$emit("init-download-modal", image);
      //   } else {
      //     Eventbus.$emit("direct-download", image);
      //   }
      // }

      if (this.IsPurchaseAvailable(image)) {
        // console.log('isPurchaseAvailable')
        image.nearRunner = nearRunner;
        Eventbus.$emit("init-download-modal", image);
      } else if (this.IsStickerAvailable || this.IsMarkupAvailable) {
        // console.log('should be shared');
        Eventbus.$emit("init-share-modal", { image });
      } else {
        image.nearRunner = nearRunner;
        Eventbus.$emit("direct-download", image);
      }
    },
    appendToInfGrid(images, init = false) {
      if (this.$refs["scroll-view-inf-grid"]) {
        this.$refs["scroll-view-inf-grid"].appendItem(images, init);
      }
    },
    onAppendItem() {
      if (!this.pageInfo.isLastPage) {
        this.appending = true;
        this.pageInfo.page++;
        this.callGetImage(
          this.checkpoint.checkpoint_id,
          this.time.start_time,
          this.time.end_time,
          (this.pageInfo.page - 1) * this.pageInfo.limit,
          this.pageInfo.limit,
          true
        );
      }
    },
    handleScroll() {
      let _this = this;
      let current = $(document).scrollTop();

      $(".single-image-view-item").each(function(index) {
        let top = $(this).offset().top;
        if ($(this).parent() && $(this).parent().offset()) {
          top -= $(this).parent().offset().top;
        }
        let bottom = top + $(this).outerHeight();
        if (top <= current && current < bottom) {
          let id = $(this).data("id");
          if (id && _this.$route.query.image_id != id) {
            let page = isNaN($(this).data("page")) ? null : parseInt($(this).data("page"));
            _this.changeRouterQuery(id, page);
            _this.triggerFireTracking(id);
          }
        }
      });
      if (this.$refs["scroll-view-inf-grid"]) {
        this.$refs["scroll-view-inf-grid"].handleScroll();
      }
    },
    triggerFireTracking(imageId) {
      if(!isNaN(imageId) && this.getRaceGalleryInfo) {
        if(this.getRaceGalleryInfo.imvImages.indexOf(imageId) < 0) {
          this.getRaceGalleryInfo.imvImages.push(imageId);
          this.callFireTracking(imageId);
        }
      }
    },
    changeRouterQuery(imageId, page = null) {
      let query = {};
      if (imageId != null) {
        query = { view: 1 };
      }
      if (this.pageInfo) {
        query.page = page ? page : this.pageInfo.page;
      }
      if (imageId) {
        query.image_id = imageId;
      }
      if (this.checkpoint && this.checkpoint.checkpoint_id != -1) {
        query.checkpoint_id = this.checkpoint.checkpoint_id;
      }
      if (this.time && this.time.key != "all") {
        query.time = this.time.key;
      }
      if (!this.formatter.checkRouteSame(query, this.$route.query)) {
        this.$router.push({ query: query });
      }
    },
    callFireTracking(image = null) {
      if (this.getFetchedEvent) {
        let payload = {
          event_type: "IMV",
          ext_race_id: this.getFetchedEvent.id
        };
        if (image) {
          payload.image_id = image;
        }
        this.$store.dispatch("fireTracking", payload);
      }
    },
    getImageById(imageId) {
      for (const key in this.getRaceGalleryInfo.allImages) {
        if (this.getRaceGalleryInfo.allImages.hasOwnProperty(key)) {
          const image = this.getRaceGalleryInfo.allImages[key];
          if (`${image.image_id}` === `${imageId}`) {
            return image;
          }
        }
      }
      return null;
    }
  },
  mounted() {
    $("html, body").scrollTop(0);
    if (
      this.getWindowSize.width < 768 &&
      this.getFetchedEvent &&
      this.getRaceGalleryInfo &&
      this.images &&
      this.images.length > 0
    ) {
      Eventbus.$emit("change-loader-status", true);
      this.changeRouterQuery(this.images[0].image_id, this.images[0].page);
      this.appendToInfGrid(this.images, true);
    } else {
      this.goBackGallery();
    }

    Eventbus.$on("close-image-sroll-view", () => {
      if (this.getWindowSize.width < 768) {
        this.goBackGallery();
      }
    });

    Eventbus.$on("scrollview-scroll", () => {
      this.handleScroll();
    });

    Eventbus.$on("scrollview-share-facebook", image_id => {
      let image = this.getImageById(image_id);
      if (image) {
        Eventbus.$emit("share-facebook", { type: "gallery", image });
      }
    });
  },
  beforeDestroy() {
    Eventbus.$off("close-image-sroll-view");
    Eventbus.$off("scrollview-scroll");
    Eventbus.$off("scrollview-share-facebook");
  }
};
</script>

<style lang="scss">
#scroll-view {
}
</style>
