<template>
  <div>
    <b-modal
      id="share-modal"
      modalClass="modal-fullscreen-md"
      size="xl"
      hide-footer
      hide-header
    >
      <b-container class="p-0">
        <b-row no-gutters>
          <b-col cols="12" class="text-center mrg-b">
            <div class="title">
              <span class="icon mr-2">
                <i class="fas fa-share-alt"></i>
              </span>
              {{
                canShare
                  ? $t("share.modal_title")
                  : $t("share.modal_title_not_share")
              }}
              <img
                @click="closeModal()"
                class="img-fluid close-btn"
                src="@/assets/images/single-image-view/close.png"
              />
            </div>
          </b-col>
          <b-col v-if="image" cols="12" class="text-center">
            <div class="content">
              <div class="image-wrapper">
                <img
                  v-if="updatedImage == null"
                  class="img-fluid"
                  id="imgOriginal"
                  :src="image.previewPath"
                />
                <img v-else id="imgUpdated" :src="updatedImage" />
                <b-progress
                  v-if="showProgress"
                  class="upload-progress"
                  :value="uploadedPercentage"
                  :max="maxPercentage"
                  animated
                />
                <div v-if="showProgress">
                  {{ $t(uploadingText) }}
                </div>
              </div>
              <div class="share-wrapper">
                <div class="description mrg-b">
                  {{ $t(getDescription) }}
                </div>
                <div v-if="IsStickerAvailable" class="switch-wrapper">
                  <div class="switch-label">
                    {{ $t("share.add_sticker_label") }}
                  </div>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitches"
                      v-model="stickerEnabled"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitches"
                    ></label>
                  </div>
                </div>
                <div v-if="IsMarkupAvailable" class="switch-wrapper">
                  <div class="switch-label">
                    {{ $t("share.add_finish_time_label") }}
                  </div>
                  <div class="custom-control custom-switch" size="lg">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitches2"
                      v-model="markupEnabled"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitches2"
                    ></label>
                  </div>
                </div>
                <div v-if="IsSaveToGalleryAvailable" class="switch-wrapper">
                  <div class="switch-label">
                    {{ $t("share.save_to_gallery") }}
                  </div>
                  <div class="custom-control custom-switch" size="lg">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitches3"
                      v-model="saveGalleryEnabled"
                      :disabled="disableGalleryOption"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitches3"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="text-center mrg-b">
            <button
              v-if="canShare"
              class="submit-btn mb-2 float-right"
              @click="webShare()"
            >
              {{ $t("share.btn_share") }}
            </button>
            <button
              v-else-if="downloadOnly"
              class="submit-btn mb-2 float-right"
              @click="directDownload()"
            >
              {{
                IsStickerAvailable || IsMarkupAvailable
                  ? $t("share.btn_download")
                  : $t("share.btn_download_only")
              }}
            </button>
            <div class="share-button-group" v-else>
              <button
                class="mb-2 ml-3"
                @click="emailShare()"
                :disabled="disabledEmailShare"
              >
                <i class="icon fas fa-envelope"></i>
              </button>

              <button
                v-if="getPhotosSocialSharing"
                class="mb-2 ml-3"
                @click="facebookShare()"
                :disabled="disabledFacebookShare"
              >
                <i class="icon fab fa-facebook-f"></i>
              </button>

              <button
                class="mb-2 ml-3"
                @click="directDownload()"
                :disabled="disabledDirectDownload"
              >
                <i class="icon fas fa-arrow-down"></i>
              </button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <DokaModal
      :src="originalImage"
      ref="doka"
      v-if="showDoka"
      outputStripImageHead="true"
      outputType="image/jpeg"
      :outputData="true"
      cropResizeMatchImageAspectRatio="true"
      :cropAllowRotate="false"
      cropAllowImageFlipHorizontal="false"
      cropAllowImageFlipVertical="false"
      :labelButtonCropAspectRatio="
        $t('dokaCropAspectRatioOptions.button-label')
      "
      styleCropCorner="line"
      :utils="dokaUtils"
      :stickers="getStickers"
      :markup="getMarkup"
      @confirm="handleDokaConfirm"
      @cancel="handleDokaCancel"
      @close="enabled = false"
    />
    <a style="display:none" id="download_link" />
    <a
      ref="download-btn"
      class="d-none"
      :href="link"
      download="img.jpg"
      :target="_self"
    ></a>
    <div class="d-none">
      <svg v-if="IsMarkupAvailable" viewBox="0 0 400 200" class="svg" ref="logoSVG" id="logoSVG" width="600" height="300">
        <rect
          x="0"
          y="0"
          rx="20"
          ry="20"
          width="400"
          height="200"
          fill="white"
          fill-opacity="0.7"
        />
        <rect
          x="4"
          y="4"
          rx="16.5"
          ry="16.5"
          width="392"
          height="192"
          fill="transparent"
          stroke="black"
          stroke-width="4px"
          stroke-opacity="0.8"
        />
        <g>
          <svg width="50" height="50" viewBox="0 0 512 512" x="40" y="13">
            <path
              fill="black"
              stroke="#FFFFFF"
              stroke-width="6px"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
            />
          </svg>
          <text
            x="250"
            y="54"
            text-length="300"
            text-anchor="middle"
            style="
              font-family: Roboto, Helvetica, Arial, sans-serif;
              font-size: 3rem;
              paint-order: stroke;
              stroke: #ffffff;
              stroke-width: 2px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              font-weight: 800;
            "
          >
          {{ getRunnerData.result.duration.chip, }}
          </text>
        </g>
        <g>
          <svg
            width="65"
            height="50"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            x="30"
            y="68"
          >
            <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
            <path
              fill="black"
              stroke="#FFFFFF"
              stroke-width="6px"
              d="M573.19 402.67l-139.79-320C428.43 71.29 417.6 64 405.68 64h-97.59l2.45 23.16c.5 4.72-3.21 8.84-7.96 8.84h-29.16c-4.75 0-8.46-4.12-7.96-8.84L267.91 64h-97.59c-11.93 0-22.76 7.29-27.73 18.67L2.8 402.67C-6.45 423.86 8.31 448 30.54 448h196.84l10.31-97.68c.86-8.14 7.72-14.32 15.91-14.32h68.8c8.19 0 15.05 6.18 15.91 14.32L348.62 448h196.84c22.23 0 36.99-24.14 27.73-45.33zM260.4 135.16a8 8 0 0 1 7.96-7.16h39.29c4.09 0 7.53 3.09 7.96 7.16l4.6 43.58c.75 7.09-4.81 13.26-11.93 13.26h-40.54c-7.13 0-12.68-6.17-11.93-13.26l4.59-43.58zM315.64 304h-55.29c-9.5 0-16.91-8.23-15.91-17.68l5.07-48c.86-8.14 7.72-14.32 15.91-14.32h45.15c8.19 0 15.05 6.18 15.91 14.32l5.07 48c1 9.45-6.41 17.68-15.91 17.68z"
            />
          </svg>

          <text
            x="250"
            y="110"
            text-length="300"
            text-anchor="middle"
            style=" font-family: Roboto, Helvetica, Arial, sans-serif; font-size: 3rem; paint-order: stroke; stroke: #ffffff; stroke-width: 2px; stroke-linecap: butt; stroke-linejoin: miter; font-weight: 800; "
          >
          {{ getRunnerData.category.completed.distance.name }}
          </text>
        </g>
        <g>
          <text
            x="200" y="150" width="360" height="200" text-length="300" text-anchor="middle"
            v-bind="{'font-size': firstLineTextSize}"
            style=" font-family: Roboto, Helvetica, Arial, sans-serif; paint-order: stroke; stroke: #ffffff; stroke-width: 2px; stroke-linecap: butt; stroke-linejoin: miter; font-weight: 800;"
          >
            {{ getFinishTimeStickerFirstLine }}
          </text>
          <text
            x="200" y="182" width="360" height="200" text-length="300" text-anchor="middle"
            v-bind="{'font-size': secondLineTextSize}"
            style=" font-family: Roboto, Helvetica, Arial, sans-serif; paint-order: stroke; stroke: #ffffff; stroke-width: 2px; stroke-linecap: butt; stroke-linejoin: miter; font-weight: 800;"
          >
            {{ getFinishTimeStickerSecondLine }}
          </text>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import Eventbus from "../event-bus";
import is from "is-js";
import {
  getCheckpoint,
  imageDetail,
  getUploadPhotoInfo,
  getUploadPhotoInfoForShare
} from "../api/index";
import { mapGetters } from "vuex";
import moment from "moment";
import converter from "../utils/converter";

import SearchBox from "../components/SearchBox";
import { DokaModal, toURL } from "../vue-doka/";

export default {
  name: "share-modal",
  components: {
    SearchBox,
    DokaModal
  },
  computed: {
    ...mapGetters([
      "getRunnerImages",
      "getHideFacematches",
      "getSelectedRunnerOrTeam",
      "getSelectedRunner",
      "getOverlays",
      "getFetchedEvent",
      "getCurrentRaceId",
      "getPhotosSocialSharing"
    ]),
    canShare() {
      return this.$isMobile() && navigator.share 
    },
    isMobile() {
      return this.$isMobile()
    },
    getFinishTimeStickerFirstLine() {
      let line = this.getRunnerData.firstname
      if (this.getRunnerData.team && this.getRunnerData.team.name) line = `${this.getRunnerData.firstname} ${this.getRunnerData.lastname}`
      if (line.length > 15) {
        this.firstLineTextSize="1rem"
      } else {
        this.firstLineTextSize="2rem"
      }
      return line
    },
    getFinishTimeStickerSecondLine() {
      let line = this.getRunnerData.lastname
      if (this.getRunnerData.team && this.getRunnerData.team.name) line = `${this.getRunnerData.team.name}`
      if (line.length > 15) {
        this.secondLineTextSize="1rem"
      } else {
        this.secondLineTextSize="2rem"
      }
      return line
    },
    getRunnerData() {
      return this.getSelectedRunnerOrTeam
        ? this.getSelectedRunnerOrTeam.data
        : null;
    },
    getUploaderUdid() {
      return this.getSelectedRunner
        ? this.getSelectedRunner.registration_id
        : "";
    },
    getDownloadImages() {
      if (!this.getHideFacematches) {
        return this.getRunnerImages;
      }
      let arr = [];
      if (this.getRunnerImages) {
        this.getRunnerImages.forEach((element, index) => {
          if (element.face == false) {
            arr.push(element);
          }
        });
      }
      return arr;
    },
    IsRunnerPage() {
      return !!this.getSelectedRunnerOrTeam;
    },
    IsMarkupAvailable() {
      const runnerData = this.getRunnerData;
      if (!runnerData) return false;
      if (!runnerData.firstname || !runnerData.lastname) return false;
      if (
        !runnerData.category ||
        !runnerData.category.completed ||
        !runnerData.category.completed.distance ||
        !runnerData.category.completed.distance.name
      )
        return false;
      if (
        !runnerData.result ||
        !runnerData.result.duration ||
        !runnerData.result.duration.chip
      )
        return false;

      return true;
    },
    IsStickerAvailable() {
      // Finish time appears as a sticker if that's selected - but don't let it trigger the sticker option on its own
      return this.IsMarkupAvailable?this.getStickers.length > 1:this.getStickers.length > 0;
    },
    IsSaveToGalleryAvailable() {
      return (
        this.IsRunnerPage && (this.IsMarkupAvailable || this.IsStickerAvailable)
      );
    },
    IsDokaAvailable() {
      return (
        (this.IsStickerAvailable && this.stickerEnabled) ||
        (this.IsMarkupAvailable && this.markupEnabled)
      );
    },
    getDescription() {
      if (this.IsRunnerPage) {
        if (this.IsStickerAvailable) {
          if (this.IsMarkupAvailable) {
            return "share.description_runner_has_sticker_has_runner_detail";
          } else {
            return "share.description_runner_has_sticker_no_runner_detail";
          }
        } else {
          if (this.IsMarkupAvailable) {
            return "share.description_runner_no_sticker_has_runner_detail";
          } else {
            return "share.description_runner_no_sticker_no_runner_detail";
          }
        }
      } else if (this.IsStickerAvailable) {
        return "share.description_race_has_sticker";
      }

      return "share.description_race_no_sticker";
    },
    getCheckpointIdForSaveGallery() {
      return this.userUploadsCheckpointId >= 0
        ? this.userUploadsCheckpointId
        : this.uploadsCheckpointId >= 0
        ? this.uploadsCheckpointId
        : 3;
    },
    getStickers() {
      const stickerList = [];
      this.getOverlays.forEach(value => {
        value.overlays.forEach(overlayItem => {
          if (
            overlayItem.overlay_position === "sticker" &&
            stickerList.indexOf(overlayItem.overlay_logo) === -1
          ) {
            stickerList.push(overlayItem.overlay_logo);
          }
        });

        if (value.checkpoint.checkpoint_label === "User Uploads") {
          this.userUploadsCheckpointId = value.checkpoint.checkpoint_id;
        } else if (value.checkpoint.checkpoint_label === "Uploads") {
          this.uploadsCheckpointId = value.checkpoint.checkpoint_id;
        }
      });

      let stickers = stickerList.map(sticker => {
        return {
          sticker: `https://img.grepcv.com/logo/${sticker}`,
          markup: [
            "image",
            {
              src: `https://img.grepcv.com/logo/${sticker}`,
              width: 0.15,
              height: 0.15,
              fit: "contain"
            }
          ]
        };
      });
      if (this.IsMarkupAvailable) {
        stickers.push({
          sticker: [
            'text',  {
              'fontFamily' : 'Font Awesome\\ 5 Free',
              text: '\uf017'

            }
          ],
          markup: [
          "image", {
            left: 0.01,
            top: 0.01,
            width: '600px',
            height: '300px',
            src: this.getFinishTimeStickerDataUrl(),
            allowSelect: true,
            allowEdit: false,
            allowMove: true,
            allowResize: true,
            fit: "contain"
          },
        ]
        })

      }
      return stickers;
    },

    getMarkup() {
      if (!this.IsMarkupAvailable || !this.markupEnabled) return [];
      const markup = [
        [
          "image",
          {
            right: 0.01,
            top: 0.01,
            width: '600px',
            height: '300px',
            src: this.getFinishTimeStickerDataUrl(),
            allowSelect: true,
            allowEdit: false,
            allowMove: true,
            allowResize: true,
            fit: 'contain'
          },
        ]
      ];
      return markup;
    }
  },
  data() {
    return {
      image: null,
      imageInfo: null,
      downloadOnly: false,
      originalImage: null,
      updatedImage: null,
      blank: "",
      link: "",
      purchaseText: "",
      moment,
      showDoka: false,
      shareType: "",
      dokaUtils: ["sticker"],
      stickerEnabled: true,
      markupEnabled: false,
      saveGalleryEnabled: false,
      disableGalleryOption: false,
      userUploadsCheckpointId: -1,
      uploadsCheckpointId: -1,
      uploadedPercentage: 0,
      maxPercentage: 100,
      showProgress: false,
      uploadingText: "share.saving_to_gallery",
      disabledEmailShare: false,
      disabledFacebookShare: false,
      disabledDirectDownload: false,
      firstLineTextSize: "1rem",
      secondLineTextSize: "1rem",
    };
  },
  watch: {
    stickerEnabled(val) {
      const galleryEnabled =
        val || (this.markupEnabled && this.IsMarkupAvailable);
      this.saveGalleryEnabled = galleryEnabled && this.saveGalleryEnabled;
      this.disableGalleryOption = !galleryEnabled;
    },
    markupEnabled(val) {
      const galleryEnabled =
        val || (this.stickerEnabled && this.IsStickerAvailable);
      this.saveGalleryEnabled = galleryEnabled;
      this.disableGalleryOption = !galleryEnabled;
    }
  },
  methods: {
    getFinishTimeStickerDataUrl() {
      if (this.markupEnabled && this.IsMarkupAvailable) {
        const svgElement = this.$refs.logoSVG;
        const svgData = new XMLSerializer().serializeToString(svgElement)
        return `data:image/svg+xml;base64,` + btoa(svgData)
      } else return ""
    },
    async callImageDetail(imageKey) {
      const resp = await imageDetail(imageKey);
      if (resp.data.detail) {
        this.imageInfo = resp.data.detail[0];
      }
    },
    async callGetUploadPhotoInfo(file) {
      const resp = await getUploadPhotoInfo({
        raceId: this.getCurrentRaceId,
        checkPointId: this.getCheckpointIdForSaveGallery,
        originalName: file.name,
        uploaderUuid: this.getUploaderUdid,
        fileContentLength: file.size,
        mediaType: file.type
      });
      return resp.data;
    },
    async callGetUploadPhotoInfoForShare(file) {
      const resp = await getUploadPhotoInfoForShare({
        raceId: this.getCurrentRaceId,
        checkPointId: this.image.check_point_id,
        originalName: file.name,
        uploaderUuid: this.getUploaderUdid,
        fileContentLength: file.size,
        mediaType: file.type
      });
      return resp.data;
    },
    sendRequest(file, presignedURL) {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.upload.addEventListener("progress", event => {
          this.uploadedPercentage = Math.round(
            (event.loaded / event.total) * 100
          );
        });

        req.upload.addEventListener("load", event => {
          // Success Upload
          this.showProgress = false;
          resolve();
        });

        req.upload.addEventListener("error", event => {
          this.showProgress = false;
          reject();
        });

        req.open("PUT", presignedURL);
        req.send(file);
      });
    },
    closeModal() {
      this.$bvModal.hide("share-modal");
      this.$bvModal.hide("download-modal");
    },
    emailShare() {
      if (this.IsDokaAvailable) {
        this.showDoka = true;
        this.shareType = "email";
      } else {
        Eventbus.$emit("init-email-photo-modal", this.image);
      }
    },
    LoadStickers() {
      const stickerList = [];
      this.getOverlays.forEach(value => {
        value.overlays.forEach(overlayItem => {
          if (
            overlayItem.overlay_position === "sticker" &&
            stickerList.indexOf(overlayItem.overlay_logo) === -1
          ) {
            stickerList.push(overlayItem.overlay_logo);
          }
        });
      });

      for (const sticker of stickerList) {
        var img = new Image();
        img.crossOrigin = "anonymous";
        img.src = `https://img.grepcv.com/logo/${sticker}`;
      }
    },
    facebookShare() {
      if (this.IsDokaAvailable) {
        this.showDoka = true;
        this.shareType = "facebook";
      } else {
        Eventbus.$emit("share-facebook", {
          type: "gallery",
          image: this.image
        });
      }
      //Eventbus.$emit("share-facebook", { type: "cert", image: this.certImage });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
      return new Blob([u8arr], {type:mime});
    },
    async webShare() {
      if (this.IsDokaAvailable) {
        this.showDoka = true;
        this.shareType = "webshare";
      } else {
        var fb_share_title = this.domStrDecode(this.getFetchedEvent.name);
        var fb_share_desc = this.$t("facebook.desc", { name: fb_share_title });
        let imageLink = this.image.previewPath.replace("/preview/", "/download/");
        console.log(this.image)
        console.log(this.originalImage)

        const blob = this.dataURLtoBlob(this.originalImage)

        const file = new File([blob], this.image.image_key, { type: blob.type });

        this.$store.dispatch("fireTracking", {
          event_type: "IMS",
          ext_race_id: this.getFetchedEvent.id,
        })

        if (navigator.canShare({
          files: [file]
        })) {
          await navigator.share({
            files: [file],
            //title: fb_share_title,
            //text: fb_share_desc,
            //url: imageLink
          })
          this.closeModal();
        } else {
          //fallback
          this.closeModal();
          return false
        }
      }
    },
    directDownload() {
      if (this.IsDokaAvailable) {
        this.showDoka = true;
        this.shareType = "download";
      } else {
        this.closeModal();
        Eventbus.$emit("direct-download", this.image);
      }
    },
    handleDokaCancel() {
      this.showDoka = false;
    },
    async uploadAndShareFacebook(output) {
      // 1. get checkpoint id
      // 2. get presigned url for sharing
      // 3. upload photo
      // 4. share url to facebook
      const checkpointId = this.image.check_point_id;
      const respForShare = await this.callGetUploadPhotoInfoForShare(
        output.file
      );

      if (respForShare && respForShare.success) {
        this.uploadedPercentage = 0;
        this.uploadingText = "share.preparing_to_share";
        this.showProgress = true;

        this.sendRequest(output.file, respForShare.presignedURL).then(() => {
          Eventbus.$emit("share-facebook", {
            type: "updated",
            image: {
              previewPath: respForShare.uri,
              image_id: respForShare.hash,
              width: output.data.image.width,
              height: output.data.image.height
            }
          });
        });
      }
    },
    async handleDokaConfirm(output) {
      console.dir(output, { depth: null });
      this.showDoka = false;
      this.updatedImage = URL.createObjectURL(output.file);
      this.disabledEmailShare = true;
      this.disabledFacebookShare = true;
      this.disabledDirectDownload = true;
      if (this.IsSaveToGalleryAvailable && this.saveGalleryEnabled) {
        // Upload file to gallery here
        const resp = await this.callGetUploadPhotoInfo(output.file);
        if (resp && resp.success) {
          this.uploadedPercentage = 0;
          this.uploadingText = "share.saving_to_gallery";
          this.showProgress = true;
          this.sendRequest(output.file, resp.presignedURL).then(async () => {
            Eventbus.$emit("reload-runner-page", 10);
            if (this.shareType === "facebook") {
              await this.uploadAndShareFacebook(output);
            }
          });
        }
      }

      if (this.shareType === "email") {
        converter.blobToBase64(output.file).then(res => {
          Eventbus.$emit("init-email-photo-modal-base64", this.image, {
            content: res,
            type: "image/jpeg",
            filename: output.file.name
          });
        });
      } else if (
        (!this.IsSaveToGalleryAvailable || !this.saveGalleryEnabled) &&
        this.shareType === "facebook"
      ) {
        await this.uploadAndShareFacebook(output);
      } else if (this.shareType === "download") {
        this.saveData(output.file, output.file.name);
        this.closeModal();
      } else if (this.shareType === "webshare") {
        var fb_share_title = this.domStrDecode(this.getFetchedEvent.name);
        var fb_share_desc = this.$t("facebook.desc", { name: fb_share_title });
        this.$store.dispatch("fireTracking", {
          event_type: "IMS",
          ext_race_id: this.getFetchedEvent.id,
        })
        const file = new File([output.file], this.image.image_key, { type: output.file.type });

        if (navigator.canShare({
          files: [file]
        })) {
          await navigator.share({
            files: [file],
            title: fb_share_title,
            text: fb_share_desc
          });
          this.closeModal();
        } else {
          this.closeModal();
          return false
        }
      }
    },
    saveData(data, fileName) {
      var downloadLink = document.getElementById("download_link");
      var url = window.URL.createObjectURL(data);
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.target = "_self";
      downloadLink.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(url);
        //document.body.removeChild(downloadLink);
      }, 100);
    },
    domStrDecode(str) {
      var parser = new DOMParser();
      var dom = parser.parseFromString(
        "<!doctype html><body>" + str,
        "text/html"
      );
    }
  },
  mounted() {
    Eventbus.$on("init-share-modal", ({ image, downloadOnly }) => {
      console.log(image)
      this.stickerEnabled = true;
      this.markupEnabled = false;
      (this.saveGalleryEnabled = false), (this.disabledEmailShare = false);
      this.disabledFacebookShare = false;
      this.disabledDirectDownload = false;
      this.LoadStickers();
      this.downloadOnly = downloadOnly;
      this.updatedImage = null;
      if (!image.image_id) {
        this.blank = "_blank";
      } else {
        this.image = image;
        this.callImageDetail(image.image_id);
        const baseImg = new Image();
        baseImg.crossOrigin = "";
        baseImg.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = baseImg.width;
          canvas.height = baseImg.height;
          ctx.drawImage(baseImg, 0, 0);
          this.originalImage = canvas.toDataURL("image/png");
          console.log(`Created image representation of ${image.image_id}`)
        };
        let imageLink = this.image.previewPath.replace("/preview/", "/download/");
        baseImg.src = imageLink//.replace(/^https:\/\//i, "http://");
      }
      this.$bvModal.show("share-modal");
    });

    Eventbus.$on("close-share-modal", () => {
      this.$bvModal.hide("share-modal");
    });
  },
  beforeDestroy() {
    Eventbus.$off("init-share-modal");
    Eventbus.$off("close-share-modal");
  }
};
</script>
<style lang="css">
#imgUpdated {
  width: 100%;
}
</style>
<style lang="scss" scoped>
#share-modal {
  .card-cols {
    max-height: 300px;
    overflow-y: auto;
    @media (min-width: 768px) {
      max-height: 600px;
    }
  }
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
    column-gap: 0.25rem;
    @media (min-width: 576px) {
      -webkit-column-count: 6;
      -moz-column-count: 6;
      column-count: 6;
      -webkit-column-gap: 0.25rem;
      -moz-column-gap: 0.25rem;
      column-gap: 0.25rem;
    }
    @media (min-width: 768px) {
      -webkit-column-count: 8;
      -moz-column-count: 8;
      column-count: 8;
    }

    .card {
      display: inline-block;
      width: 100%;
      margin-bottom: 0.25rem;
    }
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    color: #6f7372;
    @media (min-width: 768px) {
      font-size: 18px;
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .mrg-b {
    margin-bottom: 30px;
  }
  .pd-1 {
    padding: 0 10px;
  }
  .pd-2 {
    padding: 0 30px;
  }
  .hint {
    font-size: 14px;
    color: #6f7372;
  }
  .hint-sm {
    font-size: 11px;
    color: #6f7372;
  }
  .card {
    pointer-events: none;
  }
  .submit-btn {
    display: block;
    cursor: pointer;
    width: 100%;
    max-width: 220px;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;
    border: none;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;

    @media (min-width: 768px) {
      margin-right: 18px;
    }

    &.alt,
    &.alt1 {
      background-color: #fff;
      border: 1px solid #6f7372;
      color: #6f7372;
    }
    &.dark {
      background-color: var(--dark-color);
    }
    &.md-fix {
      @media (min-width: 768px) {
        display: inline-block;
        max-width: 300px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    .image-wrapper {

      @media (min-width: 768px) {
        max-width: 33.3%;
      }
    }
    .share-wrapper {
      @media (min-width: 768px) {
        max-width: 66.7%;
        padding-left: 20px;
        padding-right: 20px;
      }

      @media (min-width: 1000px) {
        padding-left: 50px;
      }

      padding-bottom: 20px;

      .description {
        @media (max-width: 767px) {
          display: none;
        }
        text-align: left;
        font-size: 24px;
        font-weight: 700;
        color: #6f7372;
      }
      .switch-wrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-right: 30px;

        @media (min-width: 768px) {
          padding-left: 0px;
        }

        @media (min-width: 1000px) {
          padding-left: 20px;
        }

        .switch-label {
          color: var(--primary-color);
          font-size: 20px;
          padding-top: 5px;
          text-transform: uppercase;

          @media (min-width: 1000px) {
            font-size: 26px;
            padding-top: 0px;
          }

          @media (max-width: 360px) {
            font-size: 24px;
            padding-top: 5px;
          }

          @media (max-width: 320px) {
            font-size: 20px;
            padding-top: 5px;
          }
        }
      }

      .custom-switch {
        min-height: 2.5rem;
        .custom-control-input {
          &:not(:disabled) {
            ~ {
              .custom-control-label {
                &::before {
                  border-color: var(--primary-color);
                }

                &::after {
                  background-color: var(--primary-color);
                }
              }
            }
          }

          &:disabled {
            ~ {
              .custom-control-label {
                &::before {
                  border-color: var(--disabled-color);
                }
                &::after {
                  background-color: var(--disabled-color);
                }
              }
            }
          }
        }

        .custom-control-label {
          &::before {
            height: 2.25rem;
            width: 5rem;
            border-radius: 1.5rem;
            outline: none;
          }

          &::after {
            left: calc(-2rem);
            top: calc(0.425rem + 1px);
            width: calc(1.75rem);
            height: calc(1.75rem);
            border-radius: 0.875rem;
          }
        }

        .custom-control-input {
          &:focus {
            ~ {
              .custom-control-label {
                &::before {
                  box-shadow: none;
                }
              }
            }
          }
          &:checked {
            ~ {
              .custom-control-label {
                &::before {
                  background-color: var(--primary-color);
                }

                &::after {
                  transform: translateX(2.75rem);
                  background-color: white;
                }
              }
            }
          }
        }
      }
    }
  }
  .share-button-group {
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background: var(--primary-color);
      border-radius: 1.5em;
      width: 3em;
      height: 3em;
      color: white;
      font-size: 1em;
    }
  }
  .close-btn {
    cursor: pointer;
  }
  span.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6f7372;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    i {
      color: #fff;
      font-size: 15px;
      margin-left: -2px;
    }
  }
}
</style>
