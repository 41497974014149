import axios from "axios";
import Vue from "vue";
import EventBus from "./event-bus.js";
import { fireTracking } from "./api/index";
import CONSTANT from "./const";
import formatter from "./utils/formatter.js";
import Vuex from "vuex";
Vue.use(Vuex);
import _ from "lodash";
const store = new Vuex.Store({
  state: {
    windowSize: {},
    headerHeight: 0,
    eventVideoType: CONSTANT.EVENT_VIDEO_INVALID,
    raceDefaultVideo: null,
    fetchedEvent: null,
    leaderboards: null,
    categories: [],
    categoriesForTeam: [],
    selectedRunner: null,
    selectedTeam: null,
    selectedTeamMembers: null,
    selectedRunnerOrTeam: null,
    runnerImages: null,
    imageReadyIMV: [],
    legacy: true,
    faceSearchResult: null,
    selectedImage: null,
    survey: null,
    currentNearRunner: null,
    adsCount: 0,
    noRaceImage: false,
    singleImageAdsCount: 0,
    color: null,
    showError: false,
    raceChanged: false,
    raceGalleryInfo: null,
    hideFacematches: false,
    showLostAndFound: false,
    enmotiveUserId: null,
    overlays: [],
    stickers: false,
    currentRaceId: null,
    uploadPhotoInfo: null,
    createVideoRequestId: null,
    comeFromTeam: false,
    //const
    noAdTenantIdArray: [
      "4d9205ce-7a0c-46c1-8929-4f56c0a86522",
      "5ed6a80c-c560-4ac7-a93f-0dbcc0a80a41"
    ],
    eventTeamList: []
  },
  getters: {
    getRaceChanged: state => state.raceChanged,
    getWindowSize: state => state.windowSize,
    getHeaderHeight: state => state.headerHeight,
    getEventVideoType: state => state.eventVideoType,
    getRaceDefaultVideo: state => state.raceDefaultVideo,
    getFetchedEvent: state => state.fetchedEvent,
    getPhotosPurchased: state => state.photos_purchase,
    getLeaderboards: state => state.leaderboards,
    getCategories: state => state.categories,
    getCategoriesForTeam: state => state.categoriesForTeam,
    getSelectedRunner: state => state.selectedRunner,
    getSelectedTeam: state => state.selectedTeam,
    getSelectedTeamMembers: state => state.selectedTeamMembers,
    getSelectedRunnerOrTeam: state => state.selectedRunnerOrTeam,
    getRunnerImages: state => state.runnerImages,
    getLegacy: state => state.legacy,
    getFaceSearchResult: state => state.faceSearchResult,
    getSelectedImage: state => state.selectedImage,
    getSurvey: state => state.survey,
    getCurrentNearRunner: state => state.currentNearRunner,
    getAdsCount: state => state.adsCount,
    getNoRaceImage: state => state.noRaceImage,
    getSingleImageAdsCount: state => state.singleImageAdsCount,
    getColor: state => state.color,
    getShowError: state => state.showError,
    getImageReadyIMV: state => state.imageReadyIMV,
    getRaceGalleryInfo: state => state.raceGalleryInfo,
    getHideFacematches: state => state.hideFacematches,
    getShowLostAndFound: state => state.showLostAndFound,
    getEnmotiveUserId: state => state.enmotiveUserId,
    getOverlays: state => state.overlays,
    hasStickers: state => state.stickers,
    getCurrentRaceId: state => state.currentRaceId,
    getUploadPhotoInfo: state => state.uploadPhotoInfo,
    getCreateVideoRequestId: state => state.createVideoRequestId,
    //const
    getNoAdTenantIdArray: state => state.noAdTenantIdArray,
    getShowAdsense: state => {
      let show = true;
      if (state.fetchedEvent && state.fetchedEvent.adsense != null) {
        if (
          state.fetchedEvent.adsense == 0 ||
          state.fetchedEvent.adsense == 2
        ) {
          show = false;
        }
      }
      if (show && state.fetchedEvent && state.fetchedEvent.tenant) {
        for (const key in state.noAdTenantIdArray) {
          if (state.noAdTenantIdArray.hasOwnProperty(key)) {
            const id = state.noAdTenantIdArray[key];
            if (state.fetchedEvent.tenant.id == id) {
              show = false;
              break;
            }
          }
        }
      }
      return show;
    },
    getComeFromTeam: state => state.comeFromTeam,
    getPhotosSocialSharing: state => {
      // console.log(`state.fetchedEvent - ${JSON.stringify(state.fetchedEvent)}`);
      if (_.has(state.fetchedEvent, "photos_social_sharing")) {
        return state.fetchedEvent.photos_social_sharing;
      }
      return 1;
    },
    getEventTypeRaceHomeResultsString: state=> {
      if (_.has(state.fetchedEvent, "type")) {
        switch (state.fetchedEvent.type) {
          case "Awards":
            return "raceHome.results_nominee";
          case "Concerts":
          case "Education":
          case "Expo":
          case "Festival":
          case "Parade":
          case "Workshop":
            return "raceHome.results_participant";
          case "Membership":
            return "raceHome.results_member";
          case "Fundraising Only":
            return "raceHome.results_fundraiser";
          case "Cycling":
          case "Mountain Bike":
          case "Multisport":
          case "Obstacle":
          case "Paddle":
          case "Run":
          case "Run or walk":
          case "Skiing":
          case "Stair Climb":
          case "Swimming":
          case "Trail":
          case "Training":
          case "Virtual":
          case "Walk":
          default:
            return "raceHome.results";
        }
      } else {
        return "raceHome.results";
      }
    },
    getEventTypeDownloadString: state => {
      if (_.has(state.fetchedEvent, "type")) {
        switch (state.fetchedEvent.type) {
          case "Awards":
            return "runnerHome.download_award";
          case "Concerts":
          case "Education":
          case "Expo":
          case "Festival":
          case "Parade":
          case "Workshop":
          case "Membership":
          case "Fundraising Only":
          case "Cycling":
          case "Mountain Bike":
          case "Multisport":
          case "Obstacle":
          case "Paddle":
          case "Run":
          case "Run or walk":
          case "Skiing":
          case "Stair Climb":
          case "Swimming":
          case "Trail":
          case "Training":
          case "Virtual":
          case "Walk":
          default:
            return "runnerHome.download_certificate";
        }
      } else {
        return "runnerHome.download_certificate";
      }
    },
    getEventTypeIcon: state => {
      if (_.has(state.fetchedEvent, "type")) {
        switch (state.fetchedEvent.type) {
          case "Awards":
            return "awards";
          case "Concerts":
            return "concerts";
          case "Education":
            return "education";
          case "Expo":
            return "expo";
          case "Festival":
            return "festival";
          case "Parade":
            return "parade";
          case "Workshop":
            return "workshop";
          case "Membership":
            return "membership";
          case "Fundraising Only":
            return "fundraising";
          case "Cycling":
            return "cycling";
          case "Mountain Bike":
            return "mountain-bike";
          case "Multisport":
            return "multisport";
          case "Obstacle":
            return "obstacle";
          case "Paddle":
            return "paddle";
          case "Run":
            return "run";
          case "Run or walk":
            return "run-walk";
          case "Skiing":
            return "skiing";
          case "Stair Climb":
            return "stair-climb";
          case "Swimming":
            return "swimming";
          case "Trail":
            return "trail";
          case "Training":
            return "training";
          case "Virtual":
            return "virtual";
          case "Walk":
            return "walk";
          default:
            return "run";
        }
      } else {
        return "run";
      }
    },
    getEventTeamList: state => {
      return state.eventTeamList ? state.eventTeamList : [];
    },
    getEventTeamAvailable: state => {
      return state.eventTeamList && state.eventTeamList.length > 0
        ? true
        : false;
    }
  },
  mutations: {
    setRaceChanged(state, payload) {
      state.raceChanged = payload;
    },
    async fireTracking(state, payload) {
      await fireTracking(payload);
    },
    setEventVideoType(state, payload) {
      state.eventVideoType = payload;
    },
    setRaceDefaultVideo(state, payload) {
      state.raceDefaultVideo = payload;
    },
    setWindowSize(state, payload) {
      state.windowSize = payload;
    },
    setHeaderHeight(state, payload) {
      state.headerHeight = payload - 1;
    },
    setFetchedEvent(state, payload) {
      state.fetchedEvent = payload;
      state.fetchedEvent.adsense = 0;
    },
    setLeaderboards(state, payload) {
      state.leaderboards = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setCategoriesForTeam(state, payload) {
      state.categoriesForTeam = payload;
    },
    setSelectedRunner(state, payload) {
      state.selectedRunner = payload;
    },
    setSelectedTeam(state, payload) {
      state.selectedTeam = payload;
    },
    setSelectedTeamMembers(state, payload) {
      state.selectedTeamMembers = payload;
    },
    setLegacy(state, payload) {
      state.legacy = payload;
    },
    setFaceSearchResult(state, payload) {
      if (state.faceSearchResult && state.faceSearchResult.images) {
        state.faceSearchResult.images = [];
        setTimeout(() => {
          state.faceSearchResult = payload;
        }, 500);
      } else {
        state.faceSearchResult = payload;
      }
    },
    setSelectedImage(state, payload) {
      state.selectedImage = payload;
    },
    setAdsense(state, payload) {
      if (state.fetchedEvent) {
        state.fetchedEvent.adsense = payload;
      } else {
        setTimeout(() => {
          state.fetchedEvent.adsense = payload;
        }, 2000);
      }
    },
    setPhotoPurchase(state, payload) {
      if (state.fetchedEvent) {
        state.fetchedEvent.photos_purchase = payload;
      } else {
        setTimeout(() => {
          state.fetchedEvent.photos_purchase = payload;
        }, 2000);
      }
    },
    setSurvey(state, payload) {
      state.survey = payload;
    },
    setSelectedRunnerOrTeam(state, payload) {
      state.selectedRunnerOrTeam = payload;
    },
    setRunnerImages(state, payload) {
      state.runnerImages = payload;
    },
    setCurrentNearRunner(state, payload) {
      state.currentNearRunner = payload;
    },
    setAdsCount(state, payload) {
      if (payload) {
        state.adsCount++;
      } else {
        state.adsCount--;
      }
    },
    setNoRaceImage(state, payload) {
      state.noRaceImage = payload;
    },
    setSingleImageAdsCount(state, payload) {
      state.singleImageAdsCount = payload;
    },
    setColor(state, payload) {
      state.color = payload;
    },
    setShowError(state, payload) {
      state.showError = payload;
    },
    setImageReadyIMV(state, payload) {
      state.imageReadyIMV = payload;
    },
    addImageReadyIMV(state, payload) {
      state.imageReadyIMV.push(...payload);
    },
    removeImageReadyIMV(state, payload) {
      let index = state.imageReadyIMV.indexOf(payload);
      if (index >= 0) state.imageReadyIMV.splice(index, 1);
    },
    setRaceGalleryInfo(state, payload) {
      state.raceGalleryInfo = payload;
    },
    setHideFacematches(state, payload) {
      state.hideFacematches = payload;
    },
    setShowLostAndFound(state, payload) {
      state.showLostAndFound = payload;
    },
    setEnmotiveUserId(state, payload) {
      state.enmotiveUserId = payload;
    },
    setOverlays(state, payload) {
      state.overlays = payload;
      state.stickers = payload.reduce(
        (a, e) =>
          a +
          e.overlays.reduce((a, e) => a + (e.overlay_position == "sticker"), 0),
        0
      );
    },
    setCurrentRaceId(state, payload) {
      state.currentRaceId = payload;
    },
    setUploadPhotoInfo(state, payload) {
      state.uploadPhotoInfo = payload;
    },
    setCreateVideoRequestId(state, payload) {
      state.createVideoRequestId = payload;
    },
    setComeFromTeam(state, payload) {
      state.comeFromTeam = payload;
    },
    setEventTeamList(state, payload) {
      state.eventTeamList = payload;
    }
  },
  actions: {
    setRaceChanged({ commit, state }, payload) {
      commit("setRaceChanged", payload);
    },
    fireTracking({ commit, state }, payload) {
      commit("fireTracking", payload);
    },
    setWindowSize({ commit, state }, payload) {
      commit("setWindowSize", payload);
    },
    setHeaderHeight({ commit, state }, payload) {
      commit("setHeaderHeight", payload);
    },
    setEventVideoType({ commit }, payload) {
      commit("setEventVideoType", payload);
    },
    setRaceDefaultVideo({ commit }, payload) {
      commit("setRaceDefaultVideo", payload);
    },
    setFetchedEvent({ commit, state }, payload) {
      // normalise payload please
      payload = formatter.normalisePayload(payload)
      commit("setFetchedEvent", payload);
    },
    setLeaderboards({ commit, state }, payload) {
      // normalise payload please
      payload = formatter.normalisePayload(payload)
      commit("setLeaderboards", payload);
    },
    setCategories({ commit, state }, payload) {
      commit("setCategories", payload);
    },
    setCategoriesForTeam({ commit, state }, payload) {
      commit("setCategoriesForTeam", payload);
    },
    setSelectedRunner({ commit, state }, payload) {
      // normalise payload please
      payload = formatter.normalisePayload(payload)
      commit("setSelectedRunner", payload);
    },
    setSelectedTeam({ commit, state }, payload) {
      commit("setSelectedTeam", payload);
    },
    setSelectedTeamMembers({ commit, state }, payload) {
      commit("setSelectedTeamMembers", payload);
    },
    setRunnerImages({ commit, state }, payload) {
      commit("setRunnerImages", payload);
    },
    setLegacy({ commit, state }, payload) {
      commit("setLegacy", payload);
    },
    setFaceSearchResult({ commit, state }, payload) {
      commit("setFaceSearchResult", payload);
    },
    setSelectedImage({ commit, state }, payload) {
      commit("setSelectedImage", payload);
    },
    setAdsense({ commit, state }, payload) {
      commit("setAdsense", payload);
    },
    setPhotoPurchase({ commit, state }, payload) {
      // normalise payload please
      payload = formatter.normalisePayload(payload)
      commit("setPhotoPurchase", payload);
    },
    setSurvey({ commit, state }, payload) {
      commit("setSurvey", payload);
    },
    setSelectedRunnerOrTeam({ commit, state }, payload) {
      payload = formatter.normalisePayload(payload)
      commit("setSelectedRunnerOrTeam", payload);
    },
    setCurrentNearRunner({ commit, state }, payload) {
      commit("setCurrentNearRunner", payload);
    },
    setAdsCount({ commit, state }, payload) {
      commit("setAdsCount", payload);
    },
    setNoRaceImage({ commit, state }, payload) {
      commit("setNoRaceImage", payload);
    },
    setSingleImageAdsCount({ commit, state }, payload) {
      commit("setSingleImageAdsCount", payload);
    },
    setColor({ commit, state }, payload) {
      commit("setColor", payload);
    },
    setShowError({ commit, state }, payload) {
      commit("setShowError", payload);
    },
    setImageReadyIMV({ commit, state }, payload) {
      commit("setImageReadyIMV", payload);
    },
    addImageReadyIMV({ commit, state }, payload) {
      commit("addImageReadyIMV", payload);
    },
    removeImageReadyIMV({ commit, state }, payload) {
      commit("removeImageReadyIMV", payload);
    },
    setRaceGalleryInfo({ commit, state }, payload) {
      commit("setRaceGalleryInfo", payload);
    },
    setHideFacematches({ commit, state }, payload) {
      commit("setHideFacematches", payload);
    },
    setShowLostAndFound({ commit, state }, payload) {
      commit("setShowLostAndFound", payload);
    },
    setEnmotiveUserId({ commit }, payload) {
      commit("setEnmotiveUserId", payload);
    },
    setOverlays({ commit }, payload) {
      commit("setOverlays", payload);
    },
    setCurrentRaceId({ commit }, payload) {
      commit("setCurrentRaceId", payload);
    },
    setUploadPhotoInfo({ commit }, payload) {
      commit("setUploadPhotoInfo", payload);
    },
    setCreateVideoRequestId({ commit }, payload) {
      commit("setCreateVideoRequestId", payload);
    },
    setComeFromTeam({ commit }, payload) {
      commit("setComeFromTeam", payload);
    },
    setEventTeamList({ commit }, payload) {
      commit("setEventTeamList", payload);
    }
  },
  namespaced: true
});

export default store;
