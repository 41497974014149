<template>
  <div id="error">
    <div class="text-center">
      <h1 class="mb-4">{{$t('404.title')}}</h1>
      <h5>
        {{$t('404.desc_1')}}
        <a @click="goBack()">{{$t('404.go_back')}}</a>
        {{$t('404.desc_2')}}
      </h5>
    </div>
  </div>
</template>

<script>
import Eventbus from "../event-bus";

export default {
  name: "error",
  components: {},
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  },
  mounted() {
    Eventbus.$emit("change-loader-status", false);
    Eventbus.$emit("hard-lock", false);
    Eventbus.$emit("lock-scroll", false);
  }
};
</script>

<style lang="scss" scoped>
#error {
  min-height: 70vh;
  background-color: #ffffffaa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6f7372;

  a {
    cursor: pointer;
    color: var(--primary-color);
  }
}
</style>
