<template>
  <ins
    :class="`adsbygoogle`"
    :data-ad-client="adsObj.client"
    :data-ad-slot="adsObj.slot"
    :style="getStyle()"
  ></ins>
</template>
<script>
import $ from "jquery";
import { mapGetters } from "vuex";

export default {
  name: "vue-ins",
  data() {
    return {};
  },
  props: {
    adsObj: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getWindowSize"])
  },
  watch: {
    adsObj(val) {
      if (val) this.initAds();
    }
  },
  mounted() {
    this.$nextTick(() => (adsbygoogle = window.adsbygoogle || []).push({}));
  },
  methods: {
    initAds() {
      let diff =
        $(".adsbygoogle").length -
        $('.adsbygoogle[data-adsbygoogle-status="done"]').length;
      if (diff > 0) {
        this.$nextTick(() => (adsbygoogle = window.adsbygoogle || []).push({}));
      }
    },
    getStyle() {
      let arr = this.adsObj.class.substring(1).split('x');
      return 'display:block;max-width:' + arr[0] + 'px;width:100%;height:' + arr[1] + 'px;';
    }
  }
};
</script>

<style lang="scss">
.adsbygoogle {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>