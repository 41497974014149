
const EVENT_VIDEO_INVALID = -1;
const EVENT_VIDEO_NEW = 0;
const EVENT_VIDEO_LEGACY = 1;
const EVENT_VIDEO_NO_VIDEO = 2;


const constants = {
  EVENT_VIDEO_INVALID,
  EVENT_VIDEO_NEW,
  EVENT_VIDEO_LEGACY,
  EVENT_VIDEO_NO_VIDEO
};

export default constants;