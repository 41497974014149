<template>
  <div>
    <div class="type-loader" :class="{'v-none': !show}" :style="{'left': `${left}px`}">
      <div class="typing_loader"></div>
    </div>
    <span
      ref="type-loader-text-width"
      :id="`type-loader-text-width-${name}`"
      class="type-loader-text"
    >{{text}}</span>
  </div>
</template>

<script>
import Eventbus from "../event-bus";
import $ from "jquery";

export default {
  name: "type-loader",
  components: {},
  props: {
    name: {
      type: String,
      required: true
    },
    holdPadding: {
      type: Number,
      required: true
    },
    timeout: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      show: false,
      left: this.holdPadding,
      text: ""
    };
  },
  methods: {
    setPosition(text) {
      this.text = text;
      setTimeout(() => {
        this.left =
          $(`#type-loader-text-width-${this.name}`).width() +
          this.holdPadding +
          40;
        this.show = true;
      }, this.timeout);
    },
    close() {
      this.show = false;
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.type-loader {
  position: absolute;
  top: -moz-calc(50% - 3px);
  top: -webkit-calc(50% - 3px);
  top: -o-calc(50% - 3px);
  top: calc(50% - 3px);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  &.v-none {
    visibility: hidden;
  }

  .typing_loader {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    -webkit-animation: typing 1s linear infinite alternate;
    -moz-animation: Typing 1s linear infinite alternate;
    animation: typing 1s linear infinite alternate;
    position: relative;
    left: 0;
    padding: 0 !important;
  }
}

.type-loader-text {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
}
</style>
