<template>
  <div id="loader" v-if="loading || !colorGot">
    <span>
      <img src="@/assets/images/loading.gif" />
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Eventbus from "../event-bus";

export default {
  name: "loader",
  components: {},
  data() {
    return {
      loading: false,
      colorGot: false
    };
  },
  computed: {
    ...mapGetters(["getColor"])
  },
  methods: {},
  mounted() {
    Eventbus.$on("change-loader-status", status => {
      Eventbus.$emit("lock-scroll", status);
      this.loading = status;
    });

    Eventbus.$on("set-color-got", status => {
      this.colorGot = status;
    });
  },
  beforeDestroy() {
    Eventbus.$off("change-loader-status");
    Eventbus.$off("set-color-got");
  }
};
</script>

<style lang="scss" scoped>
#loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: #ffffffee;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  img {
    width: 30px;
    height: auto;
  }
}
</style>
